import * as React from 'react'
import cx from 'classnames'
import { IconWrapper, IconProps, sizes } from '../../IconWrapper'
export const CheckCircleSelectedIcon = React.forwardRef(
  function CheckCircleSelectedIcon(
    props: IconProps,
    ref?: React.ForwardedRef<HTMLElement>
  ) {
    return (
      <IconWrapper ref={ref} {...props}>
        <>
          {
            <svg
              width={24}
              height={24}
              viewBox='0 0 24 24'
              fill='none'
              className={cx(
                'inline-block align-middle',
                sizes[props.size || 'sm'].className
              )}
            >
              <path
                d='M8 12.25l2.85 3.25 2.625-3.5L16.1 8.5M21 12a9 9 0 11-18 0 9 9 0 0118 0z'
                stroke='currentColor'
                strokeWidth={sizes[props.size || 'sm'].strokeWidth}
                strokeLinecap='round'
                strokeLinejoin='round'
              />
            </svg>
          }
        </>
      </IconWrapper>
    )
  }
)
