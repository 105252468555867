import * as React from 'react'
import cx from 'classnames'
import { useUniqueId } from '@toasttab/buffet-utils'

export const Colors = {
  success: 'success',
  error: 'error',
  warning: 'warning',
  info: 'info',
  neutral0: 'neutral0',
  neutral1: 'neutral1',
  neutral2: 'neutral2',
  neutral3: 'neutral3',
  neutral4: 'neutral4',
  /** @deprecated neutral will be removed in the future, use info instead */
  neutral: 'neutral', // eslint-disable-line
  /** @deprecated gray will be removed in the future, use neutral0 instead */
  gray: 'gray', // eslint-disable-line
  /** @deprecated primary will be removed in the future, use neutral1 instead */
  primary: 'primary', // eslint-disable-line
  /** @deprecated brand will be removed in the future, use neutral2 instead */
  brand: 'brand', // eslint-disable-line
  /** @deprecated lavender will be removed in the future, use neutral3 instead */
  lavender: 'lavender', // eslint-disable-line
  /** @deprecated orchid will be removed in the future, use neutral4 instead */
  orchid: 'orchid' // eslint-disable-line
} as const

export const Variants = {
  statusSm: 'statusSm',
  statusLg: 'statusLg'
} as const

type BadgeSize = 'sm' | 'lg'

interface BadgeComponentPrototype {
  Variant: typeof Variants
  Color: typeof Colors
}

type BadgeStyle = 'subtle' | 'bold'

export interface BadgeProps {
  /** @deprecated: Use size instead */
  variant?: keyof typeof Variants
  color?: keyof typeof Colors
  testId?: string | number
  className?: string
  badgeStyle?: BadgeStyle
  size?: BadgeSize
}

const getVariantClasses = (
  color: keyof typeof Colors,
  variant: keyof typeof Variants,
  badgeStyle: BadgeStyle,
  size: BadgeSize
) => {
  const colorClasses =
    badgeStyle === 'subtle'
      ? `badge-${color} badge-inner-border`
      : `badge-${color}-bold`
  const baseBadgeClasses = `badge-base ${colorClasses} font-semibold`

  const isSmall = size === 'sm' || variant === Variants.statusSm

  if (isSmall) {
    return `${baseBadgeClasses} h-4 type-overline-sm`
  }
  return `${baseBadgeClasses} h-6 type-overline`
}

export const Badge: React.FC<React.PropsWithChildren<BadgeProps>> &
  BadgeComponentPrototype = ({
  children,
  variant = Variants.statusLg, // eslint-disable-line deprecation/deprecation
  color = Colors.info,
  badgeStyle = 'subtle',
  size = 'lg',
  testId,
  className = ''
}) => {
  testId = useUniqueId(testId, 'badge-')

  return (
    <div
      className={cx(
        'py-0',
        'inline-flex items-center justify-center whitespace-nowrap',
        'px-2',
        className,
        getVariantClasses(color, variant, badgeStyle, size)
      )}
      data-testid={testId}
    >
      {children}
    </div>
  )
}

Badge.Variant = Object.freeze(Variants)
Badge.Color = Object.freeze(Colors)
