import {
  StatusBadge,
  Subscription,
  getSubscriptionStatus
} from '@local/manage-webhooks-shared'
import { useWebhooksContext } from '@local/manage-webhooks-shared/WebhooksContext/WebhooksContext'
import {
  SubscriptionStatus,
  SubscriptionStatuses
} from '@local/manage-webhooks-shared/types/subscriptionStatus.type'
import { Panel } from '@toasttab/buffet-pui-config-templates'
import cx from 'classnames'
import { useMemo } from 'react'

export interface SubscriptionStatusSummaryProps {
  subscriptions: Subscription[]
}

export function SubscriptionStatusSummary() {
  return (
    <Panel
      className='flex flex-row justify-between'
      data-testid='subscription-status-summary'
    >
      {SubscriptionStatuses.map((status, idx) => {
        const last = idx === SubscriptionStatuses.length - 1
        return (
          <div
            key={status}
            className={cx(
              'flex flex-col w-1/3 mr-4',
              last ? undefined : 'border-r-2 border-slate-50'
            )}
          >
            <SubscriptionStatusCount status={status} />
          </div>
        )
      })}
    </Panel>
  )
}

function SubscriptionStatusCount({ status }: { status: SubscriptionStatus }) {
  const { subscriptions } = useWebhooksContext()

  const count = useMemo(
    () =>
      subscriptions?.filter(
        (subscription) => getSubscriptionStatus(subscription) === status
      ).length ?? 0,
    [status, subscriptions]
  )

  return (
    <>
      <div>
        <StatusBadge status={status} />
      </div>
      <p className='w-8 type-headline-4' data-testid={`${status}-count`}>
        {count}
      </p>
    </>
  )
}
