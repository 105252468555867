import * as React from 'react'
import { useUniqueId } from '@toasttab/buffet-utils'
import {
  useFloating,
  useInteractions,
  useRole,
  useHover,
  useFocus,
  useDismiss,
  arrow,
  flip,
  shift,
  autoPlacement,
  limitShift,
  autoUpdate,
  PlacementWithAuto
} from '@toasttab/buffet-pui-floating-ui-base'
import { TooltipAccessibility, TooltipVariant } from './Tooltip'

export interface UseTooltipProps {
  id: string | undefined
  accessibility: TooltipAccessibility
  mobileTimeout: number
  placement: PlacementWithAuto
  variant: TooltipVariant
}

export const useTooltip = ({
  id,
  accessibility,
  mobileTimeout,
  placement,
  variant
}: UseTooltipProps) => {
  const tooltipId = useUniqueId(id, 'tooltip-')

  const arrowRef = React.useRef(null)
  const mobileTimer = React.useRef<ReturnType<typeof setTimeout> | null>(null)
  const openTimeout = React.useRef<ReturnType<typeof setTimeout> | null>(null)
  const [open, setOpen] = React.useState(false)
  const [ancestorScroll, setAncestorScroll] = React.useState(false)

  const adjustedPlacement = placement === 'auto' ? undefined : placement

  const arrowDarkWidth = 16
  const arrowLightWidth = 24

  // additional arrow padding for all start and end placements (top-start, top-end etc) as otherwise the arrow edge is visible at the rounded corner of the tooltip
  const arrowPadding = placement?.match(/start|end/)
    ? variant === 'arrowLight'
      ? arrowLightWidth
      : arrowDarkWidth
    : 0

  const {
    context,
    refs,
    placement: floatingPlacement,
    middlewareData: { arrow: { x: arrowX, y: arrowY } = {} },
    floatingStyles
  } = useFloating({
    open,
    onOpenChange: setOpen,
    placement: adjustedPlacement,
    whileElementsMounted: autoUpdate,
    middleware: [
      !adjustedPlacement ? autoPlacement({ padding: 8 }) : flip({ padding: 8 }),
      shift({ padding: 8, limiter: limitShift(), crossAxis: true }),
      arrow({ element: arrowRef, padding: arrowPadding })
    ]
  })

  const role = useRole(context, {
    role: 'tooltip'
  })
  const hover = useHover(context)
  const focus = useFocus(context)
  const dismiss = useDismiss(context, { ancestorScroll })
  const { getReferenceProps, getFloatingProps } = useInteractions([
    role,
    hover,
    focus,
    dismiss
  ])

  React.useEffect(() => {
    if (open) {
      mobileTimer.current = setTimeout(() => setOpen(false), mobileTimeout)
    }
    return () => {
      mobileTimer.current && clearTimeout(mobileTimer.current)
    }
  }, [mobileTimeout, openTimeout, open])

  React.useEffect(() => {
    if (open) {
      // Don't enable ancestor scroll until after
      // the web browser has scrolled to reference element
      openTimeout.current = setTimeout(() => setAncestorScroll(true), 0)
    } else {
      setAncestorScroll(false)
    }
    return () => {
      openTimeout.current && clearTimeout(openTimeout.current)
    }
  }, [ancestorScroll, setAncestorScroll, openTimeout, open])

  return {
    refs,
    tooltipId,
    accessibility,
    open,
    context,
    getFloatingProps: React.useCallback(
      (userProps?: React.HTMLProps<HTMLElement>) =>
        getFloatingProps({
          onMouseOver: () => {
            setOpen(true)
          },
          onMouseOut: () => {
            setOpen(false)
          },
          ...userProps,
          id: tooltipId
        }),
      [tooltipId, getFloatingProps]
    ),
    floatingStyles,
    placement: floatingPlacement,
    arrow: {
      ref: arrowRef,
      x: arrowX,
      y: arrowY,
      width: variant === 'arrowLight' ? arrowLightWidth : arrowDarkWidth
    },
    variant,
    getReferenceProps: React.useCallback(
      (userProps?: React.HTMLProps<HTMLElement>) =>
        getReferenceProps({
          ...userProps,
          ...getAccessibilityProps(accessibility, tooltipId)
        }),
      [accessibility, tooltipId, getReferenceProps]
    )
  }
}

const getAccessibilityProps = (
  accessibility: TooltipAccessibility,
  tooltipId: string
) => {
  switch (accessibility) {
    case 'decorative':
      return {}
    case 'label':
      return { 'aria-labelledby': tooltipId }
    case 'description':
    default:
      return { 'aria-describedby': tooltipId }
  }
}
