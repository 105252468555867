import { useDomainContext } from '@local/current-domain'
import {
  EventCategory,
  Partner,
  SavedSubscription,
  ToastAdminEditSubscriptionFormValues
} from '@local/manage-webhooks-shared'
import { PreprodTestingModal } from '@local/preprod-testing-modal'
import { Panel } from '@toasttab/buffet-pui-config-templates'
import {
  RadioGroupField,
  RequiredText,
  TextInputField
} from '@toasttab/buffet-pui-forms'
import { Form, FormikContextType, FormikProvider } from 'formik'
import { useEffect, useState } from 'react'

import { EditSubscriptionPageWrapper } from '../EditSubscriptionPageWrapper/EditSubscriptionPageWrapper'
import { SelectPartnerField } from '../SelectPartnerField/SelectPartnerField'
import { useEditSubscriptionForm } from '../useEditSubscriptionForm/useEditSubscriptionForm'

import { SelectEventCategoryField } from './Fields/SelectEventCategoryField'
import { WebhookURLField } from './Fields/WebhookURLField'

export function EditSubscriptionForm({
  existingSubscription,
  partners,
  eventCategories
}: {
  existingSubscription: SavedSubscription | undefined
  partners: Partner[]
  eventCategories: EventCategory[]
}) {
  const newSubscription = !existingSubscription?.guid
  const { isDepotDomain } = useDomainContext()

  const form = useEditSubscriptionForm(
    existingSubscription,
    partners,
    eventCategories
  )

  const [hasSetName, setHasSetName] = useState(false)
  const eventCategoryGuid = (
    form as FormikContextType<ToastAdminEditSubscriptionFormValues>
  ).values.eventCategoryGuid
  useEffect(() => {
    if (hasSetName) return
    // only active on newSubscription
    if (!newSubscription) return

    const eventCategoryName = eventCategories.find(
      (ec) => ec.guid === eventCategoryGuid
    )?.name

    if (!form.values.name && eventCategoryName) {
      form.setFieldValue('name', eventCategoryName)
      setHasSetName(true)
    }
  }, [
    newSubscription,
    form.values.name,
    eventCategoryGuid,
    form,
    eventCategories,
    hasSetName
  ])

  return (
    <FormikProvider value={form}>
      <Form>
        <EditSubscriptionPageWrapper
          subscription={existingSubscription}
          showSubmitButton
          disableSubmit={!form.dirty || !form.isValid}
          isSaveInProgress={form.isSubmitting}
          unsavedChanges={form.dirty}
        >
          <Panel
            title={
              newSubscription
                ? 'Add webhook subscription'
                : `Edit '${existingSubscription?.name ?? ''}'`
            }
            testId='edit-subscription-form'
          >
            <div className='flex flex-col gap-4' style={{ maxWidth: '35rem' }}>
              <RequiredText />
              {!isDepotDomain && (
                <>
                  <SelectPartnerField partners={partners} />
                </>
              )}
              <SelectEventCategoryField
                eventCategories={eventCategories}
                newSubscription={newSubscription}
              />
              <WebhookURLField newSubscription={newSubscription} />
              <TextInputField
                name='name'
                label='Name'
                required
                testId='name-field'
                className='max-[25rem]:'
              />
              <TextInputField
                name='notificationEmail'
                label='Notification email'
                testId='notification-email-field'
                helperText={<NotificationEmailHelperText />}
              />
              {!isDepotDomain && !newSubscription && (
                <>
                  <RadioGroupField
                    name='status'
                    label='Subscription status'
                    required
                    testId='status-radio-group'
                    options={[
                      {
                        value: 'ACTIVE',
                        label: 'Active'
                      },
                      {
                        value: 'INACTIVE',
                        label: 'Inactive'
                      },
                      {
                        value: 'PAUSED',
                        label: 'Paused',
                        disabled: true
                      }
                    ]}
                  />
                  <div className='type-caption text-secondary'>
                    Status changes will be reflected upon saving.
                  </div>
                </>
              )}
            </div>
          </Panel>
          {!isDepotDomain && (
            <div className='inline relative bottom-0 float-right'>
              {existingSubscription?.guid && (
                <PreprodTestingModal
                  subscriptionGuid={existingSubscription.guid}
                />
              )}
            </div>
          )}
        </EditSubscriptionPageWrapper>
      </Form>
    </FormikProvider>
  )
}

function NotificationEmailHelperText() {
  return (
    <div>
      Where an email will be sent in the event of Toast pausing or stopping an
      endpoint.{' '}
      <a
        className='underline text-link'
        href='https://doc.toasttab.com/doc/devguide/apiBackOffSupport.html'
        target='_blank'
        rel='noreferrer'
      >
        Learn more
      </a>
    </div>
  )
}
