import * as React from 'react'
import cx from 'classnames'
import { ScreenSize } from '@toasttab/use-screen-size'
import { useLayoutContext } from '../../common/LayoutProvider'
import { PageArea } from '../PageArea'
import { PageHeaderSpacer } from '../PageHeaderSpacer'
import { PageHeaderDivider } from '../PageHeaderDivider'

export interface PageHeaderProps {
  testId?: string
  className?: string
  /**
   * @deprecated This property can be removed. It is now queried via introspection based on the presence
   * of the PageTargetNavLayout or PageTargetNavLoading components.
   *
   * set to true when you have added PageTargetNavLayout component in the PageBody
   */
  isUsingPageTargetNav?: boolean
  /**
   * @deprecated This property can be removed. It is now queried via introspection based on the presence
   * of the SecondaryNavLayout component.
   *
   * set to true when you have added SecondaryNavLayout component in the PageBody
   */
  isUsingSecondaryNav?: boolean
  /**
   * set to true when you have added TabList component in the PageBody
   */
  isUsingTabs?: boolean
  /**
   * Specify a TabList component to be in this (fixed) header.
   * Use with caution.
   * Are you sure you don't want to put the TabList component in PageBody?
   */
  fixedTabList?: React.ReactNode
}

export const PageHeader: React.FC<React.PropsWithChildren<PageHeaderProps>> = ({
  testId = 'focus-view-header',
  isUsingTabs = false,
  className = '',
  children,
  fixedTabList
}) => {
  const {
    screenSize,
    pagePositions,
    headerRef,
    isHeaderCollapsed,
    isUsingLeftNav
  } = useLayoutContext()

  return (
    <header
      ref={headerRef}
      className={cx(
        'z-10 bg-page', // elevation: sticky headers use z-10 according to our guidelines
        isUsingLeftNav ? 'lg:sticky' : 'md:sticky',
        className
      )}
      style={
        screenSize >= ScreenSize.MD
          ? { top: pagePositions.headerTop }
          : { top: 0 }
      }
      data-testid={testId}
    >
      {/* eslint-disable-next-line */}
      <PageArea>
        <div
          className={cx(
            'transition-all duration-300',
            'relative px-4 md:px-0 pt-6 pb-0',
            isUsingLeftNav || isUsingTabs ? 'mb-0' : 'mb-4',
            isUsingTabs ? '' : 'lg:mb-6',
            isHeaderCollapsed ? 'lg:p-0 lg:pt-4' : 'lg:p-0 lg:pt-6'
          )}
        >
          <div className='relative flex flex-row justify-between'>
            {children}
          </div>
          <PageHeaderSpacer fixedTabList={fixedTabList} />
          <PageHeaderDivider
            fixedTabList={fixedTabList}
            isUsingTabs={isUsingTabs}
          />
          {fixedTabList}
        </div>
      </PageArea>
    </header>
  )
}
