import { useState } from 'react'
import { ApolloError, useLazyQuery, useMutation } from '@apollo/client'
import { Event, RESEND_EVENT } from '@local/manage-webhooks-shared'
import { useSentry } from 'banquet-runtime-modules'
import { AutoHideSpeed, useSnackBar } from '@toasttab/buffet-pui-snackbars'

import { GET_EVENT_BY_KEYS } from '../queries'

export const useEventByKeys = (partitionKey: string, sortKey: string) => {
  const [eventByKeys, setEventByKeys] = useState<Event>()
  const { hub } = useSentry()
  const [
    getEventByKeys,
    { loading: eventByKeysLoading, error: eventByKeysError }
  ] = useLazyQuery(GET_EVENT_BY_KEYS, {
    variables: { partitionKey, sortKey },
    onError: (error) => {
      hub.captureException(error)
    },
    onCompleted: (data) => {
      setEventByKeys(data.getEventByKeys)
    }
  })

  return {
    getEventByKeys,
    eventByKeys,
    eventByKeysLoading,
    eventByKeysError
  }
}

export const useReplayRetrievedEvent = () => {
  const { showErrorSnackBar, showSuccessSnackBar } = useSnackBar()
  const [replayMutation, { loading: replayLoading }] = useMutation(
    RESEND_EVENT,
    {
      onCompleted: () => {
        showSuccessSnackBar('Webhook event queued for replay', {
          testId: 'replay-event-success-snackbar'
        })
      },
      onError: (apolloError: ApolloError) => {
        const errorMessage =
          (apolloError.graphQLErrors?.[0]?.extensions?.response as any)?.body
            ?.message ?? apolloError.message

        showErrorSnackBar(`Something went wrong: ${errorMessage}`, {
          testId: 'replay-event-error-snackbar',
          autoHideDuration: AutoHideSpeed.Slow
        })
      }
    }
  )

  const executeReplayEvent = async (event: Event) => {
    await replayMutation({
      variables: {
        subscriptionGuid: event.subscriptionGuid,
        webhookGuid: event.webhookGuid,
        timestamp: event.timestamp
      }
    })
  }

  return { executeReplayEvent, replayLoading }
}
