import { useQuery } from '@apollo/client'
import { useBanquetPartner } from '@local/banquet-partner-props'
import { useSentry } from 'banquet-runtime-modules'

import { errorNotFoundOrForbidden } from '../../errorNotFoundOrForbidden'
import { SavedSubscription } from '../../types'

import { DEPOT_GET_SUBSCRIPTION } from './DEPOT_GET_SUBSCRIPTION'

export function useDepotGetSubscription(guid?: string) {
  const { partner } = useBanquetPartner()
  const { hub } = useSentry()

  const { data, error, loading } = useQuery<{
    getSubscriptionForSubscriber: SavedSubscription
  }>(DEPOT_GET_SUBSCRIPTION, {
    skip: !guid,
    // fix when user is on subscriptions page, click on 'view events'
    // then click 'edit' and 'delete'
    // user will be redirect back to subscriptions page
    // however user can click go back and still see the edit form
    fetchPolicy: 'no-cache',
    context: { headers: { 'toast-organization-guid': partner?.guid } },
    variables: { guid },
    onError: (newError) => {
      if (!errorNotFoundOrForbidden(newError)) {
        // only send error not 404 or 403 to sentry
        hub.captureException(newError)
      }
    }
  })

  return {
    subscription: data?.getSubscriptionForSubscriber,
    error,
    loading
  }
}
