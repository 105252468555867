import * as React from 'react'
import cx from 'classnames'
import { IconWrapper, IconProps, sizes } from '../../IconWrapper'
export const HomeIcon = React.forwardRef(function HomeIcon(
  props: IconProps,
  ref?: React.ForwardedRef<HTMLElement>
) {
  return (
    <IconWrapper ref={ref} {...props}>
      <>
        {
          <svg
            width={24}
            height={24}
            viewBox='0 0 24 24'
            fill='none'
            className={cx(
              'inline-block align-middle',
              sizes[props.size || 'sm'].className
            )}
          >
            <path
              d='M22 10.269L12 2 2 10.269m2.465-1.99v12.58A1.126 1.126 0 005.576 22h4.048v-4.564a1.126 1.126 0 011.11-1.141h2.531a1.126 1.126 0 011.111 1.14V22h4.048a1.126 1.126 0 001.11-1.141V8.279'
              stroke='currentColor'
              strokeWidth={sizes[props.size || 'sm'].strokeWidth}
              strokeLinecap='round'
              strokeLinejoin='round'
            />
          </svg>
        }
      </>
    </IconWrapper>
  )
})
