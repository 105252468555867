import { gql } from '@apollo/client'

export const GET_EVENT_CATEGORIES = gql`
  query getEventCategories {
    getEventCategories {
      guid
      name
      eventSubjectType
    }
  }
`
