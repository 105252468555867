import { InMemoryCache, makeVar } from '@apollo/client'

export const modifiedSubscription = makeVar({})

export const cache = new InMemoryCache({
  typePolicies: {
    Subscription: {
      keyFields: ['guid']
    },
    Partner: {
      keyFields: ['guid']
    },
    EventCategory: {
      keyFields: ['name']
    }
  }
})
