import * as React from 'react'
import { FloatingOverlay } from '@floating-ui/react'

export interface OverlayProps extends React.HTMLProps<HTMLDivElement> {
  lockScroll?: boolean
  className?: string
  testId?: string
  children: React.ReactElement
}

/**
 * One of the main reasons to have this component rather than using `FloatingOverlay` direct from floating-ui
 * is that this makes it much easier to mock (see the select tests)
 */
export const Overlay = React.forwardRef<HTMLDivElement, OverlayProps>(
  (
    {
      lockScroll,
      className,
      testId = 'overlay',
      children,
      ...rest
    }: OverlayProps,
    ref
  ) => {
    return (
      <FloatingOverlay
        className={className}
        lockScroll={lockScroll}
        data-testid={testId}
        {...rest}
        ref={ref}
      >
        {children}
      </FloatingOverlay>
    )
  }
)
