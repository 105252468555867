import { FunctionComponent } from 'react'
import { Event } from '@local/manage-webhooks-shared'
import { Alert } from '@toasttab/buffet-pui-alerts'
import { Button, ButtonGroup } from '@toasttab/buffet-pui-buttons'
import { Modal, ModalBody, ModalHeader } from '@toasttab/buffet-pui-modal'
import {
  EventFieldsContent,
  JSONContent,
  LabelledContent
} from '@local/manage-webhooks-shared/event-fields-content/EventFieldsContent'

export interface EventDetailsModalProps {
  isOpen: boolean
  onClose: () => void
  event: Event
  onReplay: (eventGuid: string, timestamp: string) => void
}
export const EventDetailsModal: FunctionComponent<EventDetailsModalProps> = ({
  isOpen,
  onClose,
  event,
  onReplay
}) => {
  let messageBody
  let jsonParsingError = false
  try {
    messageBody = JSON.stringify(JSON.parse(event.messageBody!), null, 2)
  } catch {
    messageBody = event.messageBody
    jsonParsingError = true
  }
  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={onClose}
      shouldCloseOnEsc
      shouldCloseOnOverlayClick
      size='xxl'
      testId={`${event.webhookGuid}-details-modal`}
    >
      <ModalHeader>
        <div className='type-headline-4'>Event details</div>
      </ModalHeader>
      <ModalBody>
        <ButtonGroup className='justify-end'>
          <Button
            onClick={() => onReplay(event.webhookGuid, event.timestamp)}
            variant='secondary'
            testId={'replay-button'}
          >
            Replay
          </Button>
        </ButtonGroup>
        <div className='grid grid-flow-row grid-cols-1 md:grid-cols-2 gap-x-4 gap-y-2'>
          <EventFieldsContent event={event} />
          <LabelledContent label='Message body' className='col-span-full '>
            {!jsonParsingError && (
              <JSONContent>
                <pre>{messageBody}</pre>
              </JSONContent>
            )}
            {jsonParsingError && (
              <>
                <Alert
                  variant='error'
                  className='w-full'
                  testId={'malformed-json-alert'}
                >
                  The message body contained malformed JSON. It may not be
                  formatted correctly.
                </Alert>
                <JSONContent>{event.messageBody}</JSONContent>
              </>
            )}
          </LabelledContent>
        </div>
      </ModalBody>
    </Modal>
  )
}
