import * as React from 'react'
import cx from 'classnames'
import { useLayoutContext } from '../LayoutProvider'

interface HeadingGroupProps {
  testId?: string | number
  subTitle?: string | React.ReactNode
  className?: string
}

export const HeadingGroup = ({
  testId = 'heading-group',
  subTitle,
  className = '',
  children
}: React.PropsWithChildren<HeadingGroupProps>) => {
  const { isHeaderCollapsed } = useLayoutContext()
  return (
    <hgroup
      className={cx(
        'flex flex-col-reverse flex-grow justify-center transition-all duration-300',
        { 'lg:pt-4': !subTitle && !isHeaderCollapsed },
        // To support className='truncate' on Title out of the box
        'min-w-0',
        className
      )}
      data-testid={testId}
    >
      {children}
      {subTitle && (
        <h2
          className={cx(
            'm-0 font-normal type-overline text-secondary truncate transition-all duration-300',
            isHeaderCollapsed
              ? 'opacity-0 max-h-0 transform translate-y-4'
              : 'max-h-10'
          )}
        >
          {subTitle}
        </h2>
      )}
    </hgroup>
  )
}
