import * as React from 'react'
import {
  HEADER_SCROLL_REF_HEIGHT,
  useLayoutContext,
  PAGE_VIEW_BUFFER
} from '../../common'
import { PageArea } from '../PageArea'

export interface PageBodyProps {
  testId?: string | number
  className?: string
}

export const PageBody: React.FC<React.PropsWithChildren<PageBodyProps>> = ({
  testId = 'page-container',
  className = '',
  children
}) => {
  const { headerScrollRef, isHeaderCollapsed } = useLayoutContext()

  return (
    // eslint-disable-next-line
    <PageArea testId={testId} className={className}>
      <div
        ref={headerScrollRef}
        className='absolute top-0 left-0 w-1'
        style={{
          height: `${HEADER_SCROLL_REF_HEIGHT}px`,
          marginTop: isHeaderCollapsed ? `-${PAGE_VIEW_BUFFER}px` : 0
        }}
      />
      {children}
    </PageArea>
  )
}
