import { gql } from '@apollo/client'

export const RESEND_EVENT = gql`
  mutation resendEvent(
    $subscriptionGuid: ID
    $webhookGuid: ID
    $timestamp: String
  ) {
    resendEvent(
      subscriptionGuid: $subscriptionGuid
      webhookGuid: $webhookGuid
      timestamp: $timestamp
    )
  }
`
