import { useDomainContext } from '@local/current-domain'
import {
  AdministratorPermissions,
  checkAdministratorPermissions
} from '@toasttab/check-permissions'
import { useBanquetProps } from 'banquet-runtime-modules'

export const useManagePartnersPermissions = () => {
  const { auth } = useBanquetProps()
  const { isDepotDomain } = useDomainContext()
  const hasEditPermissions = isDepotDomain
    ? false
    : checkAdministratorPermissions(
        auth?.userInfo?.administratorPermissions ?? '',
        {
          permissionBits: AdministratorPermissions.EDIT_MANAGE_PARTNERS_BIT
        }
      )

  return {
    hasEditPermissions
  }
}
