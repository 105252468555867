import {
  TextInputSkeleton,
  Skeleton
} from '@toasttab/buffet-pui-loading-indicators'

export const FormLoading = () => (
  <div className='space-y-4'>
    {Array.from({ length: 5 }, (_, index) => (
      <TextInputSkeleton noHelperText className='my-2' key={index} />
    ))}
    <div className='flex flex-col'>
      <Skeleton className='h-6 w-8 mb-1' />
      <Skeleton className='h-8 w-11' />
    </div>
  </div>
)
