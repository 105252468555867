import * as React from 'react'
import { useWindowSize } from '@toasttab/buffet-use-window-size'
import { shouldLogWarning, uniqueId } from '@toasttab/buffet-utils'
// We are now using the floating-ui base package for portal management
import {
  PortalManagement,
  useFloating,
  useFloatingComponentZIndexClass
} from '@toasttab/buffet-pui-floating-ui-base'
import { SnackBar, SnackBarProps, Variant } from '../SnackBar'
import cx from 'classnames'
import { SnackBarContext } from './SnackBarContext'
import styles from './styles.module.css'

const className = cx.bind(styles)

export enum AutoHideSpeed {
  Fast = 4000,
  Normal = 6000,
  Slow = 10000
}

export interface SnackBarOptions extends Omit<SnackBarProps, 'message'> {
  key?: string
  autoHideDuration?: AutoHideSpeed
  closed?: boolean
}
export interface SnackBarDetails extends SnackBarOptions {
  key: string
  message: SnackBarProps['message']
}

type SnackBarDetailsInternal = SnackBarDetails & {
  /**
   * An internal key to distinguish among individually enqueued snackbar entities
   */
  privateKey: string
}

export interface SnackBarProviderProps {
  autoHideDuration?: AutoHideSpeed
  /**
   * @deprecated Please use the default portal behavior to ensure the tooltip contents are correctly css scoped
   */
  domRoot?: string
  isDismissable?: boolean
  onDismiss?: (snackBar: SnackBarDetails) => void
}

const determineIsDismissable = (
  snackBar: SnackBarDetails,
  isDismissable?: boolean,
  onDismiss?: (snackBar: SnackBarDetails) => void
) => {
  const canDismiss = snackBar.isDismissable ?? isDismissable
  const hasOnDismiss = Boolean(snackBar.onDismiss) || Boolean(onDismiss)
  return hasOnDismiss || canDismiss
}

type SnackBarQueueState = {
  queue: SnackBarDetailsInternal[]
  autoHideDuration: number
  developerWarning?: { id: string; message: string }
}

const buildSnackBarProps = (
  message: SnackBarDetails['message'],
  options: SnackBarOptions
): SnackBarDetailsInternal => ({
  key: options?.key || uniqueId('snackbar-'),
  privateKey: uniqueId('private-snackbar-key'),
  ...options,
  message,
  autoHideDuration: options.autoHideDuration
})

type SnackBarQueueAction =
  | {
      type: 'ENQUEUE_SNACKBAR'
      data: {
        id?: string
        message: SnackBarDetails['message']
        options: SnackBarOptions
      }
    }
  | {
      type: 'UPDATE_DEFAULT_AUTOHIDE_DURATION'
      data: number
    }
  | {
      type: 'DEQUEUE_CLOSED_SNACKBAR'
      data: {
        privateKey: string
      }
    }
  | {
      type: 'CLOSE_SNACKBAR'
      data: {
        key: string
      }
    }
  | {
      type: 'CLOSE_SNACKBAR_BY_PRIVATE_KEY'
      data: {
        privateKey: string
      }
    }

function snackbarReducer(
  state: SnackBarQueueState,
  action: SnackBarQueueAction
): SnackBarQueueState {
  switch (action.type) {
    case 'UPDATE_DEFAULT_AUTOHIDE_DURATION':
      return {
        ...state,
        developerWarning: undefined,
        autoHideDuration: action.data
      }
    case 'ENQUEUE_SNACKBAR':
      const newSnackBar = buildSnackBarProps(action.data.message, {
        ...action.data.options,
        autoHideDuration:
          action.data.options.autoHideDuration || state.autoHideDuration
      })
      if (state.queue.some((sb) => sb.key === newSnackBar.key && !sb.closed)) {
        return {
          ...state,
          developerWarning: {
            id: uniqueId('sb-dev-warning'),
            message: `Enqueueing a snackbar with an identical key (${newSnackBar.key}) with an existing open snackbar is a no-op.`
          }
        }
      }
      return {
        ...state,
        developerWarning: undefined,
        queue: [...state.queue, newSnackBar]
      }
    case 'CLOSE_SNACKBAR':
      const newQueue: SnackBarDetailsInternal[] = []
      return {
        ...state,
        developerWarning: undefined,
        queue: state.queue.reduce((acc, sb, idx) => {
          // If the first snackbar matching, mark as "closed" so the animation runs
          // For all other matching snackbars, *remove* them from queue
          if (action.data.key === sb.key) {
            if (idx < 1) {
              return [...acc, { ...sb, closed: true }]
            } else {
              return acc
            }
          }
          return [...acc, sb]
        }, newQueue)
      }
    case 'CLOSE_SNACKBAR_BY_PRIVATE_KEY':
      return {
        ...state,
        developerWarning: undefined,
        queue: state.queue.map((sb) => {
          if (action.data.privateKey === sb.privateKey) {
            return { ...sb, closed: true }
          }
          return sb
        })
      }
    case 'DEQUEUE_CLOSED_SNACKBAR':
      return {
        ...state,
        developerWarning: undefined,
        queue: state.queue.filter((q) =>
          q.privateKey === action.data.privateKey ? !q.closed : true
        )
      }
    default:
      return state
  }
}

/**
 *  The provider for using Snackbars
 *
 * @param autoHideDuration defines the hide duration Slow, Fast or Normal, defaults to Normal
 * @param domRoot id of dom element
 * @param onDismiss an event which will be fired when the user clicks the close button in any rendered snackbar
 * @param children
 */
export const SnackBarProvider = ({
  autoHideDuration: globalAutoHideDuration = AutoHideSpeed.Normal,
  isDismissable = false,
  onDismiss: globalOnDismiss,
  domRoot, // eslint-disable-line
  children
}: React.PropsWithChildren<SnackBarProviderProps>): React.ReactElement<SnackBarProviderProps> => {
  const { context, refs } = useFloating()
  const { height: windowHeight } = useWindowSize()
  const zIndexClass = useFloatingComponentZIndexClass() // elevation: z-40 for floating components (boosted to z-50 when in modals etc)

  const portalContainerEl = React.useMemo(() => {
    if (domRoot) {
      // returns undefined if domRoot is specified but can't be found in the dom
      return document.getElementById(domRoot)
    }
    return null
  }, [domRoot])

  const [sbState, sendSbReducer] = React.useReducer(snackbarReducer, {
    queue: [],
    autoHideDuration: globalAutoHideDuration
  })

  const consoleWarningId = sbState.developerWarning?.id
  const consoleWarningMessage = sbState.developerWarning?.message
  React.useEffect(() => {
    if (shouldLogWarning() && consoleWarningId && consoleWarningMessage) {
      console.warn(consoleWarningMessage)
    }
  }, [consoleWarningId, consoleWarningMessage])

  React.useEffect(() => {
    sendSbReducer({
      type: 'UPDATE_DEFAULT_AUTOHIDE_DURATION',
      data: globalAutoHideDuration
    })
  }, [globalAutoHideDuration])

  const snackBarOffsetRef = React.useRef<HTMLDivElement>(null)

  const calculateOffset = () => {
    const offsetEl =
      snackBarOffsetRef.current ||
      document.querySelector('[data-buffet-bottom-toolbar]')
    if (offsetEl) {
      const offsetElRect = offsetEl?.getBoundingClientRect()
      // we only want to use the element as an offset if it's positioned in the bottom
      // (allowing for a 100px error of margin to account for url bar etc on mobile)
      if (offsetElRect && offsetElRect.bottom > windowHeight - 100) {
        return offsetElRect.height
      }
      return 0
    }
    return 0
  }

  const showSuccessSnackBar = React.useCallback(
    (
      message: SnackBarDetails['message'],
      options?: Omit<SnackBarOptions, 'variant'>
    ): void => {
      sendSbReducer({
        type: 'ENQUEUE_SNACKBAR',
        data: {
          id: options?.key,
          message: message,
          options: { ...options, variant: Variant.Success }
        }
      })
    },
    []
  )

  const showErrorSnackBar = React.useCallback(
    (
      message: SnackBarDetails['message'],
      options?: Omit<SnackBarOptions, 'variant'>
    ): void => {
      sendSbReducer({
        type: 'ENQUEUE_SNACKBAR',
        data: {
          id: options?.key,
          message: message,
          options: { ...options, variant: Variant.Error }
        }
      })
    },
    []
  )

  const showSnackBar = React.useCallback(
    (message: SnackBarDetails['message'], options?: SnackBarOptions): void => {
      sendSbReducer({
        type: 'ENQUEUE_SNACKBAR',
        data: {
          id: options?.key,
          message: message,
          options: { variant: Variant.Neutral, ...options }
        }
      })
    },
    []
  )

  const handleCloseSnackBar = React.useCallback((key: React.Key): void => {
    sendSbReducer({
      type: 'CLOSE_SNACKBAR',
      data: {
        // Ensuring always passes as a string, since the rest of the application
        // assumes this as well. Ideally, this would only accept strings, but
        // we do not want to break the existing API for closeSnackBar
        key: key.toString()
      }
    })
  }, [])

  const nextSnackbarInQueuePrivateKey = sbState.queue[0]?.privateKey
  const nextSnackbarAutoHideDuration = sbState.queue[0]?.autoHideDuration

  React.useEffect(() => {
    if (nextSnackbarInQueuePrivateKey) {
      const timeout = window.setTimeout(() => {
        sendSbReducer({
          type: 'CLOSE_SNACKBAR_BY_PRIVATE_KEY',
          data: {
            privateKey: nextSnackbarInQueuePrivateKey
          }
        })
      }, nextSnackbarAutoHideDuration)

      return () => {
        clearTimeout(timeout)
      }
    }
  }, [nextSnackbarInQueuePrivateKey, nextSnackbarAutoHideDuration])

  const openSnackBar = sbState.queue.find(
    (sb) => sb.privateKey === nextSnackbarInQueuePrivateKey
  )

  return (
    <SnackBarContext.Provider
      value={{
        showSuccessSnackBar: showSuccessSnackBar,
        showErrorSnackBar: showErrorSnackBar,
        showSnackBar: showSnackBar,
        closeSnackBar: handleCloseSnackBar,
        snackBarOffsetRef
      }}
    >
      <>
        {children}
        {openSnackBar && (
          <PortalManagement
            context={context}
            disableFocusManager
            portalContainerEl={portalContainerEl}
          >
            <div
              data-testid='snackBars-container'
              role='region'
              aria-live='polite'
              className={cx(
                zIndexClass,
                'fixed left-0 right-0 flex items-center justify-center px-4 pb-4 md:pb-6 pointer-events-none'
              )}
              style={{ bottom: calculateOffset() }}
              ref={refs.setFloating}
            >
              <div
                role='status'
                key={openSnackBar.key}
                className={className(
                  'w-full md:w-auto',
                  styles.snackbarWrapper,
                  {
                    [styles.closed]: openSnackBar.closed
                  }
                )}
                onAnimationEnd={(e) => {
                  if (e?.animationName?.includes('hide-snackbar')) {
                    // HACK: Add the hidden class to the div after the close animation to prevent flicker
                    e.currentTarget.classList.add('hidden')
                    sendSbReducer({
                      type: 'DEQUEUE_CLOSED_SNACKBAR',
                      data: {
                        privateKey: openSnackBar.privateKey
                      }
                    })
                  }
                }}
              >
                <SnackBar
                  className='pointer-events-auto'
                  variant={openSnackBar.variant}
                  testId={openSnackBar.testId}
                  isDismissable={determineIsDismissable(
                    openSnackBar,
                    isDismissable,
                    globalOnDismiss
                  )}
                  onDismiss={(
                    e: React.MouseEvent<HTMLButtonElement, MouseEvent>
                  ) => {
                    openSnackBar?.onDismiss?.(e)
                    globalOnDismiss?.(openSnackBar)
                    sendSbReducer({
                      type: 'CLOSE_SNACKBAR_BY_PRIVATE_KEY',
                      data: {
                        privateKey: openSnackBar.privateKey
                      }
                    })
                  }}
                  message={openSnackBar.message}
                  showIcon={openSnackBar.showIcon}
                  action={openSnackBar.action}
                />
              </div>
            </div>
          </PortalManagement>
        )}
      </>
    </SnackBarContext.Provider>
  )
}
