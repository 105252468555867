import * as React from 'react'
import cx from 'classnames'
import { useLayoutContext } from '../../common/LayoutProvider'
import { PageHeaderProps } from '../PageHeader/PageHeader'

export const PageHeaderSpacer = ({
  fixedTabList,
  testId
}: {
  fixedTabList?: PageHeaderProps['fixedTabList']
  testId?: string
}) => {
  const { isHeaderCollapsed } = useLayoutContext()
  return (
    <div
      data-testid={testId}
      className={cx(
        'transition-all duration-300',
        fixedTabList && isHeaderCollapsed
          ? 'h-1.5'
          : fixedTabList && !isHeaderCollapsed
          ? 'h-2 lg:h-6'
          : isHeaderCollapsed
          ? 'h-4'
          : 'h-4 lg:h-6'
      )}
    />
  )
}
