import { ApolloError } from '@apollo/client'
import { HOST_DOMAIN, useDomainContext } from '@local/current-domain'

import { useDepotGetSubscription } from '../../graphql-depot'
import { useToastAdminGetSubscription } from '../../graphql-toast-admin'
import { SavedSubscription } from '../../types'

const mapUseSubscription: {
  [domain in HOST_DOMAIN]: (guid?: string) => {
    subscription: SavedSubscription | undefined
    error: ApolloError | undefined
    loading: boolean
  }
} = {
  [HOST_DOMAIN.DEPOT]: useDepotGetSubscription,
  [HOST_DOMAIN.TOASTWEB]: useToastAdminGetSubscription
}

export function useSubscription(guid?: string) {
  const { hostDomain } = useDomainContext()
  return mapUseSubscription[hostDomain](guid)
}
