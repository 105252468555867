import * as React from 'react'
import {
  useFloating,
  useClick,
  useDismiss,
  useRole,
  useListNavigation,
  useTypeahead,
  useInteractions,
  offset,
  flip,
  shift,
  size as sizeFloatingUI,
  autoUpdate,
  Placement
} from '@floating-ui/react'
import { TestIdentifiable } from '@toasttab/buffet-shared-types'
import { useUniqueId } from '@toasttab/buffet-utils'
import { focusOnRefEl } from '../utils'

export type UseDropdownCommonProps = TestIdentifiable & {
  /** The preferred placement (top, bottom etc) for the dropdown */
  placement?: Placement
  /** Enables circular keyboard navigation (down from the last item goes back to the top) */
  isCircularKeyboardNav?: boolean
  /** The html id of the component */
  id?: string
  /** Display search input */
  enableSearch?: boolean
  /** Whether or not the floating element width should match the reference element width */
  matchReferenceWidth?: boolean
  /** Set this to true if the select has an actual label (i.e. the presence of the label prop) */
  hasLabel?: boolean
  /** The aria-label (if being used instead of label) */
  ariaLabel?: string
  /** The aria-labelledby attribute (if being used instead of label) */
  ariaLabelledBy?: string
  /** Is the select disabled */
  disabled?: boolean
  /** Allows for a callback when the open state of the select changes */
  onOpenChange?: (isOpen: boolean) => void
  /** Allows for a callback when the dropdown panel closes */
  onClose?: () => void
  /** A base string to use for the ids of subcomponents (e.g. use "select" for select components) */
  idBase?: string
  /** The aria role to use for the contents of the dropdown panel (defaults to the most common: listbox) */
  contentRole?:
    | 'tooltip'
    | 'dialog'
    | 'alertdialog'
    | 'menu'
    | 'listbox'
    | 'grid'
    | 'tree'
  /** Set this to true to disable floating-ui's built in keyboard search (disable this for search UIs such as autosuggest) */
  disableTypeahead?: boolean
  /** Use this to set a custom offset (via the offset middleware) */
  customOffset?: number
  /** Set this to decide which list item is selected by default */
  selectedIndex?: number | null
}

export const useDropdownCommon = <
  RefPropsType = React.HTMLAttributes<HTMLButtonElement>,
  LabelPropsType = React.HTMLAttributes<HTMLLabelElement>
>({
  placement,
  testId = 'select',
  id = 'select',
  matchReferenceWidth,
  hasLabel,
  ariaLabel,
  ariaLabelledBy,
  disabled,
  isCircularKeyboardNav,
  enableSearch,
  onOpenChange,
  onClose,
  idBase = 'dropdown',
  contentRole = 'listbox',
  disableTypeahead,
  customOffset,
  selectedIndex = null
}: UseDropdownCommonProps) => {
  const [isOpen, _setIsOpen] = React.useState(false)
  const [activeIndex, setActiveIndex] = React.useState<number | null>(null)
  const dropdownId = useUniqueId(id, `buffet-${idBase}-`, true)

  const setIsOpen = React.useCallback(
    (isOpen: boolean) => {
      _setIsOpen(isOpen)
      onOpenChange?.(isOpen)
      if (!isOpen) {
        onClose?.()
      }
    },
    [_setIsOpen, onOpenChange, onClose]
  )

  const { refs, floatingStyles, context, elements } = useFloating({
    placement,
    open: isOpen,
    onOpenChange: (isOpen) => {
      setIsOpen(isOpen)
    },
    whileElementsMounted: autoUpdate,
    middleware: [
      offset(customOffset ? customOffset : enableSearch ? 2 : 4),
      flip({ padding: 10 }),
      sizeFloatingUI({
        apply({ rects, elements }) {
          if (matchReferenceWidth) {
            Object.assign(elements.floating.style, {
              width: `${rects.reference.width}px`
            })
          }
        },
        padding: 10
      }),
      shift({
        crossAxis: true,
        // When it does need to cross the axis, pad it away from the edge of the screen if possible
        padding: 8
      })
    ]
  })

  const _testId = useUniqueId(testId, `${idBase}-`)
  const labelId = useUniqueId(dropdownId, `${idBase}-label-`, true)
  const dropdownContentsId = useUniqueId(
    dropdownId,
    `${idBase}-contents-`,
    true
  )

  const listRef = React.useRef<Array<HTMLElement | null>>([])
  const listContentRef = React.useRef<Array<string | null>>([])

  const click = useClick(context)
  const dismiss = useDismiss(context, {
    outsidePress: (event) => {
      event.stopPropagation()
      event.preventDefault()
      return true
    },
    outsidePressEvent: 'mousedown'
    // note that since our select has an overlay, an outside press also acts as a reference press
  })
  const role = useRole(context, { role: contentRole })

  const listNav = useListNavigation(context, {
    listRef,
    activeIndex,
    selectedIndex,
    onNavigate: setActiveIndex,
    loop: isCircularKeyboardNav,
    virtual: enableSearch // keep the focus in the input for searchable selects
  })
  const typeahead = useTypeahead(context, {
    listRef: listContentRef,
    activeIndex,
    enabled: !enableSearch,
    onMatch: setActiveIndex
  })

  const listBoxInteractions = [listNav]
  if (!disableTypeahead) {
    listBoxInteractions.push(typeahead)
  }

  const {
    getReferenceProps: _getReferenceProps,
    getFloatingProps: _getFloatingProps,
    getItemProps
  } = useInteractions([
    dismiss,
    role,
    click,
    ...(contentRole === 'listbox' ? listBoxInteractions : [])
  ])

  const closeMenu = React.useCallback(() => {
    setIsOpen(false)
    focusOnRefEl(elements.reference as HTMLElement)
  }, [elements.reference, setIsOpen])

  const getReferenceProps = React.useCallback(
    (additionalProps?: RefPropsType) => {
      const ariaProps: React.HTMLAttributes<HTMLElement> = {
        'aria-expanded': isOpen ? 'true' : 'false',
        'aria-controls': dropdownContentsId,
        'aria-owns': dropdownContentsId
      }
      if (hasLabel) {
        ariaProps['aria-labelledby'] = labelId
      } else if (ariaLabel) {
        ariaProps['aria-label'] = ariaLabel
      } else if (ariaLabelledBy) {
        ariaProps['aria-labelledby'] = ariaLabelledBy
      }
      return _getReferenceProps({
        id: dropdownId,
        ...ariaProps,
        disabled,
        ...additionalProps
      } as React.HTMLProps<HTMLElement>)
    },
    [
      _getReferenceProps,
      ariaLabel,
      ariaLabelledBy,
      isOpen,
      hasLabel,
      labelId,
      dropdownContentsId,
      dropdownId,
      disabled
    ]
  )

  const getFloatingProps = React.useCallback(
    (additionalProps?: React.HTMLProps<HTMLElement>) => {
      const ariaProps: React.HTMLProps<HTMLElement> = {}
      if (hasLabel) {
        ariaProps['aria-labelledby'] = labelId
      } else if (ariaLabel) {
        ariaProps['aria-label'] = ariaLabel
      } else if (ariaLabelledBy) {
        ariaProps['aria-labelledby'] = ariaLabelledBy
      }
      return _getFloatingProps({
        id: dropdownContentsId,
        ...ariaProps,
        ...additionalProps
      })
    },
    [
      _getFloatingProps,
      ariaLabel,
      ariaLabelledBy,
      hasLabel,
      labelId,
      dropdownContentsId
    ]
  )

  const getLabelProps = React.useCallback(
    (additionalProps?: LabelPropsType) => {
      return {
        id: labelId,
        htmlFor: dropdownId,
        disabled,
        ...additionalProps
      }
    },
    [labelId, dropdownId, disabled]
  )

  return {
    isOpen,
    setIsOpen,
    activeIndex,
    setActiveIndex,
    refs,
    elements,
    floatingStyles,
    context,
    listRef,
    listContentRef,
    testId: _testId,
    getFloatingProps,
    getReferenceProps,
    getItemProps,
    getLabelProps,
    closeMenu
  }
}
