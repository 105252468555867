import { gql } from '@apollo/client'

export const GET_SUBSCRIPTION = gql`
  query getSubscription($guid: ID!) {
    getSubscription(guid: $guid) {
      name
      guid
      uri
      paused
      stopped
      notificationEmail
      subscriberType
      subscriberGuid
      eventCategory {
        guid
        name
        eventSubjectType
      }
    }
  }
`
