import { HOST_DOMAIN, useDomainContext } from '@local/current-domain'
import {
  EventCategory,
  Partner,
  SavedSubscription
} from '@local/manage-webhooks-shared'

import { useDepotCreateSubscriptionForm } from './useDepotCreateSubscriptionForm'
import { useDepotEditSubscriptionForm } from './useDepotEditSubscriptionForm'
import { useToastAdminSubscriptionForm } from './useToastAdminSubscriptionForm'

export function useEditSubscriptionForm(
  existingSubscription: SavedSubscription | undefined,
  partners: Partner[],
  eventCategories: EventCategory[]
) {
  const { hostDomain } = useDomainContext()

  if (existingSubscription) {
    return useEditSubscriptionFormMap[hostDomain](
      existingSubscription,
      partners,
      eventCategories
    )
  }
  return useCreateSubscriptionFormMap[hostDomain](
    existingSubscription,
    partners,
    eventCategories
  )
}

const useCreateSubscriptionFormMap = {
  [HOST_DOMAIN.DEPOT]: useDepotCreateSubscriptionForm,
  [HOST_DOMAIN.TOASTWEB]: useToastAdminSubscriptionForm
} as const

const useEditSubscriptionFormMap = {
  [HOST_DOMAIN.DEPOT]: useDepotEditSubscriptionForm,
  [HOST_DOMAIN.TOASTWEB]: useToastAdminSubscriptionForm
} as const
