import * as React from 'react'
import {
  RadioUnselectedIcon,
  RadioSelectedIcon
} from '@toasttab/buffet-pui-icons'
import {
  SelectionInput,
  SelectionInputProps
} from '@toasttab/buffet-pui-input-base'
import { useUniqueId } from '@toasttab/buffet-utils'
import { TestIdentifiable, Labelled } from '@toasttab/buffet-shared-types'

export type RadioButtonProps = Omit<
  SelectionInputProps,
  'type' | 'uncheckedIcon' | 'checkedIcon'
> &
  TestIdentifiable &
  Labelled

export const RadioButton = React.forwardRef<HTMLInputElement, RadioButtonProps>(
  ({ testId, name, ...props }, ref) => {
    testId = useUniqueId(testId, 'radio-button-')

    return (
      <SelectionInput
        testId={testId}
        {...props}
        name={name}
        ref={ref}
        type='radio'
        uncheckedIcon={<RadioUnselectedIcon accessibility='decorative' />}
        checkedIcon={<RadioSelectedIcon accessibility='decorative' />}
      />
    )
  }
)

RadioButton.displayName = 'RadioButton'
