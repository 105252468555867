import * as React from 'react'
import { useIntersection, useLocation } from 'react-use'
import { ScreenSize, useScreenSize } from '@toasttab/use-screen-size'
import { getScrollableAncestor } from './getScrollableAncestor'

export enum FlushBreakpoint {
  SM = 1,
  MD = 2
}

export const HEADER_SCROLL_REF_HEIGHT = 200
// These are used to delay the transition to return to a non-collapsed state.
// These are finely tuned threshold change to avoid a wobble as the CSS
// animation collapses the header height
export const PAGE_VIEW_BUFFER = 32
export const FOCUS_VIEW_BUFFER = 64

const FOCUS_VIEW_INTERSECTION = 72

// The header will not collapse if the screen height is within
// 100px of the scroll height
// Without this, the collapse will shrink the screen
// enough that it will decide it shouldn't collapse
// and it will wobble back and forth between the two states
// continuously
const SHRINKAGE_FACTOR = 100

const instersectionThreshold = (px: number) => {
  return 1 - px / HEADER_SCROLL_REF_HEIGHT
}

type PagePositionsType = 'headerTop' | 'mainTop'

interface LayoutContextType {
  pagePositions: {
    headerTop: number
    mainTop: number
  }
  disableMaxWidth: boolean
  scrollToTop: boolean
  setPagePositions: (position: PagePositionsType, value: number) => void
  headerRef?: React.RefObject<HTMLElement>
  headerScrollRef?: React.RefObject<HTMLDivElement>
  sectionRef?: React.RefObject<HTMLDivElement>
  flushBreakpoint: FlushBreakpoint
  /**
   * Usually defined, but if not, the expectation is that window is scrollable.
   *
   * To scroll to the top, you could do:
   *  ```
   *  const scrollableEl = scrollElRef?.current || window
   *  scrollableEl?.scrollTo?.(0, 0)
   *  ```
   */
  scrollElRef?: React.RefObject<Element>
  isFocusView: boolean
  isTabsOffScreen: boolean
  isHeaderCollapsed: boolean
  isHeaderScrollRefOffScreen: boolean
  screenSize: ScreenSize
  isUsingLeftNav: boolean
  setIsUsingLeftNav: React.Dispatch<React.SetStateAction<boolean>>
}

const LayoutContext = React.createContext<LayoutContextType>({
  pagePositions: {
    headerTop: 0,
    mainTop: 0
  },
  disableMaxWidth: false,
  scrollToTop: true,
  setPagePositions: () => null,
  headerRef: undefined,
  headerScrollRef: undefined,
  sectionRef: undefined,
  flushBreakpoint: FlushBreakpoint.MD,
  scrollElRef: undefined,
  isFocusView: false,
  isTabsOffScreen: false,
  isHeaderCollapsed: false,
  isHeaderScrollRefOffScreen: false,
  screenSize: ScreenSize.MD,
  isUsingLeftNav: false,
  setIsUsingLeftNav: () => undefined
})

export interface LayoutProviderInternalProps {
  disableMaxWidth?: boolean
  scrollToTop?: boolean
  flushBreakpoint: FlushBreakpoint
  isFocusView: boolean
}

export const LayoutProviderInternal = ({
  disableMaxWidth = false,
  scrollToTop = true,
  flushBreakpoint,
  isFocusView,
  children
}: React.PropsWithChildren<LayoutProviderInternalProps>) => {
  const [pageHeaderTop, setPageHeaderTop] = React.useState(0)
  const [pageMainTop, setPageMainTop] = React.useState(0)
  const [isUsingLeftNav, setIsUsingLeftNav] = React.useState(false)

  const headerRef = React.useRef<HTMLDivElement>(null)
  const headerScrollRef = React.useRef<HTMLDivElement>(null)
  const sectionRef = React.useRef<HTMLDivElement>(null)

  const setPagePositions = (position: PagePositionsType, value: number) => {
    if (position === 'headerTop') {
      setPageHeaderTop(value)
    } else if (position === 'mainTop') {
      setPageMainTop(value)
    }
  }
  const pagePositions = {
    headerTop: pageHeaderTop,
    mainTop: pageMainTop
  }

  const { pathname } = useLocation()
  const pageScrollElRef = React.useRef<Element | null>(null)
  React.useEffect(() => {
    if (sectionRef?.current && !pageScrollElRef.current && !isFocusView) {
      // For some layouts the window may not be the scrollable ancestor
      // Manually setting current on a ref feels dirty, but
      // this ancestor is expected to exist and if it's destroyed
      // we would expect the Page react component itself to be destroyed
      pageScrollElRef.current = getScrollableAncestor(sectionRef.current)
    }
  }, [sectionRef, pageScrollElRef, isFocusView])

  const scrollElRef = isFocusView
    ? sectionRef
    : pageScrollElRef.current
    ? pageScrollElRef
    : undefined

  React.useEffect(() => {
    if (scrollToTop) {
      const scrollableEl = scrollElRef?.current || window
      scrollableEl?.scrollTo?.(0, 0)
    }
  }, [pathname, scrollToTop, scrollElRef])

  const screenSize = useScreenSize()

  const tabsHeight = screenSize < ScreenSize.MD ? 48 : 56

  // Make the page transition at the tabs height
  // so that when we are using tabs, there is
  // only one transition point for both page header
  // bottom border appearing and page header collapsing
  const pageIntersection = tabsHeight
  const intersection = isFocusView ? FOCUS_VIEW_INTERSECTION : pageIntersection
  const tempRef = React.useRef<HTMLElement>(null)
  const intersectionRef = headerScrollRef || tempRef

  const tabsIntersectionResult = useIntersection(intersectionRef, {
    root: null,
    rootMargin: '0px',
    threshold: instersectionThreshold(
      tabsHeight + (isFocusView ? 0 : PAGE_VIEW_BUFFER)
    )
  })
  const intersectionResult = useIntersection(intersectionRef, {
    root: null,
    rootMargin: '0px',
    threshold: instersectionThreshold(intersection)
  })

  // Don't collapse non-large screens because the header
  // might not be sticky if `isUsingPageTargetNav` -- but
  // because it's a prop on the header, we don't know it here
  // current limitation to the API -- we could move this prop
  // up one level...
  const shouldCollapseHeader = screenSize >= ScreenSize.LG

  const isHeaderScrollRefOffScreen =
    intersectionResult !== null && !intersectionResult.isIntersecting
  const isTabsOffScreen =
    tabsIntersectionResult !== null && !tabsIntersectionResult.isIntersecting

  const containerHeight = window.innerHeight - pagePositions.headerTop
  const sectionScrollHeight = sectionRef?.current?.scrollHeight || 0

  const hasEnoughRoomToCollapse =
    sectionScrollHeight - containerHeight > SHRINKAGE_FACTOR

  const isHeaderCollapsed =
    hasEnoughRoomToCollapse &&
    shouldCollapseHeader &&
    isHeaderScrollRefOffScreen

  const contextValue = {
    pagePositions,
    disableMaxWidth,
    scrollToTop,
    setPagePositions,
    headerRef,
    headerScrollRef,
    sectionRef,
    screenSize,
    flushBreakpoint,
    scrollElRef,
    isFocusView,
    isTabsOffScreen,
    isHeaderCollapsed,
    isHeaderScrollRefOffScreen,
    isUsingLeftNav,
    setIsUsingLeftNav
  }

  return (
    <LayoutContext.Provider value={contextValue}>
      {children}
    </LayoutContext.Provider>
  )
}

export const useLayoutContext = () => {
  const context = React.useContext(LayoutContext)
  if (!context) {
    throw new Error(
      'useLayoutContext must be used within a LayoutProvider component'
    )
  }
  return context
}
