import * as React from 'react'
import cx from 'classnames'
import { IconWrapper, IconProps, sizes } from '../../IconWrapper'
export const FilterIcon = React.forwardRef(function FilterIcon(
  props: IconProps,
  ref?: React.ForwardedRef<HTMLElement>
) {
  return (
    <IconWrapper ref={ref} {...props}>
      <>
        {
          <svg
            width={24}
            height={24}
            viewBox='0 0 24 24'
            fill='none'
            className={cx(
              'inline-block align-middle',
              sizes[props.size || 'sm'].className
            )}
          >
            <path
              d='M20 12h-2.7m0 0a1.7 1.7 0 11-3.4 0 1.7 1.7 0 013.4 0zm-3.5 0H4m16-6h-9.6m0 0A1.7 1.7 0 117 6a1.7 1.7 0 013.4 0zM6.9 6H4m16 12h-7.4m0 0a1.7 1.7 0 11-3.4 0 1.7 1.7 0 013.4 0zm-3.5 0H4'
              stroke='currentColor'
              strokeWidth={sizes[props.size || 'sm'].strokeWidth}
              strokeMiterlimit={10}
              strokeLinecap='round'
              strokeLinejoin='round'
            />
          </svg>
        }
      </>
    </IconWrapper>
  )
})
