import * as React from 'react'

import cx from 'classnames'

import {
  PortalManagement,
  FloatingArrow,
  useFloatingComponentZIndexClass
} from '@toasttab/buffet-pui-floating-ui-base'

import { useTooltipContext } from './useTooltipContext'
import { TestIdentifiable } from '@toasttab/buffet-shared-types'
import { useUniqueId } from '@toasttab/buffet-utils'

const arrowLight =
  'p-4 type-subhead bg-white border-none rounded shadow focus:outline-none'

const dark = 'px-4 py-2 type-subhead text-white border rounded bg-gray-125'

export interface TooltipContentProps extends TestIdentifiable {
  /**
   * Element of which we want to render the tooltip inside
   * @deprecated Please use the default portal behavior to ensure the tooltip contents are correctly css scoped
   */
  portalContainer?: HTMLElement | null
  className?: string
  disabled?: boolean
  /** Render function to render tooltip content */
  children: React.ReactNode
}

export const TooltipContent = ({
  children,
  className,
  disabled,
  testId,
  portalContainer // eslint-disable-line
}: TooltipContentProps) => {
  testId = useUniqueId(testId, 'tooltip-')

  const {
    open,
    context,
    variant,
    arrow,
    placement,
    getFloatingProps,
    floatingStyles,
    refs
  } = useTooltipContext()

  const zIndexClass = useFloatingComponentZIndexClass() // elevation: z-40 for floating components (boosted to z-50 when in modals etc)

  return open && !disabled ? (
    <PortalManagement
      context={context}
      disableFocusManager
      portalContainerEl={portalContainer}
    >
      <div
        {...getFloatingProps()}
        data-testid={testId}
        ref={refs.setFloating}
        style={floatingStyles}
        className={cx(
          'inline-block outline-none',
          variant === 'arrowLight' ? 'p-4' : 'p-2',
          zIndexClass
        )}
      >
        <div
          className={cx(
            className,
            'font-normal whitespace-normal overflow-auto',
            variant === 'arrowLight' ? arrowLight : dark
          )}
          style={{ maxWidth: 'min(100vw - 2rem, 16.25rem)' }}
        >
          {children}
        </div>
        <FloatingArrow
          ref={arrow.ref}
          context={context}
          x={arrow.x}
          y={arrow.y}
          fill={variant === 'arrowLight' ? 'white' : '#252525'}
          width={arrow.width}
          height={variant === 'arrowLight' ? 14 : 8}
          stroke={variant === 'arrowLight' ? 'rgba(0, 0, 0, 0.08)' : ''}
          strokeWidth={1}
          style={{
            margin:
              variant === 'arrowLight'
                ? /** padding p-4 = 16px + 2px for additional space */
                  placement.match(/top|bottom/)
                  ? 'calc(-1rem - 2px) 0px'
                  : '0px calc(-1rem - 2px)'
                : placement.match(/top|bottom/)
                ? /** padding p-2 = 8px + 2px for additional space */
                  'calc(-0.5rem - 2px) 0px'
                : '0px calc(-0.5rem - 2px)'
          }}
        />
      </div>
    </PortalManagement>
  ) : (
    <span {...getFloatingProps()} className='sr-only'>
      {children}
    </span>
  )
}
