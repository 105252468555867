import { useMutation } from '@apollo/client'
import { useSnackBar } from '@toasttab/buffet-pui-snackbars'
import { useSentry } from 'banquet-runtime-modules'
import { useCallback } from 'react'
import { useNavigate } from 'react-router-dom'
import { useBanquetPartner } from '@local/banquet-partner-props'

import { Subscription } from '../../types'

import { PATCH_SUBSCRIPTION } from './PATCH_SUBSCRIPTION'

export type DepotPatchSubscriptionInput = {
  guid: string
  name: string
  uri: string
  notificationEmail?: string | null
}

export function useDepotEditSubscription(onSaved?: () => void) {
  const { showSuccessSnackBar, showErrorSnackBar } = useSnackBar()
  const { partner } = useBanquetPartner()

  const { hub } = useSentry()
  const navigate = useNavigate()

  // mutation will automatically update existing subscription in cache
  const [mutateSubscription, { loading: saving }] = useMutation<{
    patchSubscription: Subscription
  }>(PATCH_SUBSCRIPTION, {
    context: { headers: { 'toast-organization-guid': partner?.guid } },
    onCompleted: (data) => {
      const newSubscription = data?.patchSubscription
      if (onSaved) {
        onSaved()
      } else {
        showSuccessSnackBar(
          `Webhook subscription ${newSubscription?.name} has been successfully updated`
        )
        navigate(`/events/${newSubscription?.guid}`)
      }
    },
    onError: (error) => {
      hub.captureException(error)
      showErrorSnackBar(
        'Oops! There has been an error updating this webhook. Please try again.',
        {
          testId: 'saving-error-snackbar'
        }
      )
    }
  })

  const saveSubscription = useCallback(
    (newSubscription: DepotPatchSubscriptionInput) =>
      mutateSubscription({
        variables: { newSubscription }
      }),
    [mutateSubscription]
  )

  return {
    saving,
    saveSubscription
  }
}
