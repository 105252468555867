import * as React from 'react'
import cx from 'classnames'
import { IconWrapper, IconProps, sizes } from '../../IconWrapper'
export const ArrowDropDownIcon = React.forwardRef(function ArrowDropDownIcon(
  props: IconProps,
  ref?: React.ForwardedRef<HTMLElement>
) {
  return (
    <IconWrapper ref={ref} {...props}>
      <>
        {
          <svg
            width={24}
            height={24}
            viewBox='0 0 24 24'
            fill='none'
            className={cx(
              'inline-block align-middle',
              sizes[props.size || 'sm'].className
            )}
          >
            <path
              d='M15.018 9.75H9.047c-1.095 0-1.393.6-.597 1.4l2.786 2.8c.299.3.498.3.796.3.3 0 .498-.1.697-.3l2.787-2.8c.796-.8.597-1.4-.498-1.4z'
              fill='currentColor'
            />
          </svg>
        }
      </>
    </IconWrapper>
  )
})
