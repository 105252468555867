import { DateFnsLocale } from '../date-utilities'
// Locales selected from: https://github.com/date-fns/date-fns/tree/master/src/locale
// eslint-disable-next-line @toasttab/buffet/external-date-libraries
import { enUS, enGB, enCA, es } from 'date-fns/locale'

export const selectDateFnsLocale = (locale: string): DateFnsLocale => {
  switch (locale) {
    case 'en-CA':
      return enCA
    case 'en-GB':
    case 'en-IE':
      return enGB
    case 'es-US':
      return es
    default:
      return enUS
  }
}
