import * as React from 'react'
import cx from 'classnames'
import { IconWrapper, IconProps, sizes } from '../../IconWrapper'
export const LookupInfoIcon = React.forwardRef(function LookupInfoIcon(
  props: IconProps,
  ref?: React.ForwardedRef<HTMLElement>
) {
  return (
    <IconWrapper ref={ref} {...props}>
      <>
        {
          <svg
            width={24}
            height={24}
            viewBox='0 0 24 24'
            fill='none'
            className={cx(
              'inline-block align-middle',
              sizes[props.size || 'sm'].className
            )}
          >
            <path
              d='M17.05 13.45v5.8c0 1-.8 1.8-1.8 1.8h-9.3c-1 0-1.8-.8-1.8-1.8V7.15c0-1 .8-1.8 1.8-1.8h2.4m-3 7.8h-2.3m2.3-4.7h-2.3m2.3 9.4h-2.3m15-8.5l3 3m-2-5.7a3.6 3.6 0 11-7.2 0 3.6 3.6 0 017.2 0z'
              stroke='currentColor'
              strokeWidth={sizes[props.size || 'sm'].strokeWidth}
              strokeMiterlimit={10}
              strokeLinecap='round'
              strokeLinejoin='round'
            />
          </svg>
        }
      </>
    </IconWrapper>
  )
})
