import { Partner } from '@local/manage-webhooks-shared'

export const getPartnerNameFromGuid = (
  subscriberGuid: string,
  partners: Partner[] | undefined
) => {
  return (
    partners?.find((partner) => partner.guid === subscriberGuid)?.name ??
    subscriberGuid
  )
}
