import { useSubscriptionSecret } from '@local/manage-webhooks-shared/graphql/hooks'
import { InfoIcon } from '@toasttab/buffet-pui-icons'
import { Tooltip } from '@toasttab/buffet-pui-tooltip'
import copy from 'copy-to-clipboard'
import { useCallback, useState } from 'react'

export function SecretSection({ guid }: { guid: string }) {
  const { secret, fetchingSecret, hideSecret, getSecret } =
    useSubscriptionSecret(guid)

  const [copied, setCopied] = useState(false)

  const SecretField = useCallback(() => {
    if (fetchingSecret)
      return <div data-testid='secret-loading'>loading...</div>
    if (secret)
      return (
        <Tooltip placement='bottom'>
          <Tooltip.Trigger
            role='button'
            onClick={() => {
              setCopied(true)
              copy(secret)
            }}
            data-testid='secret-content'
          >
            {secret}
          </Tooltip.Trigger>
          <Tooltip.Content>{copied ? 'copied' : 'copy secret'}</Tooltip.Content>
        </Tooltip>
      )
    return <div data-testid='secret-placeholder'>*************</div>
  }, [copied, fetchingSecret, secret])

  const ShowHideButton = useCallback(() => {
    if (secret)
      return (
        <button
          className='text-link underline'
          disabled={fetchingSecret}
          onClick={() => {
            setCopied(false)
            hideSecret()
          }}
        >
          Hide
        </button>
      )
    return (
      <button
        className='text-link underline'
        disabled={fetchingSecret}
        onClick={() => getSecret()}
      >
        Show
      </button>
    )
  }, [fetchingSecret, getSecret, hideSecret, secret])

  return (
    <div>
      <div className='font-bold flex gap-x-2'>
        <div>Secret</div>
        <ShowHideButton />
        <Tooltip placement='bottom'>
          <Tooltip.Trigger>
            <InfoIcon
              aria-label='focus-for-more-secret-info'
              className='mx-2 text-gray-110 font-semibold'
            />
          </Tooltip.Trigger>
          <Tooltip.Content>
            Secrets are auto generated keys used for validating the source of
            data sent to your webhook endpoint. Learn more{' '}
            <a
              href='https://doc.toasttab.com/doc/devguide/apiMessageSigning.html'
              target='_blank'
              rel='noreferrer'
              className='underline'
            >
              here
            </a>
            .
          </Tooltip.Content>
        </Tooltip>
      </div>
      <SecretField />
    </div>
  )
}
