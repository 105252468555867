import {
  ToastAdminEditSubscriptionFormValues,
  getSubscriptionStatus,
  Partner,
  SavedSubscription,
  SubscriptionType,
  DepotEditSubscriptionFormValues
} from '@local/manage-webhooks-shared'

export function buildToastAdminEditSubscriptionsFormInitialValues(
  subscription: SavedSubscription | undefined,
  partners: Partner[]
): ToastAdminEditSubscriptionFormValues {
  if (subscription) {
    return {
      uri: subscription.uri ?? '',
      name: subscription.name ?? '',
      subscriberType: subscription.subscriberType ?? SubscriptionType.Partner,
      subscriberName:
        partners.find((partner) => partner.guid === subscription.subscriberGuid)
          ?.name ?? '',
      notificationEmail: subscription.notificationEmail ?? '',
      eventCategoryGuid: subscription.eventCategory.guid,
      status: getSubscriptionStatus(subscription)
    }
  }

  return {
    uri: '',
    name: '',
    subscriberType: SubscriptionType.Partner,
    subscriberName: '',
    notificationEmail: '',
    eventCategoryGuid: ''
  }
}

export function buildDepotEditSubscriptionsFormInitialValues(
  subscription: SavedSubscription
): DepotEditSubscriptionFormValues {
  return {
    eventCategoryGuid: subscription?.eventCategory?.guid ?? '',
    uri: subscription.uri ?? '',
    name: subscription.name ?? '',
    notificationEmail: subscription.notificationEmail ?? ''
  }
}
