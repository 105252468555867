import { Button } from '@toasttab/buffet-pui-buttons'
import { AlertModal, Modal } from '@toasttab/buffet-pui-modal'
import { useState } from 'react'
import { useNavigate } from 'react-router-dom'

export function CancelButton({ unsavedChanges }: { unsavedChanges?: boolean }) {
  const negative = useNavigate()

  const [showFormUpdatedWarning, setShowFormUpdatedWarning] = useState(false)
  return (
    <>
      <Button
        variant='secondary'
        onClick={() => {
          if (unsavedChanges) {
            setShowFormUpdatedWarning(true)
          } else {
            negative(-1)
          }
        }}
        testId='initial-cancel'
      >
        Cancel
      </Button>
      <AlertModal
        isOpen={showFormUpdatedWarning}
        onRequestClose={() => setShowFormUpdatedWarning(false)}
      >
        <Modal.Header>You have unsaved changes on the form</Modal.Header>
        <Modal.Body>
          <p>Cancel without saving?</p>
        </Modal.Body>
        <Modal.Footer>
          <Button
            variant='secondary'
            onClick={() => negative(-1)}
            testId='warning-modal-discard'
          >
            Discard
          </Button>
          <Button
            onClick={() => setShowFormUpdatedWarning(false)}
            testId='warning-modal-cancel'
          >
            Cancel
          </Button>
        </Modal.Footer>
      </AlertModal>
    </>
  )
}
