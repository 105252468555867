import { HOST_DOMAIN, useDomainContext } from '@local/current-domain'
import { useCallback, useMemo, useState } from 'react'

import { useDepotGetSubscriptionSecret } from '../../graphql-depot'
import { useToastAdminGetSubscriptionSecret } from '../../graphql-toast-admin'

export function useSubscriptionSecret(guid: string) {
  const [secret, setSecret] = useState<string>()

  const hideSecret = useCallback(() => setSecret(undefined), [])

  const { hostDomain } = useDomainContext()
  const useGetSubscriptionSecret = useMemo(
    () => useGetSubscriptionSecretMap[hostDomain],
    [hostDomain]
  )

  const { getSecret, fetchingSecret } = useGetSubscriptionSecret(
    guid,
    setSecret
  )

  return {
    fetchingSecret,
    getSecret,
    secret,
    hideSecret
  }
}

const useGetSubscriptionSecretMap = {
  [HOST_DOMAIN.DEPOT]: useDepotGetSubscriptionSecret,
  [HOST_DOMAIN.TOASTWEB]: useToastAdminGetSubscriptionSecret
}
