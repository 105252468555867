import { useCallback } from 'react'
import { Header } from '@tanstack/react-table'
import { SortDirection, SortIndicator } from '@toasttab/buffet-pui-table'

export function TableHeadingCellSortIndicator<T>({
  header
}: Readonly<{
  header: Header<T, unknown>
}>) {
  // can't use useMemo as header.column won't update when sort direction change
  const getSortDirection = useCallback((headerWithSort: Header<T, unknown>) => {
    if (
      !headerWithSort.column.getCanSort() ||
      !headerWithSort.column.getIsSorted()
    )
      return SortDirection.NONE
    if (headerWithSort.column.getIsSorted() === 'desc')
      return SortDirection.DESC
    return SortDirection.ASC
  }, [])

  if (!header.column.getCanSort()) return null

  return <SortIndicator direction={getSortDirection(header)} />
}
