import * as React from 'react'
import cx from 'classnames'
import { ChevronLeftIcon } from '@toasttab/buffet-pui-icons'
import { useContainerSize } from '@toasttab/use-container-size'
import { useLayoutContext } from '../../common'
import { IconButton, IconButtonProps } from '@toasttab/buffet-pui-buttons'

const defaultElement = 'button'

type PageBackProps<
  TElementType extends React.ElementType = typeof defaultElement
> = Omit<IconButtonProps<TElementType>, 'icon' | 'iconColor'> & {
  as?: TElementType
  /**
   * @deprecated Please use `label` instead.
   */
  text?: string
  /**
   * An accessible label assigned to the button, when the icon cannot be as
   * descriptive.
   */
  label?: string
  /**
   * @deprecated PageBackButton is now deprecated, and PageBack renders its own
   * action based on the `as` prop, to use a button, anchor or Link.
   */
  children?: React.ReactNode
}

function convertRemToPixels(rem: number) {
  return rem * parseFloat(getComputedStyle(document.documentElement).fontSize)
}

export function PageBack<
  TElementType extends React.ElementType = typeof defaultElement
>({
  className,
  testId = 'page-back',
  // eslint-disable-next-line
  text = 'Back',
  label = 'Back',
  // eslint-disable-next-line
  children,
  ...restProps
}: PageBackProps<TElementType>): JSX.Element {
  const containerSize = useContainerSize()
  const { disableMaxWidth } = useLayoutContext()

  // The outer max width from the Page component
  const maxWidthOuter = disableMaxWidth ? 99999 : convertRemToPixels(68.25)

  // The max-width from `container` tailwindcss utility in the PageArea component
  const maxWidthInner =
    containerSize.width < 1280 // xl
      ? 1024
      : containerSize.width < 1366 // xxl
      ? 1280
      : 1366

  // max width is constrained by two containers, one in px, the other in rem!
  const maxWidth = Math.min(
    // padding from the lg:px-8 in PageArea
    maxWidthOuter - convertRemToPixels(4),
    maxWidthInner
  )

  const floatBackButton =
    containerSize.width > maxWidth + convertRemToPixels(7.25)

  return (
    <div
      className={cx(
        'h-10',
        'order-first flex-grow-0',
        floatBackButton
          ? 'absolute left-0 bottom-0 -ml-14 pl-1'
          : '-ml-2 self-center lg:self-end',
        className
      )}
      data-testid={testId}
    >
      {/* Change to keep backward compatibility. If children are passed, render
      them. Otherwise, show the new content. */}
      {children || (
        <IconButton
          // Default, but will be overridden by `restProps`
          aria-label={label}
          as={defaultElement}
          className={cx(
            className,
            'flex flex-col w-12 h-12 lg:h-10 -mt-1 lg:mt-0 justify-center items-center'
          )}
          {...(restProps as IconButtonProps<TElementType>)}
          icon={<ChevronLeftIcon accessibility='decorative' />}
          iconColor='secondary'
        />
      )}
    </div>
  )
}
