export type Variant = 'bgGray' | 'textOnly' | 'icon' | 'iconButton'

export const variants: Readonly<{
  [P in Variant]: P
}> = Object.freeze({
  bgGray: 'bgGray',
  textOnly: 'textOnly',
  icon: 'icon',
  iconButton: 'iconButton'
})

export type WithVariant<T> = T & { Variant: typeof variants }
