import * as React from 'react'
import cx from 'classnames'
import { SearchInput } from '@toasttab/buffet-pui-text-input'
import { selectAllKey } from '../useSelect'
import { SelectItem } from '../types'
import { t, loadStrings } from '../defaultStrings'

export interface SelectSearchProps<TItem> {
  testId?: string
  searchTerm?: string
  handleSearch: (v: string) => void
  hasNoResults: boolean
  searchPlaceholder?: string
  setIsOpen: (arg: boolean) => void
  onSearch?: (v: string) => void
  enableSelectAll?: boolean
  isOpen?: boolean
  isMultipleSelect?: boolean
  activeIndex: number | null
  options: TItem[]
  handleSelect: (item: TItem) => void
  clearSearchTerm: () => void
  hasAdditionalActions?: boolean
}

export const SelectSearch = <TValue, TItem extends SelectItem<TValue>>({
  testId = 'select-search',
  searchTerm,
  handleSearch,
  hasNoResults,
  searchPlaceholder = t('search'),
  setIsOpen,
  onSearch,
  enableSelectAll,
  isOpen,
  isMultipleSelect,
  activeIndex,
  options,
  handleSelect,
  clearSearchTerm,
  hasAdditionalActions
}: SelectSearchProps<TItem>) => {
  const searchInputRef = React.useRef<HTMLInputElement>(null)

  React.useEffect(() => {
    if (isOpen) {
      searchInputRef.current?.focus()
    } else {
      clearSearchTerm()
    }
  }, [isOpen, clearSearchTerm])

  const multiSelectKeyboardHandler = React.useCallback(
    (event: React.KeyboardEvent<HTMLInputElement>) => {
      if (event.key === 'Enter' && activeIndex !== null) {
        const isSelectAllShowing = !searchTerm && enableSelectAll
        const adjustedActiveIndex = isSelectAllShowing
          ? activeIndex - 1
          : activeIndex
        if (isSelectAllShowing && activeIndex === 0) {
          // select all
          handleSelect({ value: selectAllKey } as unknown as TItem)
        } else if (options[adjustedActiveIndex]) {
          handleSelect(options[adjustedActiveIndex])
        }
      }
    },
    [activeIndex, enableSelectAll, handleSelect, options, searchTerm]
  )

  const selectKeyboardHandler = React.useCallback(
    (event: React.KeyboardEvent<HTMLInputElement>) => {
      if (
        (event.key === 'Enter' || event.key === 'Tab') &&
        activeIndex != null &&
        options[activeIndex]
      ) {
        if (event.key === 'Tab' && hasAdditionalActions) {
          // if the user tabs when there are additional actions, we don't make the selection etc
          return
        }
        handleSelect(options[activeIndex])
        searchInputRef.current?.blur()
        clearSearchTerm()
        if (event.key === 'Enter') {
          // floating-ui (useClick) will re-open the select if we don't do this...
          event.preventDefault()
          event.stopPropagation()
        }
        setIsOpen(false)
      }
    },
    [
      activeIndex,
      options,
      hasAdditionalActions,
      handleSelect,
      clearSearchTerm,
      setIsOpen
    ]
  )

  loadStrings()

  return (
    <>
      <SearchInput
        testId={testId}
        containerClassName={cx('px-3 pt-4 pb-2', {
          'pb-2': enableSelectAll
        })}
        placeholder={searchPlaceholder}
        onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
          handleSearch(event.target.value)
        }}
        value={searchTerm}
        onKeyDown={(event) => {
          if (isMultipleSelect) {
            multiSelectKeyboardHandler(event)
          } else {
            selectKeyboardHandler(event)
          }
        }}
        ref={searchInputRef}
        aria-label={t('search')}
        {...(!!onSearch && { value: undefined })} // make uncontrolled when using external search
      />
      {hasNoResults && (
        <div className='px-3 pt-4 font-normal type-default'>
          {t('no-results-found')}
        </div>
      )}
    </>
  )
}
