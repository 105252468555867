import { Subscription, SubscriptionStatus } from '../types'

export const getSubscriptionStatus = (subscription: Subscription) => {
  if (subscription.stopped) {
    return SubscriptionStatus.Inactive
  } else if (subscription.paused) {
    return SubscriptionStatus.Paused
  } else {
    return SubscriptionStatus.Active
  }
}

export const isCurrentStatusActive = (status: SubscriptionStatus) => {
  return status === SubscriptionStatus.Active
}
