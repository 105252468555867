import { NumberInput } from '@toasttab/buffet-pui-text-input'
import * as React from 'react'
import { formatTimeValue, isInteger } from '../utils'
import { TimePickerNumberInputProps } from './types'

export const HoursInput = React.forwardRef<
  HTMLInputElement,
  TimePickerNumberInputProps
>(
  (
    {
      disabled,
      invalid,
      required,
      value,
      defaultValue,
      commitValue,
      changeValue,
      ...numberInputProps
    },
    ref
  ) => (
    <NumberInput
      ref={ref}
      disabled={disabled.hours}
      allowLeadingZeros
      containerClassName='flex-grow w-20'
      invalid={invalid}
      format='##'
      inputMode='numeric'
      value={formatTimeValue(value?.hours)}
      defaultValue={formatTimeValue(defaultValue?.hours)}
      onBlur={() => commitValue()}
      onValueChange={({ floatValue }) =>
        changeValue((currentValue) => ({
          ...currentValue,
          hours: isInteger(floatValue) ? floatValue : null
        }))
      }
      required={required}
      aria-required={required}
      aria-label='Hour'
      {...numberInputProps}
    />
  )
)
