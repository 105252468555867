import { useQuery } from '@apollo/client'
import {
  EventCategory,
  Partner,
  Subscription
} from '@local/manage-webhooks-shared'
import { useSentry } from 'banquet-runtime-modules'

import { GET_WEBHOOKS } from './GET_WEBHOOKS'

export function useToastAdminWebhooks() {
  const { hub } = useSentry()

  const { loading, error, data } = useQuery<{
    getSubscriptions: Subscription[]
    getPartners: Partner[]
    getEventCategories: EventCategory[]
  }>(GET_WEBHOOKS, {
    onError: (newError) => {
      hub.captureException(newError)
    }
  })

  return {
    loading,
    error,
    subscriptions: data?.getSubscriptions,
    partners: data?.getPartners,
    eventCategories: data?.getEventCategories
  }
}
