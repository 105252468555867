import { dateFnsFormat as format, parse, isToday, defaultFormats } from '../'
import { Locale, getLocale } from '@toasttab/buffet-pui-locale-utilities'

export const isPmToAmPm = (isPm: boolean) => (isPm ? 'PM' : 'AM')

export interface TimePoint {
  hours: number | string | null
  minutes: number | string | null
  isPm: boolean
}

export interface TimeInterval {
  start: Date
  end: Date
}

export const timePointFromString = (
  time: string,
  formatStr: string = defaultFormats.time_short_24
): TimePoint | null => {
  try {
    const date = parse(time, formatStr, new Date())
    const formatted = format(date, 'h:mm:a')
    const parts = formatted.split(':')
    return {
      hours: parseInt(parts[0]) || 12,
      minutes: parts[1] || '00',
      isPm: parts[2] === 'PM'
    }
  } catch (e) {
    return null
  }
}

export const timePointFromString12Hours = (
  time: string,
  // eslint-disable-next-line @toasttab/buffet/date-formats
  formatStr: string = 'h:mm a'
): TimePoint | null => {
  try {
    const date = parse(time, formatStr, new Date())
    const formatted = format(date, 'h:mm:a')
    const parts = formatted.split(':')
    return {
      hours: parseInt(parts[0], 10) || 12,
      minutes: parts[1] || '00',
      isPm: parts[2] === 'PM'
    }
  } catch (e) {
    return null
  }
}

export const formatTime = (
  time: TimePoint,
  showMinute: boolean = true,
  locale?: Locale
) => {
  try {
    if (time.hours === null) {
      return ''
    }
    let hours = +time.hours
    if (time.isPm && hours < 12) {
      hours += 12
    } else if (!time.isPm && hours === 12) {
      hours = 0
    }
    const date = new Date(0, 0, 0, hours, time.minutes ? +time.minutes : 0, 0)
    // eslint-disable-next-line @toasttab/buffet/external-date-libraries
    const localeTimeString = date.toLocaleTimeString(locale || getLocale(), {
      hour12: true,
      hour: 'numeric',
      minute: showMinute ? '2-digit' : undefined
    })
    return localeTimeString === 'Invalid Date' ? '' : localeTimeString
  } catch (e) {
    return ''
  }
}

export const formatHourAmPm = (hour: string | number, locale?: Locale) => {
  try {
    const parsed = parse(hour.toString(), 'H', new Date())
    const timeObj = {
      hours:
        parsed.getHours() > 12 ? parsed.getHours() - 12 : parsed.getHours(),
      minutes: 0,
      isPm: parsed.getHours() >= 12
    }
    return formatTime(timeObj, false, locale)
  } catch (e) {
    return ''
  }
}

export const dateFromTimePoint = (timePoint: TimePoint) => {
  if (!timePoint?.hours) return null

  try {
    const toString = formatTime(timePoint)
    // eslint-disable-next-line @toasttab/buffet/date-formats
    return parse(toString, 'h:mm a', new Date())
  } catch (e) {
    return null
  }
}

export const isTimeBefore = (first: Date, second: Date) => {
  try {
    const minutesFirst = getTimeInMinutes(first)
    const minutesSecond = getTimeInMinutes(second)
    return minutesFirst < minutesSecond
  } catch (e) {
    return false
  }
}

export const areIntervalsAdjacent = (
  interval1: TimeInterval,
  interval2: TimeInterval
) => {
  try {
    return (
      areTimesEqual(interval1.start, interval2.end) ||
      areTimesEqual(interval2.start, interval1.end) ||
      areTimesEqual(interval1.end, interval2.start) ||
      areTimesEqual(interval2.end, interval1.start)
    )
  } catch (e) {
    return false
  }
}

export const areIntervalsEqual = (
  interval1: TimeInterval,
  interval2: TimeInterval
) => {
  try {
    return (
      areTimesEqual(interval1.start, interval2.start) &&
      areTimesEqual(interval1.end, interval2.end)
    )
  } catch (e) {
    return false
  }
}

export const areIntervalsOverlapping = (
  interval1: TimeInterval,
  interval2: TimeInterval
) => {
  try {
    const interval1Start = interval1.start
    const interval1End = interval1.end
    const interval2Start = interval2.start
    const interval2End = interval2.end

    return (
      areTimesEqual(interval1Start, interval2Start) ||
      areTimesEqual(interval1End, interval2End) ||
      isTimePointContainedInInterval(interval1, interval2Start) ||
      isTimePointContainedInInterval(interval1, interval2End) ||
      isTimePointContainedInInterval(interval2, interval1Start) ||
      isTimePointContainedInInterval(interval2, interval1End)
    )
  } catch (e) {
    return false
  }
}

export const dayOfTheWeek = (
  date: Date,
  useToday: boolean = true,
  locale?: Locale
): string => {
  if (useToday && isToday(date)) {
    return 'Today'
  }
  // eslint-disable-next-line @toasttab/buffet/external-date-libraries
  return date.toLocaleDateString(locale || getLocale(), {
    weekday: 'long'
  })
}

//---------------------------------------------------------
// Helpers
//---------------------------------------------------------

const isTimePointContainedInInterval = (
  interval: TimeInterval,
  timePoint: Date
) => {
  const startTime = interval.start
  const endTime = interval.end
  if (isTimeBefore(startTime, endTime)) {
    return (
      isTimeBefore(startTime, timePoint) && isTimeBefore(timePoint, endTime)
    )
  }

  return isTimeBefore(startTime, timePoint) || isTimeBefore(timePoint, endTime)
}

const getTimeInMinutes = (time: Date) =>
  time.getHours() * 60 + time.getMinutes()

const areTimesEqual = (time1: Date, time2: Date) => {
  try {
    return (
      time1.getHours() === time2.getHours() &&
      time1.getMinutes() === time2.getMinutes()
    )
  } catch (e) {
    return false
  }
}
