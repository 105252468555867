import { useEffect, useState } from 'react'
import { useDebouncedState } from '@toasttab/use-debounced-state'

interface WindowSize {
  width: number
  height: number
}

export const useWindowSize = (): WindowSize => {
  const [windowSize, windowSizeSet] = useState<WindowSize>({
    width: window.innerWidth,
    height: window.innerHeight
  })
  const [, debounceWidthSet] = useDebouncedState(windowSizeSet, 500)
  useEffect(() => {
    const listener = () =>
      debounceWidthSet({ width: window.innerWidth, height: window.innerHeight })

    window.addEventListener('resize', listener)

    return () => window.removeEventListener('resize', listener)
  }, [debounceWidthSet])

  return windowSize
}
