import * as React from 'react'
import cx from 'classnames'
import { Box, PolymorphicComponentProps } from 'react-polymorphic-box'
import { IconProps } from '@toasttab/buffet-pui-icons'
import {
  getIconButtonStyle,
  GetIconButtonStyleProps
} from './getIconButtonStyle'
import { deleteNonButtonProps } from '../Button/deleteNonButtonProps'

const defaultElement = 'button'

export type IconButtonProps<
  TElementType extends React.ElementType = typeof defaultElement
> = PolymorphicComponentProps<
  TElementType,
  GetIconButtonStyleProps & {
    testId?: string | number
    icon: React.FunctionComponentElement<IconProps>
  }
>

export const IconButton: <
  TElementType extends React.ElementType = typeof defaultElement
>(
  props: IconButtonProps<TElementType>
) => React.ReactElement | null = React.forwardRef(
  <TElementType extends React.ElementType = typeof defaultElement>(
    { testId, icon, className, ...props }: IconButtonProps<TElementType>,
    ref: typeof props.ref
  ) => {
    const { button, icon: _icon } = getIconButtonStyle(props)
    // Avoid spreading non button props in button element, that could cause warnings
    const {
      as: asProp,
      contained,
      cropToIcon,
      cropToIconMarginClassName,
      iconColor,
      size,
      textClassName,
      ...buttonProps
    } = props

    const as =
      asProp === undefined || buttonProps.disabled ? defaultElement : asProp

    if (buttonProps.disabled && asProp !== undefined && asProp !== 'button') {
      // Only do this weird mutation thing in this edge case
      // which we explicitly code for and support
      deleteNonButtonProps(buttonProps)
    }

    // Ensure that the appropriate element-specific default props get added
    const defaultProps =
      as === 'button'
        ? {
            type: 'button'
          }
        : {}
    return (
      <Box
        as={as}
        ref={ref}
        data-testid={testId}
        className={cx(button.className, className)}
        style={button.style}
        {...defaultProps}
        // The cast is marginally better than a ts-ignore,
        // and sort of reasonable given we understand
        // the ButtonProps type.
        {...(buttonProps as IconButtonProps<TElementType>)}
      >
        {React.cloneElement<IconProps>(icon, {
          ...icon.props,
          className: cx(
            icon.props && icon.props.className ? icon.props.className : '',
            _icon.className
          )
        })}
      </Box>
    )
  }
)
