import * as React from 'react'

import { IconButton } from '@toasttab/buffet-pui-buttons'

import { useTooltipContext } from './useTooltipContext'

import type { IconButtonProps } from '@toasttab/buffet-pui-buttons'
import type { TestIdentifiable } from '@toasttab/buffet-shared-types'
import { useMergeRefs } from '@toasttab/buffet-pui-floating-ui-base'

export interface TooltipTriggerProps
  extends React.HtmlHTMLAttributes<HTMLDivElement> {}

export interface TooltipTriggerPropsInternal extends TestIdentifiable {}

export function TooltipTrigger(props: TooltipTriggerProps) {
  const { refs, getReferenceProps } = useTooltipContext()

  return (
    <span tabIndex={0} {...getReferenceProps(props)} ref={refs.setReference} />
  )
}

const defaultElement = 'button'

export const TooltipTriggerIconButton: <
  TElementType extends React.ElementType = typeof defaultElement
>(
  props: IconButtonProps<TElementType>
) => React.ReactElement | null = React.forwardRef(
  <TElementType extends React.ElementType = typeof defaultElement>(
    { icon, ...props }: IconButtonProps<TElementType>,
    ref: typeof props.ref
  ) => {
    const { refs, getReferenceProps } = useTooltipContext()
    const mergedRef = useMergeRefs(
      ref ? [ref, refs.setReference] : [refs.setReference]
    )
    return (
      <IconButton {...getReferenceProps(props)} icon={icon} ref={mergedRef} />
    )
  }
)

TooltipTrigger.IconButton = TooltipTriggerIconButton
