/* Locale date strings for en-CA locale */
import { OptionalFormats, OptionalDateMaskFormat } from './default'

export const enCA: OptionalFormats = {
  date_short_without_year: 'MM-dd',
  date_shorter: 'yy-MM-dd',
  date_short: 'P',
  time_shorter: 'h aaaa',
  time_short: 'h:mm aaaa',
  time_medium: 'h:mm:ss aaaa',
  time_long: 'h:mm:ss aaaa O',
  time_full: 'h:mm:ss aaaa OOOO',
  dateTime_shorter: 'yy-MM-dd, h aaaa',
  dateTime_short: 'yyyy-MM-dd, h:mm aaaa',
  dateTime_short_24: 'yyyy-MM-dd, HH:mm',
  dateTime_medium: 'MMM d, yyyy, h:mm aaaa',
  dateTime_medium_with_seconds: 'MMM d, yyyy, h:mm:ss aaaa',
  dateTime_long: 'MMMM do, yyyy, h:mm:ss aaaa O',
  dateTime_full: 'MMMM do, yyyy, h:mm:ss aaaa OOOO'
}

export const enCAMasks: OptionalDateMaskFormat = {
  mask_short: {
    placeholder: ['YYYY', 'MM', 'DD'],
    delimiter: '-',
    mask: ['Y', 'Y', 'Y', 'Y', 'M', 'M', 'D', 'D'],
    pattern: ['####', '##', '##'],
    parseFormat: {
      input: 'yyyyMMdd',
      output: 'yyyy-MM-dd'
    }
  }
}
