import { useScreenSize, ScreenSize } from '@toasttab/use-screen-size'
import * as React from 'react'
import { createPageRange } from './createPageRange'

export interface UsePaginationConfig<P> {
  initialPage?: number
  totalPageCount?: number
  currentPage?: number
  onChangePage?: (newPage: number) => void
  pageData?: ((page: number) => P) | P[]
  visiblePages?: number
  shouldWrap?: boolean
  className?: string
}

export interface PaginationBag<P> {
  currentPage: number
  totalPageCount: number
  currentPageData: P
  gotoPage: (page: number) => void
  gotoNext: () => void
  hasNext: boolean
  gotoPrevious: () => void
  hasPrevious: boolean
  shouldWrap?: boolean
  className?: string
  pageControls: Array<
    | { isPlaceholder: true; key: string; page?: undefined }
    | { page: number; key: string; isPlaceholder?: undefined }
  >
}

export const usePagination = <P = unknown>({
  pageData,
  visiblePages,
  currentPage: currentPageInput,
  totalPageCount: totalPageCountInput = 0,
  initialPage = 0,
  onChangePage = () => {},
  shouldWrap,
  className
}: UsePaginationConfig<P>): PaginationBag<P> => {
  const isTabletOrLarger = useScreenSize() >= ScreenSize.MD
  const [currentPage, setCurrentPage] = React.useState(
    currentPageInput ?? initialPage
  )

  const totalPageCount = Array.isArray(pageData)
    ? pageData.length
    : totalPageCountInput

  const currentPageData = React.useMemo(
    () =>
      pageData! &&
      (Array.isArray(pageData) ? pageData[currentPage] : pageData(currentPage)),
    [pageData, currentPage]
  )

  const gotoPage = React.useCallback(
    (page: number) => {
      onChangePage(page)
      setCurrentPage(page)
    },
    [onChangePage]
  )

  const hasPrevious = currentPage > 0
  const hasNext = currentPage < totalPageCount - 1

  const gotoPrevious = React.useCallback(
    () => gotoPage(hasPrevious ? currentPage - 1 : totalPageCount - 1),
    [gotoPage, hasPrevious, currentPage, totalPageCount]
  )

  const gotoNext = React.useCallback(
    () => gotoPage(hasNext ? currentPage + 1 : 0),
    [gotoPage, hasNext, currentPage]
  )

  const pageControls = React.useMemo(
    () =>
      createPageRange({
        currentPage,
        totalPageCount,
        visiblePages: visiblePages ?? (isTabletOrLarger ? 7 : 5)
      }).map((page, index) =>
        page === null
          ? ({ isPlaceholder: true, key: `placeholder-${index}` } as const)
          : ({
              page,
              key: `page-${page}`
            } as const)
      ),
    [currentPage, totalPageCount, visiblePages, isTabletOrLarger]
  )

  React.useEffect(() => {
    if (typeof currentPageInput === 'number') {
      setCurrentPage(currentPageInput)
    }
  }, [currentPageInput])

  return {
    currentPage,
    currentPageData,
    totalPageCount,
    gotoPage,
    gotoNext,
    hasNext,
    gotoPrevious,
    hasPrevious,
    pageControls,
    shouldWrap,
    className
  }
}
