import { useState, useEffect, useRef, useCallback } from 'react'

// https://doc.toasttab.com/internal/webexperience/buffet/index.html?path=/docs/hooks-use-debounced-state--usage
export function useDebouncedState<T>(
  fn: (state: T) => void,
  delay: number,
  initialState?: T
): [T | undefined, (state: T) => void] {
  const isInitialized = useRef(false)

  const [state, _setState] = useState(initialState)
  const setState = useCallback(
    (state: T) => {
      isInitialized.current = true
      _setState(state)
    },
    [isInitialized, _setState]
  )

  useEffect(() => {
    if (!isInitialized.current) {
      return
    }
    const id = setTimeout(() => {
      // This is T, not T | undefined, because we're guaranteed to be initialised before invoking this
      // But we can't use a type guard as T itself could match undefined
      fn(state as T)
    }, delay)
    return () => {
      clearTimeout(id)
    }
  }, [fn, delay, state])

  return [state, setState]
}
