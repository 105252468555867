import { Button } from '@toasttab/buffet-pui-buttons'
import { AlertModal, Modal } from '@toasttab/buffet-pui-modal'
import { useState } from 'react'

export function DepotDeleteSubscriptionButton() {
  const [showHelpModal, setShowHelpModal] = useState(false)
  return (
    <>
      <Button
        variant='destructive'
        testId='depot-delete-subscription-button'
        onClick={() => setShowHelpModal(true)}
        aria-label='delete subscription'
      >
        Delete
      </Button>
      <AlertModal
        testId='depot-delete-modal'
        isOpen={showHelpModal}
        onRequestClose={() => setShowHelpModal(false)}
      >
        <Modal.Header>Delete webhook</Modal.Header>
        <Modal.Body>
          <p>
            To permanently delete a webhook, please{' '}
            <a
              href='https://developers.toasttab.com/hc/en-us/requests/new'
              target='_blank'
              className='inline-link'
              rel='noreferrer'
              data-testid='delete-support'
            >
              open a support ticket
            </a>{' '}
            with Toast Developer Relations.
          </p>
        </Modal.Body>
      </AlertModal>
    </>
  )
}
