import {
  NoEventEmptyState,
  NoEventMatchFilterEmptyState
} from '@local/app-routes/NoWebhooksMatchPanel/NoWebhooksMatchPanel'
import { useEvents } from '@local/manage-webhooks-shared'
import { Panel } from '@toasttab/buffet-pui-config-templates'
import { ErrorPage500 } from '@toasttab/buffet-pui-error-pages'
import { MerryGoRound } from '@toasttab/buffet-pui-loading-indicators'
import { Pagination, usePagination } from '@toasttab/buffet-pui-pagination'
import classnames from 'classnames'
import { FunctionComponent, useCallback, useEffect, useState } from 'react'

import { Event } from '../../manage-webhooks-shared/types/event.type'
import { EventsFilters } from '../EventsFilters/EventsFilters'
import {
  defaultFilterValues,
  useEventsFilters
} from '../EventsFilters/useEventsFilters'
import { EventsTable } from '../EventsTable/EventsTable'

export interface EventsListProps {
  subscriptionGuid: string
  pageSize?: number
}
export const EventsList: FunctionComponent<EventsListProps> = ({
  subscriptionGuid,
  pageSize = 10
}) => {
  const [currentPage, setCurrentPage] = useState(0)

  const { filters, filterIsDefault, setFilters } = useEventsFilters()

  const [showNewestFirst, setShowNewestFirst] = useState<boolean>(true)
  const onSortChange = useCallback(
    (accessor: string, sortDesc: boolean) => {
      if (accessor === 'timestamp') {
        setShowNewestFirst(sortDesc)
      }
    },
    [setShowNewestFirst]
  )

  const onPaginationReset = useCallback(() => {
    setCurrentPage(0)
  }, [])

  const {
    events,
    getEventsLoading,
    getEventsError,
    resendEvent,
    replaying,
    hasNextPage,
    fetchNextPage
  } = useEvents(
    subscriptionGuid,
    filters,
    pageSize,
    onPaginationReset,
    showNewestFirst
  )

  const totalPageCount =
    Math.ceil(events.length / pageSize) + (hasNextPage ? 1 : 0)
  const onChangePage = useCallback(
    (newPage: number) => {
      if (hasNextPage && newPage >= totalPageCount - 1) {
        fetchNextPage().then(() => {
          setCurrentPage(newPage)
        })
      } else {
        setCurrentPage(newPage)
      }
    },
    [hasNextPage, totalPageCount, fetchNextPage, setCurrentPage]
  )

  const paginationProps = usePagination<Event>({
    currentPage,
    totalPageCount,
    visiblePages: 0,
    onChangePage
  })

  const [pageEvents, setPageEvents] = useState<Array<Event>>([])
  useEffect(() => {
    const start = pageSize * currentPage
    setPageEvents(events.slice(start, start + pageSize))
  }, [events, currentPage, setPageEvents, pageSize])

  if (getEventsError) {
    return <ErrorPage500 />
  }

  if (!getEventsLoading && pageEvents.length === 0)
    return (
      <>
        <EventsFilters filters={filters} onFiltersChange={setFilters} />
        {filterIsDefault ? (
          <NoEventEmptyState />
        ) : (
          <NoEventMatchFilterEmptyState
            handleClearSearch={() => setFilters(defaultFilterValues)}
          />
        )}
      </>
    )

  return (
    <>
      <div className='flex flex-row justify-between'>
        <EventsFilters filters={filters} onFiltersChange={setFilters} />
      </div>
      <Panel>
        <div className='relative'>
          <EventsTable
            events={pageEvents}
            onResend={resendEvent}
            onSortChange={onSortChange}
            isSortedNewestFirst={showNewestFirst}
            replaying={replaying}
          />
          <div
            className={classnames(
              { hidden: !getEventsLoading },
              'absolute flex flex-row justify-center items-center w-full h-full top-0 bg-white bg-opacity-75'
            )}
          >
            <MerryGoRound size='xxl' />
          </div>
        </div>
        <Pagination
          {...paginationProps}
          className={classnames(
            { hidden: getEventsLoading },
            'mt-2 justify-end'
          )}
        />
      </Panel>
    </>
  )
}
