import { ApolloClient } from '@apollo/client'
import { getIsDepotDomain } from '@local/current-domain'
import { cache } from '@local/manage-webhooks-shared'

enum HOST {
  TOASTWEB = 'TOASTWEB',
  DEPOT = 'DEPOT'
}

type ClientParams = {
  name: string
  uri: string
}

const apolloClientParams: { [host in HOST]: ClientParams } = {
  [HOST.TOASTWEB]: {
    name: 'manage-webhooks-spa-toastweb',
    uri: '/api/service/webhooks-bff/v1/graphql'
  },
  [HOST.DEPOT]: {
    name: `manage-webhooks-spa-depot`,
    uri: `/api/service/depot-bff/v1/graphql`
  }
}

export function getApolloClient() {
  const currentHost = getIsDepotDomain() ? HOST.DEPOT : HOST.TOASTWEB
  const { name, uri } = apolloClientParams[currentHost]

  return new ApolloClient({
    name,
    uri,
    cache
  })
}
