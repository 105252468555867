import * as React from 'react'
import cx from 'classnames'
import { useLayoutContext } from '../LayoutProvider'
interface TitleProps {
  testId?: string | number
  className?: string
}

export const Title: React.FC<React.PropsWithChildren<TitleProps>> = ({
  testId = 'config-template-title',
  className = '',
  children
}) => {
  const { isHeaderCollapsed } = useLayoutContext()

  return (
    <h1
      className={cx(
        'transition-all duration-300',
        'font-normal',
        '-mx-px',
        // The `-my-px` forces a typography with a line-height of 42px to
        // live in a space that's meant to be only 40px tall
        isHeaderCollapsed
          ? 'type-headline-3'
          : 'type-headline-3 md:type-headline-2 -my-px',
        className
      )}
      data-testid={testId}
    >
      {children}
    </h1>
  )
}
