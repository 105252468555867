import { DepotWebhooksContextProvider } from '@local/manage-webhooks-shared/WebhooksContext/WebhooksContext'
import { BrowserRouter, Navigate, Route, Routes } from 'react-router-dom'
import { EventsPage } from '@local/events-list'

import { Default404Page } from '../Default404Page/Default404Page'
import { DepotSubscriptionsPage } from '../DepotSubscriptionsPage/DepotSubscriptionsPage'
import { EditSubscriptionPage } from '../../../src/components/EditSubscriptionPage/EditSubscriptionPage'

export function DepotRoutes() {
  return (
    <DepotWebhooksContextProvider>
      <BrowserRouter basename='webhooks'>
        <Routes>
          <Route path='/' element={<Navigate to='/subscriptions' replace />} />
          <Route path='/subscriptions' element={<DepotSubscriptionsPage />} />
          <Route path='/events/:guid' element={<EventsPage />} />
          <Route path='/update/:guid' element={<EditSubscriptionPage />} />
          <Route path='/create' element={<EditSubscriptionPage />} />
          <Route path='*' element={<Default404Page />} />
        </Routes>
      </BrowserRouter>
    </DepotWebhooksContextProvider>
  )
}
