import {
  ToastAdminEditSubscriptionFormValues,
  Partner,
  SavedSubscription,
  SubscriptionStatus,
  SubscriptionType,
  DepotEditSubscriptionFormValues,
  NewSubscription
} from '@local/manage-webhooks-shared'
import { DepotPatchSubscriptionInput } from '@local/manage-webhooks-shared/graphql-depot/useDepotEditSubscription/useDepotEditSubscription'

export const getPartnerGuidFromName = (
  partnerName: string,
  partners: Partner[]
) => {
  return partners.find((partner) => partner.name === partnerName)?.guid ?? ''
}

const getStoppedPausedFromStatus = (
  status: string | undefined
): {
  stopped: boolean
  paused: boolean
} => {
  if (status === SubscriptionStatus.Inactive) {
    return {
      stopped: true,
      paused: false
    }
  }

  if (status === SubscriptionStatus.Paused) {
    return {
      stopped: false,
      paused: true
    }
  }

  return { stopped: false, paused: false }
}

export const getToastAdminSubscriptionFromForm = (
  formValues: ToastAdminEditSubscriptionFormValues,
  subscription: SavedSubscription | undefined,
  partners: Partner[]
): NewSubscription => {
  return {
    guid: subscription?.guid,
    name: formValues.name,
    subscriberType: formValues.subscriberType as SubscriptionType,
    subscriberGuid: getPartnerGuidFromName(formValues.subscriberName, partners),
    eventCategory: { guid: formValues.eventCategoryGuid },
    uri: formValues.uri,
    notificationEmail: formValues.notificationEmail
      ? formValues.notificationEmail
      : '',
    ...getStoppedPausedFromStatus(formValues.status)
  }
}

export const getDepotSubscriptionFromForm = (
  formValues: DepotEditSubscriptionFormValues,
  subscription: SavedSubscription
): DepotPatchSubscriptionInput => {
  return {
    guid: subscription.guid,
    name: formValues.name,

    uri: formValues.uri,
    notificationEmail: formValues.notificationEmail
      ? formValues.notificationEmail
      : ''
  }
}
