import { useMutation } from '@apollo/client'
import { useSnackBar } from '@toasttab/buffet-pui-snackbars'
import { useBanquetProps, useSentry } from 'banquet-runtime-modules'
import { useCallback } from 'react'
import { useNavigate } from 'react-router-dom'

import { NewSubscription, Subscription } from '../../types'
import { GET_WEBHOOKS } from '../useToastAdminWebhooks/GET_WEBHOOKS'

import { UPDATE_SUBSCRIPTION } from './UPDATE_SUBSCRIPTION'
import { CREATE_SUBSCRIPTION } from './CREATE_SUBSCRIPTION'

type UpdateSubscriptionData = {
  updateSubscription: Subscription
  createSubscription: undefined
}
type CreateSubscriptionData = {
  updateSubscription: undefined
  createSubscription: Subscription
}

export function useToastAdminEditSubscription(
  guid?: string,
  onSaved?: () => void
) {
  const { auth } = useBanquetProps()
  const userGuid = auth?.userInfo.guid
  const { showSuccessSnackBar, showErrorSnackBar } = useSnackBar()

  const { hub } = useSentry()
  const navigate = useNavigate()

  // mutation will automatically update existing subscription in cache
  const [mutateSubscription, { loading: saving }] = useMutation<
    UpdateSubscriptionData | CreateSubscriptionData
  >(guid ? UPDATE_SUBSCRIPTION : CREATE_SUBSCRIPTION, {
    variables: { userGuid: userGuid },
    onCompleted: (data) => {
      if (onSaved) {
        onSaved()
      } else {
        const newSubscription =
          data.updateSubscription || data.createSubscription
        showSuccessSnackBar(
          `Webhook subscription ${
            newSubscription?.name
          } has been successfully ${guid ? 'updated' : 'created'}`
        )
        if (guid) {
          navigate(`/events/${newSubscription?.guid}`)
        } else {
          navigate('/subscriptions')
        }
      }
    },
    onError: (error) => {
      hub.captureException(error)
      showErrorSnackBar(
        'Oops! There has been an error updating this webhook. Please try again.',
        {
          testId: 'saving-error-snackbar'
        }
      )
    },
    // refetch if create new subscription
    awaitRefetchQueries: !guid,
    refetchQueries: [GET_WEBHOOKS]
  })

  const saveSubscription = useCallback(
    (newSubscription: NewSubscription) =>
      mutateSubscription({
        variables: { subscription: newSubscription }
      }),
    [mutateSubscription]
  )

  return {
    saving,
    saveSubscription
  }
}
