import * as React from 'react'
import cx from 'classnames'
import { IconWrapper, IconProps, sizes } from '../../IconWrapper'
export const RadioSelectedIcon = React.forwardRef(function RadioSelectedIcon(
  props: IconProps,
  ref?: React.ForwardedRef<HTMLElement>
) {
  return (
    <IconWrapper ref={ref} {...props}>
      <>
        {
          <svg
            width={24}
            height={24}
            viewBox='0 0 24 24'
            fill='none'
            className={cx(
              'inline-block align-middle',
              sizes[props.size || 'sm'].className
            )}
          >
            <path d='M12 18a6 6 0 100-12 6 6 0 000 12z' fill='currentColor' />
            <path
              d='M12 21.75c-5.4 0-9.8-4.4-9.8-9.7 0-5.3 4.4-9.8 9.8-9.8s9.8 4.4 9.8 9.8-4.5 9.7-9.8 9.7zm0-18c-4.5 0-8.2 3.7-8.2 8.2 0 4.5 3.6 8.3 8.2 8.3s8.2-3.7 8.2-8.2c0-4.5-3.7-8.3-8.2-8.3z'
              fill='currentColor'
            />
          </svg>
        }
      </>
    </IconWrapper>
  )
})
