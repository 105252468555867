import { gql } from '@apollo/client'

export const GET_PARTNERS = gql`
  query getPartners {
    getPartners {
      guid
      name
    }
  }
`
