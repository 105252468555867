import { useMutation } from '@apollo/client'
import { useSnackBar } from '@toasttab/buffet-pui-snackbars'
import { useSentry } from 'banquet-runtime-modules'
import { useNavigate } from 'react-router-dom'

import { Subscription, SubscriptionType } from '../../types'
import { DEPOT_GET_WEBHOOKS } from '../useDepotWebhooks/DEPOT_GET_WEBHOOKS'

import { CREATE_SUBSCRIPTION } from './CREATE_SUBSCRIPTION'
import { ErrorResponse } from './errorResponse.type'

export type DepotNewSubscriptionInput = {
  name: string
  subscriberType: SubscriptionType
  subscriberGuid: string
  uri: string
  eventCategory: {
    guid: string
  }
  createdBy: string
  modifiedBy: string
  notificationEmail?: string
}

export function useDepotCreateSubscription(onSaved?: () => void) {
  const { showSuccessSnackBar, showErrorSnackBar } = useSnackBar()

  const { hub } = useSentry()
  const navigate = useNavigate()

  // mutation will automatically update existing subscription in cache
  const [saveSubscription, { loading: saving }] = useMutation<{
    createSubscription: Subscription
  }>(CREATE_SUBSCRIPTION, {
    onCompleted: (data) => {
      const newSubscription = data?.createSubscription
      if (onSaved) {
        onSaved()
      } else {
        showSuccessSnackBar(
          `Webhook subscription ${newSubscription?.name} has been successfully created`
        )
        navigate('/')
      }
    },
    onError: (error) => {
      hub.captureException(error)
      // check server 400 error
      const errorResponse: ErrorResponse | undefined = error?.graphQLErrors?.[0]
        ?.extensions?.response as ErrorResponse

      showErrorSnackBar(
        errorResponse?.body?.status === 400 && errorResponse.body.message
          ? errorResponse.body.message
          : 'Oops! There has been an error creating this webhook. Please try again.',
        {
          testId: 'saving-error-snackbar'
        }
      )
    },
    awaitRefetchQueries: true,
    refetchQueries: [DEPOT_GET_WEBHOOKS]
  })

  return {
    saving,
    saveSubscription
  }
}
