import * as React from 'react'
import cx from 'classnames'

interface PageActionsProps {
  testId?: string | number
  className?: string
}

export const PageActions = ({
  testId = 'page-actions',
  className = '',
  children
}: React.PropsWithChildren<PageActionsProps>) => {
  return (
    <div
      className={cx(
        // elevation: mobile page actions render as a sticky footer - which use `z-10` according to our guidelines
        'fixed left-0 right-0 bottom-0 p-4 bg-white z-10 lg:bg-transparent flex justify-center shadow-md-flush lg:relative lg:left-auto lg:right-auto lg:bottom-auto lg:p-0 lg:self-end lg:shadow-none lg:ml-2',
        className
      )}
      data-testid={testId}
      data-buffet-bottom-toolbar
    >
      {children}
    </div>
  )
}
