import {
  Subscription,
  getSubscriptionStatus
} from '@local/manage-webhooks-shared'
import { useMemo } from 'react'

export function useMappedSubscriptionDetailFields(subscription: Subscription) {
  return useMemo(() => {
    return [
      { label: 'Name', value: subscription.name },
      { label: 'Webhook URL', value: subscription.uri },
      {
        label: 'Event category',
        value: subscription.eventCategory?.name
      },
      {
        label: 'Notification email',
        value: subscription.notificationEmail
      },
      { label: 'Status', value: getSubscriptionStatus(subscription) }
    ]
  }, [subscription])
}
