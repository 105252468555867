import { Filter } from '@local/manage-webhooks-shared'
import { useMemo, useState } from 'react'

export function useEventsFilters() {
  const [filters, setFilters] = useState<Filter>(defaultFilterValues)

  const filterIsDefault = useMemo(
    () => checkFiltersEqual(defaultFilterValues, filters),
    [filters]
  )

  return {
    filters,
    setFilters,
    filterIsDefault
  }
}

export const defaultFilterValues: Filter = {
  status: null,
  startDateTime: undefined,
  endDateTime: undefined
}

export const WEBHOOKS_STORAGE_EVENTS_MAX_AGE_DAYS = 28

export function checkFiltersEqual<T>(defaultFilter: T, filter: T): boolean {
  return JSON.stringify(defaultFilter) === JSON.stringify(filter)
}
