import * as React from 'react'
import cx from 'classnames'
import { IconWrapper, IconProps, sizes } from '../../IconWrapper'
export const WarningFilledIcon = React.forwardRef(function WarningFilledIcon(
  props: IconProps,
  ref?: React.ForwardedRef<HTMLElement>
) {
  return (
    <IconWrapper ref={ref} {...props}>
      <>
        {
          <svg
            width={24}
            height={24}
            viewBox='0 0 24 24'
            fill='none'
            className={cx(
              'inline-block align-middle',
              sizes[props.size || 'sm'].className
            )}
          >
            <path
              fillRule='evenodd'
              clipRule='evenodd'
              d='M10.51 3.137a3.073 3.073 0 014.104 1.065l3.954 6.366 3.723 5.995a3.072 3.072 0 01-2.61 4.694H4.327a3.073 3.073 0 01-2.61-4.695l3.723-5.994 3.954-6.366c.275-.444.66-.81 1.117-1.065zm1.494 4.367a1 1 0 011 1v4.534a1 1 0 01-2 0V8.504a1 1 0 011-1zm0 10.318a1.25 1.25 0 100-2.5 1.25 1.25 0 000 2.5z'
              fill='currentColor'
            />
          </svg>
        }
      </>
    </IconWrapper>
  )
})
