import { EventCategory, Subscription } from '@local/manage-webhooks-shared'

export const countSubscriptionsWithEventCategory = (
  subscriptions: Subscription[],
  eventCategory: EventCategory
) => {
  return subscriptions.filter(
    (subscription) => subscription.eventCategory!.name === eventCategory.name
  ).length
}
