import { Colors } from '@toasttab/buffet-pui-badge'

export enum EventStatus {
  SENT_SUCCESS = 'SENT_SUCCESS',
  SENT_FAIL = 'SENT_FAIL',
  SENT_PENDING_RESPONSE = 'SENT_PENDING_RESPONSE',
  NOT_SENT = 'NOT_SENT'
}

export interface EventStatusBuffetBadgeProps {
  message?: string
  color?: keyof typeof Colors
}

export type EventStatusFilterOptions = keyof typeof EventStatus | 'ALL'
