import { useWebhooksContext } from '@local/manage-webhooks-shared/WebhooksContext/WebhooksContext'
import { Panel } from '@toasttab/buffet-pui-config-templates'
import { ErrorPage500 } from '@toasttab/buffet-pui-error-pages'
import { MerryGoRound } from '@toasttab/buffet-pui-loading-indicators'

import { EventCategoriesTable } from '../EventCategoriesTable/EventCategoriesTable'

export const EventCategoriesList = () => {
  const { loading, error, eventCategories, subscriptions } =
    useWebhooksContext()

  if (loading)
    return (
      <span className='flex flex-row justify-center w-full'>
        <MerryGoRound size='xxl' />
      </span>
    )

  if (error) {
    return <ErrorPage500 />
  }

  return (
    <Panel>
      <EventCategoriesTable
        eventCategories={eventCategories ?? []}
        subscriptions={subscriptions ?? []}
      />
    </Panel>
  )
}
