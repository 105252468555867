import * as React from 'react'
import cx from 'classnames'
import { useLayoutContext } from '../../common/LayoutProvider'
import { PageHeaderProps } from '../PageHeader/PageHeader'

export const PageHeaderDivider = ({
  fixedTabList,
  isUsingTabs,
  testId
}: {
  fixedTabList?: PageHeaderProps['fixedTabList']
  isUsingTabs?: PageHeaderProps['isUsingTabs']
  testId?: string
}) => {
  const { isTabsOffScreen, isUsingLeftNav } = useLayoutContext()

  const dividerVisibility = () => {
    if (fixedTabList) {
      // Bottom border comes from TabList > TabListScrollContainerInternal
      return 'hidden'
    }
    if (isUsingTabs) {
      if (isTabsOffScreen) {
        if (isUsingLeftNav) {
          return 'hidden lg:block'
        } else {
          return 'hidden md:block'
        }
      } else {
        return 'hidden'
      }
    } else if (isUsingLeftNav) {
      return 'hidden lg:block'
    } else {
      return 'hidden md:block'
    }
  }

  return (
    <div
      data-testid={testId}
      className={cx(
        'absolute left-0 bottom-0 w-full',
        'h-px bg-gray-50',
        dividerVisibility()
      )}
    />
  )
}
