import { EventCategory, Partner } from '@local/manage-webhooks-shared'
import { useMemo } from 'react'
import { object, string } from 'yup'

export function useToastAdminGetEditSubscriptionFormSchema(
  partners: Partner[],
  eventCategories: EventCategory[]
) {
  const validateSubscriberName = useValidateSubscriberName(partners)
  const validateEventCategoryGuid =
    useValidateEventCategoryGuid(eventCategories)

  return object().shape({
    name: validateName,
    uri: validateUrl,
    subscriberName: validateSubscriberName,
    eventCategoryGuid: validateEventCategoryGuid,
    notificationEmail: validateEmail
  })
}

export function useDepotGetEditSubscriptionFormSchema() {
  return object().shape({
    name: validateName,
    uri: validateUrl,
    notificationEmail: validateEmail
  })
}

export function useDepotCreateSubscriptionFormSchema(
  eventCategories: EventCategory[]
) {
  const validateEventCategoryGuid =
    useValidateEventCategoryGuid(eventCategories)

  return object().shape({
    eventCategoryGuid: validateEventCategoryGuid,
    uri: validateUrl,
    name: validateName,
    notificationEmail: validateEmail
  })
}

function useValidateSubscriberName(partners: Partner[]) {
  const partnerNames = useMemo(
    () => partners.map((partner) => partner.name),
    [partners]
  )

  return string()
    .required('Enter a subscriber name')
    .test(
      'valid subscriber name',
      'Select from the predefined list of partners',
      (value) => !!value && partnerNames.includes(value)
    )
}

function useValidateEventCategoryGuid(eventCategories: EventCategory[]) {
  const eventCategoryGUIDs = useMemo(
    () => eventCategories.map((ec: EventCategory) => ec.guid),
    [eventCategories]
  )

  return string()
    .required('Please select an event category')
    .test(
      'valid event category',
      'Select from the predefined list of event categories',
      (value) => !!value && eventCategoryGUIDs.includes(value)
    )
}

const validateName = string()
  .required('Enter a webhook name')
  .max(255, 'Name must be shorter than 256 characters')
  .min(3, 'Name must be longer than 3 characters')

const validateUrl = string()
  .url('Invalid URL')
  .required('Enter a webhook URL')
  .matches(
    /(https:\/\/)[a-z0-9]+/,
    'Webhook URL must be on a secured https domain'
  )

const validateEmail = string()
  .nullable()
  // allowed email formats are '', 'a@test.com', 'a@test.com,b@test.com'
  // however we don't want to actively prompt user to input multiple emails
  // this is only to allow existing subscriptions with multiple emails to be updated
  .test('valid email or emails', 'Invalid email', async (value) => {
    if (!value) return true
    for (const email of value.split(',')) {
      if (!email) return false
      if (!(await string().email().isValid(email))) return false
    }
    return true
  })
