import { Table } from '@tanstack/react-table'
import { Head, Row } from '@toasttab/buffet-pui-table'

import { BuffetHeadingCell } from '../TableHeadingCell'

export function TableHead<T>({ table }: Readonly<{ table: Table<T> }>) {
  return (
    <Head>
      {table.getHeaderGroups().map((headerGroup) => (
        <Row key={headerGroup.id}>
          {headerGroup.headers.map((header) => (
            <BuffetHeadingCell key={header.id} header={header} />
          ))}
        </Row>
      ))}
    </Head>
  )
}
