import * as React from 'react'
import cx from 'classnames'

export interface PageAreaProps {
  testId?: string | number
  className?: string
}

/**
 * @deprecated -- PageArea is an internal component.
 * Please migrate to PageBody, which supports a collapsible PageHeader
 */
export const PageArea: React.FC<React.PropsWithChildren<PageAreaProps>> = ({
  testId = 'page-container',
  className = '',
  children
}) => {
  return (
    <div className={cx('lg:px-8 md:px-5', className)} data-testid={testId}>
      <div className='lg:container lg:mx-auto'>
        <div>{children}</div>
      </div>
    </div>
  )
}
