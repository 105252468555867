import { AutoSuggestTextInputField } from '@toasttab/buffet-pui-forms'
import {
  ToastAdminEditSubscriptionFormValues,
  Partner
} from '@local/manage-webhooks-shared'
import { UseComboboxProps } from 'downshift'
import { useFormikContext } from 'formik'
import { useMemo, useState } from 'react'

export function SelectPartnerField({ partners }: { partners: Partner[] }) {
  const availablePartners = useMemo(() => partners, [partners])
  const { initialValues } =
    useFormikContext<ToastAdminEditSubscriptionFormValues>()
  const [selectedPartner, setSelectedPartner] = useState(
    initialValues.subscriberName ?? ''
  )

  const [filteredPartners, setFilteredPartners] = useState(partners || [])

  const partnersComboboxOptions: UseComboboxProps<string> = useMemo(
    () => ({
      items: filteredPartners.map((partner: Partner) => partner.name),
      onInputValueChange: ({ inputValue }) => {
        setSelectedPartner(inputValue || '')
        setFilteredPartners(
          partners?.filter((partner: Partner) =>
            partnerMatchInput(inputValue, partner)
          ) ?? []
        )
      },
      onSelectedItemChange: (event) => {
        setSelectedPartner(event.selectedItem ?? '')
      }
    }),
    [filteredPartners, availablePartners]
  )

  const itemsNames = useMemo(() => (filteredPartners.map((partner: Partner) => partner.name)), [filteredPartners])
  
  return (
    <AutoSuggestTextInputField
      required
      name='subscriberName'
      testId='subscriber-name'
      label='Subscriber name'
      items={itemsNames}
      defaultValue={selectedPartner}
      comboboxProps={partnersComboboxOptions}
      placeholder='Type to search for a partner by name or by guid'
      suffixVariant='iconButton'
      renderItem={(value: string | null) =>
        renderSinglePartnerItem(value, availablePartners)
      }
    />
  )
}

function partnerMatchInput(inputValue: string | undefined, partner: Partner) {
  if (!inputValue) return true
  if (partner.name.toLowerCase().includes(inputValue.toLowerCase())) return true
  if (partner.guid.toLowerCase().includes(inputValue.toLowerCase())) return true

  return false
}

function renderSinglePartnerItem(value: string | null, partners: Partner[]) {
  if (!value) return null

  const partner = partners.filter(
    (partner: Partner) => partner.name === value
  )[0]
  if (!partner) return null

  return (
    <>
      <div className='type-default font-normal text-default'>
        {partner.name}
      </div>
      <div className='type-caption font-normal text-secondary'>
        {partner.guid}
      </div>
    </>
  )
}
