import { Locale } from '@toasttab/buffet-pui-locale-utilities'
import { defaultMasks, enCAMasks, enGBMasks, Mask, Masks } from './locales'

const selectCustomMasks = (locale: string): Record<string, Mask> => {
  let customMasks = {}
  switch (locale) {
    case 'en-GB':
    case 'en-IE':
      customMasks = enGBMasks
      break
    case 'en-CA':
      customMasks = enCAMasks
      break
    default:
      break
  }
  return {
    ...defaultMasks,
    ...customMasks
  }
}

export const selectMask = (
  format: string,
  locale: Locale
): Mask | undefined => {
  const customMask = selectCustomMasks(locale)
  if (!customMask[format]) return customMask[Masks.short]
  return customMask[format]
}
