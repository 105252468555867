import * as React from 'react'
import { TextToggleSwitch } from '@toasttab/buffet-pui-toggle-switches'
import { TimePickerToggleInputProps } from './types'

export const AmPmToggle = React.forwardRef<
  HTMLInputElement,
  TimePickerToggleInputProps
>(
  (
    { testId, value, commitValue, defaultValue, disabled, ...toggleInputProps },
    ref
  ) => (
    <TextToggleSwitch
      ref={ref}
      testId={testId}
      disabled={disabled.isPm}
      options={['AM', 'PM']}
      uppercase
      containerClassName='flex-grow ml-2'
      className='w-24'
      isActive={value?.isPm}
      defaultChecked={defaultValue?.isPm}
      onChange={() =>
        commitValue({
          isPm: !value?.isPm
        })
      }
      label='Time of day'
      hideLabel
      {...toggleInputProps}
    />
  )
)
