import * as React from 'react'
import cx from 'classnames'

import { BorderProps } from './Border'
import { Prefix } from '../Prefix/Prefix'
import { Suffix } from '../Suffix/Suffix'
import { getInputPaddingLeft, getInputPaddingRight } from '../utils'

export const BorderContained = React.forwardRef<
  HTMLDivElement,
  React.PropsWithChildren<BorderProps>
>(
  (
    {
      children,
      invalid,
      disabled,
      readOnly,
      prefix,
      prefixVariant = 'bgGray',
      suffix,
      suffixVariant = 'bgGray',
      roundedFull,
      className,
      forceInnerStroke,
      testId,
      borderRadiusClassName,
      label
    },
    ref
  ) => {
    const borderRadius = roundedFull ? 'rounded-full' : 'rounded-input'
    return (
      <label
        data-testid={`${testId}-border-outer`}
        className={cx(
          'relative focus-within:shadow-focus block',
          {
            'z-0':
              (prefixVariant === 'bgGray' && prefix) ||
              (suffixVariant === 'bgGray' && suffix)
          },
          'p-0',
          borderRadius,
          className,
          borderRadiusClassName
        )}
      >
        <div
          data-testid={`${testId}-border-inner`}
          ref={ref}
          className={cx(
            'p-0',
            'border h-full flex flex-col justify-center',
            borderRadius,
            {
              'border-error': invalid && !forceInnerStroke,
              'hover:border-primary-75 focus-within:border-primary-75 hover:shadow-focus-within focus-within:shadow-focus-within':
                !disabled && !readOnly,
              'border-primary-75 shadow-focus-within': forceInnerStroke,
              'border-disabled': disabled,
              'border-darken-12': readOnly && !disabled
            },
            borderRadiusClassName
          )}
        >
          <div className='flex items-center'>
            {prefix && (
              <Prefix
                testId={`${testId}-prefix`}
                variant={prefixVariant}
                disabled={disabled}
                readOnly={readOnly}
              >
                {prefix}
              </Prefix>
            )}
            <div className='flex flex-col w-full m-px'>
              <div
                style={{
                  paddingLeft: getInputPaddingLeft({
                    prefix,
                    prefixVariant
                  }),
                  paddingRight: getInputPaddingRight({
                    suffix,
                    suffixVariant
                  })
                }}
                className={cx(
                  'input-label-text',
                  disabled ? 'text-disabled' : 'input-label-color'
                )}
              >
                {label}
              </div>
              {children}
            </div>
            {suffix && (
              <Suffix
                testId={`${testId}-suffix`}
                variant={suffixVariant}
                disabled={disabled}
                roundedClassName={roundedFull ? 'rounded-r-full' : undefined}
                readOnly={readOnly}
              >
                {suffix}
              </Suffix>
            )}
          </div>
        </div>
      </label>
    )
  }
)
