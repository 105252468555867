import React from 'react'
import { forwardRef } from 'react'
import cx from 'classnames'
import { uniqueId } from '@toasttab/buffet-utils'
import { IconButton } from '@toasttab/buffet-pui-buttons'
import {
  CheckCircleSelectedIcon,
  CloseIcon,
  WarningOutlineIcon
} from '@toasttab/buffet-pui-icons'
import { t, loadStrings } from '../defaultStrings'

export enum Variant {
  Neutral = 'neutral',
  Error = 'error',
  Success = 'success'
}

export interface SnackBarProps {
  testId?: string
  variant?: Variant
  message: React.ReactNode
  showIcon?: boolean
  isDismissable?: boolean
  onDismiss?: (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void
  className?: string
  /**
   * An additional action to be displayed within the snackbar. Can be any
   * clickable element (anchor, link, button, etc.), which should accept a
   * `className` prop.
   */
  action?: React.FunctionComponentElement<{ className?: string }>
}

const getVariantClasses = (variant: Variant): string => {
  switch (variant) {
    case Variant.Error:
      return 'bg-error-100'
    case Variant.Success:
      return 'bg-success-75'
    case Variant.Neutral:
    default:
      return 'bg-gray-110'
  }
}

const getIcon = (variant: Variant): React.ReactNode => {
  switch (variant) {
    case Variant.Error:
      return <WarningOutlineIcon aria-label={t('error')} />
    case Variant.Success:
      return <CheckCircleSelectedIcon aria-label={t('success')} />
    default:
      return null
  }
}

/**
 *  A SnackBar element
 *
 * @param variant the snackbar variant: Neutral, Success or Error
 * @param testId applied as `data-testid` which defaults to `snackbar-{random}` if not specified
 * @param className classes to be applied to the snackbar
 * @param onDismiss an event which will be fired when the user clicks the close button, if no event is provided the button is not rendered
 * @param message the text to display in the snackbar
 */
export const SnackBar = forwardRef<HTMLDivElement, SnackBarProps>(
  (
    {
      variant = Variant.Neutral,
      testId = uniqueId('snackbar-'),
      className,
      isDismissable = false,
      onDismiss = () => {},
      message,
      showIcon,
      action,
      ...props
    },
    ref
  ): React.ReactElement<SnackBarProps> => {
    const hasAnyAction = isDismissable || action
    loadStrings()
    return (
      <div
        ref={ref}
        className={cx(
          'flex items-start shadow-lg gap-2 md:mx-auto md:mb-2',
          'rounded-snackbar type-default text-white',
          getVariantClasses(variant),
          className
        )}
        data-testid={testId}
        {...props}
      >
        <div
          className={cx('flex items-start flex-1 gap-2 pl-4 py-3', {
            'pr-4': !hasAnyAction
          })}
        >
          {showIcon && variant !== Variant.Neutral && (
            <div data-testid={`${testId}-icon`} className='leading-none'>
              {getIcon(variant)}
            </div>
          )}
          <p>{message}</p>
        </div>
        {/*
          We need 16px horizontally, 12px vertically (like in the above div).
          The IconButton, when shown, embeds a 8px padding, thus we need to add
          less padding to compensate and align with above div (displayed on the
          left hand).
        */}
        {hasAnyAction && (
          <div className='flex items-center gap-2'>
            {action &&
              React.cloneElement(action, {
                // We need 16px space between left and right groups. When close
                // is shown, it adds 8px, so gap-2 fills in the 8px missing.
                // When action is shown we are adding 8px to the left of the
                // action to mimic the close padding.
                className: cx('inline-link-light my-3 ml-2', {
                  'mr-4': !isDismissable
                })
              })}
            {isDismissable && (
              <IconButton
                aria-label={t('dismiss')}
                iconColor='light'
                onClick={onDismiss}
                // Uses sm to suppress internal padding
                size='sm'
                className='py-1 pr-2'
                icon={<CloseIcon size='sm' accessibility='decorative' />}
              />
            )}
          </div>
        )}
      </div>
    )
  }
)
