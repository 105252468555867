export const FLUSH = 'flush'
export const ROUNDED = 'rounded'
export const AUTO_SM = 'auto-sm'
export const AUTO_MD = 'auto-md'

export type VerticalEdgeTypes =
  | typeof FLUSH
  | typeof ROUNDED
  | typeof AUTO_SM
  | typeof AUTO_MD

export const getRoundingStyles = (verticalEdge: VerticalEdgeTypes) => {
  switch (verticalEdge) {
    case FLUSH:
      return ''
    case ROUNDED:
      return 'rounded-card'
    case AUTO_SM:
      return 'sm:rounded-card'
    case AUTO_MD:
    default:
      return 'md:rounded-card'
  }
}

export const getShadowStyles = (verticalEdge: VerticalEdgeTypes) => {
  switch (verticalEdge) {
    case FLUSH:
      return 'card-elevation-flush'
    case ROUNDED:
      return 'card-elevation'
    case AUTO_SM:
      return 'card-elevation-flush sm:card-elevation'
    case AUTO_MD:
    default:
      return 'card-elevation-flush md:card-elevation'
  }
}
