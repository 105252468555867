import { useCallback } from 'react'
import {
  HeadingCell,
  SortDirection,
  SortIndicator
} from '@toasttab/buffet-pui-table'
import { ColumnInstance } from 'react-table'

export const getSortableHeaderCells = (
  headers: ColumnInstance<object>[],
  onSortChange: (accessor: string, sortedDesc: boolean) => void = () => {},
  manualSortBy = false
) => {
  return headers.map((column: any) => {
    const headerProps = column.getHeaderProps({
      className: column.className,
      ...column.getSortByToggleProps()
    })
    const onHeaderSort = useCallback(
      (event: any, accessor: string, sortedDesc: boolean) => {
        if (!manualSortBy) {
          headerProps.onClick(event)
        }
        onSortChange(accessor, sortedDesc)
      },
      [headerProps]
    )
    return (
      <HeadingCell
        {...headerProps}
        key={column.id}
        tabIndex={0}
        onKeyDown={(e) => {
          e.key === 'Enter' && onHeaderSort(e, column.id, !column.isSortedDesc)
        }}
        onClick={(e) => {
          if (!column.disableSortBy) {
            onHeaderSort(e, column.id, !column.isSortedDesc)
          }
        }}
        isSorted={column.isSorted}
        {...(!column.disableSortBy && { role: 'button' })}
        testId={`data-table-${column.id}-heading`}
        className='type-default align-center'
      >
        <span className='flex flex-row items-center'>
          {column.render('Header')}
          {!column.disableSortBy && (
            <SortIndicator
              className='w-2 h-full'
              direction={
                !column.isSorted
                  ? SortDirection.NONE
                  : column.isSortedDesc
                  ? SortDirection.DESC
                  : SortDirection.ASC
              }
            />
          )}
        </span>
      </HeadingCell>
    )
  })
}
