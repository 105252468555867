import { gql } from '@apollo/client'

export const DEPOT_GET_WEBHOOKS = gql`
  query depotGetWebhooks {
    getSubscriptionsForSubscriber {
      name
      guid
      uri
      notificationEmail
      eventCategory {
        guid
        name
        eventSubjectType
      }
      subscriberType
      subscriberGuid
      stopped
      paused
      createdBy
      modifiedBy
    }
    getAvailableEventCategoriesForSubscriber {
      guid
      name
      eventSubjectType
    }
  }
`
