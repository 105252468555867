import { HOST_DOMAIN, useDomainContext } from '@local/current-domain'
import { FC, useMemo } from 'react'

import { ToastAdminRoutes } from '../ToastAdminRoutes/ToastAdminRoutes'
import { DepotRoutes } from '../DepotRoutes/DepotRoutes'

export function AppRoutes() {
  const { hostDomain } = useDomainContext()
  const CurrentDomainRoutes = useMemo(() => AppMapper[hostDomain], [hostDomain])
  return <CurrentDomainRoutes />
}

const AppMapper: Readonly<{ [domain in HOST_DOMAIN]: FC }> = {
  [HOST_DOMAIN.TOASTWEB]: ToastAdminRoutes,
  [HOST_DOMAIN.DEPOT]: DepotRoutes
}
