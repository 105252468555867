import * as React from 'react'
import cx from 'classnames'
import { CardContainer, CardContainerProps } from '@toasttab/buffet-pui-card'
import { FlushBreakpoint, useLayoutContext } from '../LayoutProvider'
import { SettingGroupHeader } from '../SettingGroupHeader/SettingGroupHeader'

export interface PanelProps extends CardContainerProps {
  title?: string
  subtitle?: React.ReactNode
}

export const Panel: React.FC<React.PropsWithChildren<PanelProps>> = ({
  title,
  subtitle,
  className = '',
  children,
  ...props
}) => {
  const layoutContext = useLayoutContext()
  return (
    <CardContainer
      className={cx('card-bottom-margin w-full', className)}
      verticalEdge={
        layoutContext?.flushBreakpoint === FlushBreakpoint.SM
          ? 'auto-sm'
          : 'auto-md'
      }
      {...props}
    >
      {(title || subtitle) && (
        <SettingGroupHeader
          title={title}
          subtitle={subtitle}
          // this matches the top padding of CardContainer
          className='mb-4 md:mb-6 xxl:mb-8'
        />
      )}
      {children}
    </CardContainer>
  )
}
