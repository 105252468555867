import * as React from 'react'
import cx from 'classnames'
import { TestIdentifiable } from '@toasttab/buffet-shared-types'
import { IconProps } from '@toasttab/buffet-pui-icons'
import { ButtonProps, ButtonGroup } from '@toasttab/buffet-pui-buttons'

export interface EmptyStateProps extends TestIdentifiable {
  title?: string | null
  className?: string
  children: React.ReactNode
  icon: React.FunctionComponentElement<IconProps>
  button?: React.FunctionComponentElement<ButtonProps> | null
}

export const EmptyState = ({
  icon,
  className,
  children,
  testId = 'empty-state',
  title = null,
  button = null
}: EmptyStateProps) => {
  const normalizedIcon = React.cloneElement(icon, {
    size: 'md',
    className: 'text-color-secondary',
    testId: `${testId}-icon`,
    ...icon.props
  })

  const normalizedButton =
    button &&
    React.cloneElement(button, {
      variant: 'secondary',
      testId: `${testId}-button`,
      ...button.props
    })

  return (
    <div
      data-testid={testId}
      className={cx(
        'w-full flex flex-row justify-center text-center',
        className
      )}
    >
      <div className='flex flex-col items-center'>
        <div className='mb-4'>{normalizedIcon}</div>
        {title && (
          <div
            data-testid={`${testId}-title`}
            className='mb-2 type-default font-semibold'
          >
            {title}
          </div>
        )}
        <div className='type-default'>{children}</div>
        {normalizedButton && (
          <EmptyStateActions>{normalizedButton}</EmptyStateActions>
        )}
      </div>
    </div>
  )
}

export const EmptyStateActions: React.FC<React.PropsWithChildren<unknown>> = ({
  children
}) => <ButtonGroup className='mt-6 justify-center'>{children}</ButtonGroup>
