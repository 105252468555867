import { BanquetProps, useBanquetProps } from 'banquet-runtime-modules'
import { PropsWithChildren, createContext, useContext } from 'react'
interface PartnerInfo {
  guid: string
  name: string
  applicationName: string
  marketImageUrl: string
  iconUrl: string
}

// partner is passed in from depot-banquet-root
interface BanquetPartnerProps extends BanquetProps {
  // only exist if in depot domain
  partner?: PartnerInfo
}

function useBanquetWithPartnerProps() {
  return useBanquetProps() as BanquetPartnerProps
}

const BanquetPartnerContext = createContext<{
  partner: PartnerInfo | undefined
}>({
  partner: undefined
})

export function BanquetPartnerContextProvider({ children }: PropsWithChildren) {
  const { partner } = useBanquetWithPartnerProps()
  return (
    <BanquetPartnerContext.Provider value={{ partner }}>
      {children}
    </BanquetPartnerContext.Provider>
  )
}

export function useBanquetPartner() {
  return useContext(BanquetPartnerContext)
}

export function BanquetPartnerContextProviderForTesting({
  children,
  partner
}: PropsWithChildren<{ partner?: Partial<PartnerInfo> }>) {
  return (
    <BanquetPartnerContext.Provider
      value={{ partner } as { partner: PartnerInfo }}
    >
      {children}
    </BanquetPartnerContext.Provider>
  )
}
