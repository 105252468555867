import { Subscription } from '@local/manage-webhooks-shared'
import { TableHead } from '@local/table-shared'
import {
  Table as ReactTable,
  Row as ReactTableRow,
  flexRender
} from '@tanstack/react-table'
import { Panel } from '@toasttab/buffet-pui-config-templates'
import { Pagination, PaginationBag } from '@toasttab/buffet-pui-pagination'
import { Body, Cell, Row, Table } from '@toasttab/buffet-pui-table'
import cx from 'classnames'

import './SubscriptionTable.css'

export function SubscriptionTable({
  table,
  pagination
}: {
  table: ReactTable<Subscription>
  pagination: PaginationBag<ReactTableRow<Subscription>[]>
}) {
  return (
    <>
      <Panel>
        <div className='overflow-x-auto subscriptions-table-scrollbox'>
          <div
            style={{
              // maintain full table width
              width: '59.25rem'
            }}
          >
            <Table
              variant='plain'
              valign='middle'
              density='condensed-x'
              testId='subscriptions-table'
              className='text-left'
            >
              <TableHead table={table} />
              <Body>
                {pagination.currentPageData.map((row) => (
                  <Row key={row.id} testId='subscription-row'>
                    {row.getVisibleCells().map((cell) => {
                      return (
                        <Cell
                          key={cell.id}
                          className={cx(
                            cell.column.columnDef.meta?.className,
                            'py-1'
                          )}
                          testId={`value-${cell.column.id}`}
                        >
                          {flexRender(
                            cell.column.columnDef.cell,
                            cell.getContext()
                          )}
                        </Cell>
                      )
                    })}
                  </Row>
                ))}
              </Body>
            </Table>
          </div>
        </div>
      </Panel>
      <div className='flex flex-row justify-end'>
        <Pagination {...pagination} />
      </div>
    </>
  )
}
