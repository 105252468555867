import { gql } from '@apollo/client'

export const CREATE_SUBSCRIPTION = gql`
  mutation createSubscription($subscription: NewSubscription, $userGuid: ID) {
    createSubscription(subscription: $subscription, userGuid: $userGuid) {
      name
      guid
      uri
      paused
      stopped
      notificationEmail
      subscriberType
      subscriberGuid
      eventCategory {
        name
      }
    }
  }
`
