import { EventStatusFilterOptions, Filter } from '@local/manage-webhooks-shared'
import { Button } from '@toasttab/buffet-pui-buttons'
import { DividingLine } from '@toasttab/buffet-pui-config-templates'
import { TimePoint } from '@toasttab/buffet-pui-date-utilities'
import {
  DatePickerInputField,
  RadioGroupField,
  TimePickerField
} from '@toasttab/buffet-pui-forms'
import { FilterIcon } from '@toasttab/buffet-pui-icons'
import { Modal } from '@toasttab/buffet-pui-modal'
import { Form, FormikProvider, useFormik } from 'formik'
import { FunctionComponent, useMemo, useState } from 'react'

import {
  combineDateTimePoint,
  eventStatusFilterOptions,
  getTimePointFromDate,
  setTimeToZero
} from '../utils'

import { eventsFiltersFormSchema } from './eventsFiltersFormSchema'
import {
  WEBHOOKS_STORAGE_EVENTS_MAX_AGE_DAYS,
  checkFiltersEqual
} from './useEventsFilters'

export interface EventsFiltersProps {
  filters: Filter
  onFiltersChange(filters: Filter): void
}

type FilterFormValues = {
  selectedEventStatus: EventStatusFilterOptions
  startDate: Date | undefined
  startTimeField: TimePoint | null
  endDate: Date | undefined
  endTimeField: TimePoint | null
}

const emptyTimePoint: TimePoint = {
  isPm: false,
  hours: 12,
  minutes: 0
}

const emptyFilterValues: FilterFormValues = {
  selectedEventStatus: 'ALL',
  startDate: undefined,
  startTimeField: emptyTimePoint,
  endDate: undefined,
  endTimeField: emptyTimePoint
}

export const EventsFilters: FunctionComponent<EventsFiltersProps> = ({
  filters,
  onFiltersChange
}) => {
  const [showFilter, setShowFilter] = useState(false)

  const initialValues: FilterFormValues = useMemo(
    () => ({
      selectedEventStatus: filters.status === null ? 'ALL' : filters.status,
      startDate: filters.startDateTime
        ? setTimeToZero(filters.startDateTime)
        : undefined,
      startTimeField:
        getTimePointFromDate(filters.startDateTime) ?? emptyTimePoint,
      endDate: filters.endDateTime
        ? setTimeToZero(filters.endDateTime)
        : undefined,
      endTimeField: getTimePointFromDate(filters.endDateTime) ?? emptyTimePoint
    }),
    [filters.endDateTime, filters.startDateTime, filters.status]
  )

  const { fromDate, toDate } = useMemo(() => {
    const toDate = new Date()
    toDate.setHours(0, 0, 0, 0)

    const fromDate = new Date(toDate)
    fromDate.setDate(fromDate.getDate() - WEBHOOKS_STORAGE_EVENTS_MAX_AGE_DAYS)
    return { fromDate, toDate }
  }, [])

  const form = useFormik<FilterFormValues>({
    initialValues,
    validateOnMount: true,
    enableReinitialize: true,
    validationSchema: eventsFiltersFormSchema,
    onSubmit: (values) => {
      onFiltersChange({
        status:
          values.selectedEventStatus === 'ALL'
            ? null
            : values.selectedEventStatus,
        startDateTime: combineDateTimePoint(
          values.startDate,
          values.startTimeField
        ),
        endDateTime: combineDateTimePoint(values.endDate, values.endTimeField)
      })
      setShowFilter(false)
      setShowDirtyFormCloseWarning(false)
    }
  })

  const { values, submitForm, setValues, dirty, resetForm } = form

  // reset time input when reset input value
  const [dateInputKey, setDateInputKey] = useState(0)

  const formEmpty = useMemo(
    () => checkFiltersEqual(values, emptyFilterValues),
    [values]
  )

  const [showDirtyFormCloseWarning, setShowDirtyFormCloseWarning] =
    useState(false)

  return (
    <>
      <div className='mb-4'>
        <Button
          iconLeft={<FilterIcon accessibility='decorative' />}
          variant='link'
          onClick={() => setShowFilter(true)}
        >
          Filters
        </Button>
      </div>
      <Modal
        isOpen={showFilter}
        position='pin-right'
        onRequestClose={() => {
          if (dirty) {
            setShowDirtyFormCloseWarning(true)
            return
          }
          setShowFilter(false)
        }}
      >
        <Modal.Header headingId='filter-modal-heading'>Filters</Modal.Header>
        <Modal.Body>
          <FormikProvider value={form}>
            <Form data-testid='event-filters-form'>
              <RadioGroupField
                options={eventStatusFilterOptions}
                name='selectedEventStatus'
                label='Status'
                testId='events-filters-status-radio-group'
                itemsContainerClassName='mx-2'
              />
              <DividingLine />
              <div className='flex flex-col gap-2'>
                <div className='flex flex-row justify-between items-center'>
                  <div>
                    <div className='font-bold text-default'>
                      Time range start
                    </div>
                    <div className='type-caption'>
                      Time ranges are based on your current local timezone
                    </div>
                  </div>
                  <Button
                    variant='text-link'
                    size='sm'
                    onClick={() => {
                      setValues({
                        ...values,
                        startDate: undefined,
                        startTimeField: emptyTimePoint
                      })
                      setDateInputKey((current) => (current += 1))
                    }}
                  >
                    Clear
                  </Button>
                </div>

                <DatePickerInputField
                  name='startDate'
                  label='Date'
                  testId='start-date'
                  fromDate={fromDate}
                  toDate={toDate}
                />
                <TimePickerField
                  disabled={!values.startDate}
                  key={dateInputKey}
                  label='Time'
                  name='startTimeField'
                  testId='start-time'
                />
              </div>
              <DividingLine />
              <div className='flex flex-col gap-2'>
                <div className='flex flex-row justify-between items-center'>
                  <div>
                    <div className='font-bold text-default'>Time range end</div>
                    <div className='type-caption'>
                      Time ranges are based on your current local timezone
                    </div>
                  </div>
                  <Button
                    variant='text-link'
                    size='sm'
                    onClick={() => {
                      setValues({
                        ...values,
                        endDate: undefined,
                        endTimeField: emptyTimePoint
                      })
                      setDateInputKey((current) => (current += 1))
                    }}
                  >
                    Clear
                  </Button>
                </div>
                <DatePickerInputField
                  name='endDate'
                  label='Date'
                  testId='end-date'
                  fromDate={fromDate}
                  toDate={toDate}
                />
                <TimePickerField
                  disabled={!values.endDate}
                  key={dateInputKey}
                  label='Time'
                  name='endTimeField'
                  testId='end-time'
                />
              </div>
              <DividingLine />
            </Form>
          </FormikProvider>
        </Modal.Body>
        <Modal.Footer>
          <Button
            className='grow'
            disabled={formEmpty}
            onClick={() => {
              setValues(emptyFilterValues)
            }}
            variant='secondary'
          >
            Clear all
          </Button>
          <Button
            className='grow'
            testId='apply-filters-button'
            disabled={!form.dirty || !form.isValid}
            onClick={submitForm}
          >
            Apply filters
          </Button>
        </Modal.Footer>
      </Modal>
      <Modal
        isOpen={showDirtyFormCloseWarning}
        onRequestClose={() => setShowDirtyFormCloseWarning(false)}
      >
        <Modal.Header>You have unapplied filter changes</Modal.Header>
        <Modal.Body>
          Do you want to apply the selected filters before closing?
        </Modal.Body>
        <Modal.Footer>
          <Button
            variant='secondary'
            onClick={() => {
              resetForm()
              setShowFilter(false)
              setShowDirtyFormCloseWarning(false)
            }}
          >
            Close now
          </Button>
          <Button
            onClick={() => {
              submitForm()
            }}
          >
            Apply filters
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  )
}
