import {
  Labelled,
  WithLabel,
  WithAriaLabelledBy
} from '@toasttab/buffet-shared-types'

export const isWithLabel = (T: Labelled): T is WithLabel => {
  return !!(T as WithLabel)?.label
}

export const isWithAriaLabelledBy = (T: Labelled): T is WithAriaLabelledBy => {
  return !!(T as WithAriaLabelledBy)?.['aria-labelledby']
}
