import { ApolloError } from '@apollo/client'
import { Default404Page } from '@local/app-routes/Default404Page/Default404Page'
import { LoadingPageBody } from '@local/app-routes/LoadingPageBody/LoadingPageBody'
import {
  SavedSubscription,
  errorNotFoundOrForbidden
} from '@local/manage-webhooks-shared'
import { SubscriptionDetails } from '@local/subscription-details'
import { ErrorPage500 } from '@toasttab/buffet-pui-error-pages'

import { EventsList } from '../EventsList/EventsList'

export function EventsPageBody({
  subscription,
  error,
  loading
}: {
  subscription: SavedSubscription | undefined
  error: ApolloError | undefined
  loading: boolean
}) {
  if (loading) return <LoadingPageBody />

  if (error && errorNotFoundOrForbidden(error)) return <Default404Page />

  if (subscription)
    return (
      <>
        <SubscriptionDetails subscription={subscription} />
        <EventsList subscriptionGuid={subscription.guid} />
      </>
    )

  return <ErrorPage500 />
}
