import { useLazyQuery } from '@apollo/client'
import { useSnackBar } from '@toasttab/buffet-pui-snackbars'
import { useSentry } from 'banquet-runtime-modules'

import { RESYNC } from '../queries'

export const useSubscriptions = () => {
  const { showSuccessSnackBar, showErrorSnackBar } = useSnackBar()
  const { hub } = useSentry()

  const [resync, { loading: resyncLoading }] = useLazyQuery(RESYNC, {
    onCompleted: () => {
      showSuccessSnackBar('Webhook subscriptions successfully resynced', {
        showIcon: true,
        onDismiss: () => {},
        testId: 'resync-success'
      })
    },
    onError: (error) => {
      hub.captureException(error)
      showErrorSnackBar('There was an error resyncing: ' + error.message, {
        testId: 'resync-error'
      })
    }
  })

  return {
    resync,
    resyncLoading
  }
}
