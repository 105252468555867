import * as React from 'react'
import cx from 'classnames'
import { Skeleton } from '../Skeleton'
import { TestIdentifiable } from '@toasttab/buffet-shared-types'

export interface TextInputSkeletonProps extends TestIdentifiable {
  noLabel?: boolean
  noHelperText?: boolean
  /** Classes applied to the Skeleton component */
  className?: string
}

export const TextInputSkeleton = ({
  testId,
  noLabel,
  noHelperText,
  className
}: TextInputSkeletonProps) => {
  return (
    <div className={cx(className, 'space-y-2')} data-testid={testId}>
      {!noLabel && (
        <div>
          <div className='h-1' />
          <Skeleton className='w-20 h-4' />
        </div>
      )}
      <Skeleton className='w-full h-12 md:h-10' />
      {!noHelperText && (
        <div style={{ height: '11px' }}>
          <Skeleton className='w-16 h-4' />
        </div>
      )}
    </div>
  )
}
