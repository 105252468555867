import * as React from 'react'
import cx from 'classnames'
import { IconWrapper, IconProps, sizes } from '../../IconWrapper'
export const AutorenewIcon = React.forwardRef(function AutorenewIcon(
  props: IconProps,
  ref?: React.ForwardedRef<HTMLElement>
) {
  return (
    <IconWrapper ref={ref} {...props}>
      <>
        {
          <svg
            width={24}
            height={24}
            viewBox='0 0 24 24'
            fill='none'
            className={cx(
              'inline-block align-middle',
              sizes[props.size || 'sm'].className
            )}
          >
            <path
              d='M9.4 16.95l-3.9 1 1.1 3.8m8-14.6l3.8-1.1-1-3.8m.6 3.6c-3-2.8-7.5-3.2-10.9-.9-3.4 2.3-4.7 6.7-3.1 10.5m2 2.9c2.9 2.8 7.5 3.2 10.9.9 3.4-2.3 4.7-6.7 3.1-10.5'
              stroke='currentColor'
              strokeWidth={sizes[props.size || 'sm'].strokeWidth}
              strokeMiterlimit={10}
              strokeLinecap='round'
              strokeLinejoin='round'
            />
          </svg>
        }
      </>
    </IconWrapper>
  )
})
