import * as React from 'react'
import { SelectItem } from '../types'
import { isSelectOption } from '../utils'
import { itemToStringFn, ItemToStringFn } from '../utils'

export type UseSelectSearchProps<TValue, TItem extends SelectItem<TValue>> = {
  options: TItem[]
  itemToString?: ItemToStringFn<TValue, TItem>
  /**
   * @deprecated - use filterOptions and onSearchChange --
   * a function that determines how each item (option) will be rendered
   */
  onSearch?: (v: string) => void
  onSearchChange?: (v: string) => void
  /** allow for the string match to be anywhere in the string (deafult is that the matching string starts with the search term) */
  searchAnyPartOfString?: boolean
  filterOptions?: (options: Array<TItem>, searchTerm?: string) => Array<TItem>
}

export const useSelectSearch = <TValue, TItem extends SelectItem<TValue>>({
  onSearch, // eslint-disable-line
  options,
  itemToString = itemToStringFn,
  searchAnyPartOfString = false,
  filterOptions: externalFilterOptions,
  onSearchChange
}: UseSelectSearchProps<TValue, TItem>) => {
  const [searchTerm, setSearchTerm] = React.useState<string | undefined>(
    !onSearch ? '' : undefined
  )

  const handleSearch: (v: string) => void = React.useCallback(
    (v) => {
      if (onSearch) {
        onSearch(v)
      } else {
        setSearchTerm(v)
        if (onSearchChange) {
          onSearchChange(v)
        }
      }
    },
    [onSearch, onSearchChange]
  )

  const defaultFilterOptions = (options: Array<TItem>, searchTerm?: string) =>
    !onSearch && searchTerm
      ? options.filter((item) => {
          const searchableStrings: string[] = [itemToString(item)]
          if (isSelectOption(item) && item.subLabel) {
            searchableStrings.push(item.subLabel.toString())
          }
          return searchableStrings.some((s) => {
            if (searchAnyPartOfString) {
              return s.toLowerCase().includes(searchTerm.toLowerCase())
            }
            return s.toLowerCase().startsWith(searchTerm.toLowerCase())
          })
        })
      : options

  const filterOptions = externalFilterOptions || defaultFilterOptions

  const filteredOptions = filterOptions(options, searchTerm)

  const hasNoResults =
    (filteredOptions.length === 0 &&
      options.length > 0 &&
      !!searchTerm &&
      !onSearch) ||
    (!!onSearch && !!searchTerm && options.length === 0)

  const clearSearchTerm = React.useCallback(() => {
    setSearchTerm(!onSearch ? '' : undefined)
    if (onSearchChange) {
      onSearchChange('')
    }
  }, [onSearch, onSearchChange])

  const onSearchInput = React.useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      const value = event.target.value
      handleSearch(value)
    },
    [handleSearch]
  )

  return {
    searchTerm,
    handleSearch,
    filteredOptions,
    hasNoResults,
    clearSearchTerm,
    onSearchInput
  }
}
