import { useQuery } from '@apollo/client'
import { useSentry } from 'banquet-runtime-modules'

import { errorNotFoundOrForbidden } from '../../errorNotFoundOrForbidden'
import { SavedSubscription } from '../../types'

import { GET_SUBSCRIPTION } from './GET_SUBSCRIPTION'

export function useToastAdminGetSubscription(guid?: string) {
  const { hub } = useSentry()

  const { data, error, loading } = useQuery<{
    getSubscription: SavedSubscription
  }>(GET_SUBSCRIPTION, {
    skip: !guid,
    // fix when user is on subscriptions page, click on 'view events'
    // then click 'edit' and 'delete'
    // user will be redirect back to subscriptions page
    // however user can click go back and still see the edit form
    fetchPolicy: 'no-cache',
    variables: { guid },
    onError: (newError) => {
      if (!errorNotFoundOrForbidden(newError)) {
        // only send error not 404 or 403 to sentry
        hub.captureException(newError)
      }
    }
  })

  return { subscription: data?.getSubscription, error, loading }
}
