import * as React from 'react'

import { TooltipContent } from './TooltipContent'
import { TooltipTrigger } from './TooltipTrigger'
import { useTooltip } from './useTooltip'
import { TooltipContext } from './useTooltipContext'

import type { PlacementWithAuto } from '@toasttab/buffet-pui-floating-ui-base'

export type TooltipVariant = 'dark' | 'arrowLight'
export type TooltipAccessibility = 'label' | 'description' | 'decorative'

export interface TooltipProps {
  /** Tooltip content */
  children: React.ReactNode
  /** Unique id applied to the tooltip to describe the given content for accessibility purposes. */
  id?: string
  /** On mobile, how long the tooltip will be shown before disappearing, in milliseconds. Defaults to `5000`. */
  mobileTimeout?: number
  /** FloatingUI placement options including auto */
  placement?: PlacementWithAuto
  /** Theme used for the tooltip */
  variant?: TooltipVariant
  /** Whether or not to make the reference element (trigger) described by the contents, labelled by the contents, or neither */
  accessibility?: TooltipAccessibility

  // original API
  /**
   * Element of which we want to render the tooltip inside
   * @deprecated Please use the default portal behavior to ensure the tooltip contents are correctly css scoped
   */
  portalContainer?: HTMLElement | null
  /**
   * Render function to render tooltip content
   */
  content?: () => React.ReactNode
  /**
   * When `content` prop is specified, this is the id that is applied to the tooltip content
   */
  testId?: string | number
  /** When `content` props is specified, this is applied to the reference element (trigger) of the tooltip */
  className?: string
}

const variants: { [key in TooltipVariant]: TooltipVariant } = {
  dark: 'dark',
  arrowLight: 'arrowLight'
}
export const Tooltip = ({
  className,
  children,
  id,
  accessibility = 'description',
  mobileTimeout = 10000,
  placement = 'auto',
  variant = 'dark',

  // original API
  content,
  testId,
  portalContainer // eslint-disable-line
}: TooltipProps) => {
  const tooltip = useTooltip({
    id,
    accessibility,
    mobileTimeout,
    placement,
    variant
  })

  if (content) {
    // Presence of content prop indicates use of the original API
    return (
      <TooltipContext.Provider value={tooltip}>
        {/*
         * In this original form of the Tooltip API,
         * we expected people to provide tab index on one of the children of the trigger
         */}
        <TooltipTrigger className={className} tabIndex={undefined}>
          {children}
        </TooltipTrigger>
        <TooltipContent portalContainer={portalContainer} testId={testId}>
          {content()}
        </TooltipContent>
      </TooltipContext.Provider>
    )
  }
  return (
    // Tooltips that omit content prop can use new API
    <TooltipContext.Provider value={tooltip}>
      {children}
    </TooltipContext.Provider>
  )
}

Tooltip.Variant = Object.freeze(variants)

Tooltip.Content = TooltipContent
Tooltip.Trigger = TooltipTrigger
