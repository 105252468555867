import { PropsWithChildren, createContext, useContext } from 'react'
import { ApolloError } from '@apollo/client'

import { EventCategory, Partner, Subscription } from '../types'
import { useToastAdminWebhooks } from '../graphql-toast-admin'
import { useDepotWebhooks } from '../graphql-depot'

type WebhooksContextValue = {
  loading: boolean
  error: ApolloError | undefined
  subscriptions: Subscription[] | undefined
  partners: Partner[] | undefined
  eventCategories: EventCategory[] | undefined
}

const WebhooksContext = createContext<WebhooksContextValue>(
  {} as WebhooksContextValue
)

export function ToastAdminWebhooksContextProvider({
  children
}: PropsWithChildren) {
  const value = useToastAdminWebhooks()
  return (
    <WebhooksContext.Provider value={value}>
      {children}
    </WebhooksContext.Provider>
  )
}

export function DepotWebhooksContextProvider({ children }: PropsWithChildren) {
  const value = useDepotWebhooks()
  return (
    <WebhooksContext.Provider value={value}>
      {children}
    </WebhooksContext.Provider>
  )
}

export function useWebhooksContext() {
  return useContext(WebhooksContext)
}

export function WebhooksContextForTesting({
  children,
  value
}: PropsWithChildren<{ value: Partial<WebhooksContextValue> }>) {
  return (
    <WebhooksContext.Provider value={value as WebhooksContextValue}>
      {children}
    </WebhooksContext.Provider>
  )
}
