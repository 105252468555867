import { FunctionComponent } from 'react'
import { useSubscriptions } from '@local/manage-webhooks-shared'
import { Button, ButtonGroup } from '@toasttab/buffet-pui-buttons'
import { AddIcon, AutorenewIcon, RestartIcon } from '@toasttab/buffet-pui-icons'
import { Link } from 'react-router-dom'

export interface SubscriptionsActionButtonsProps {
  className?: string
}

export const SubscriptionsActionButtons: FunctionComponent<
  SubscriptionsActionButtonsProps
> = ({ className }) => {
  const { resync, resyncLoading } = useSubscriptions()
  return (
    <ButtonGroup
      className={className + ' justify-end'}
      testId='subscriptions-action-buttons'
    >
      <Button
        iconLeft={
          resyncLoading ? (
            <AutorenewIcon className='animate-spin' />
          ) : (
            <RestartIcon />
          )
        }
        variant='secondary'
        onClick={() => resync()}
        testId='resync-button'
        disabled={resyncLoading}
      >
        Resync
      </Button>
      <Link to='/create' className='border-none hover:no-underline'>
        <Button iconLeft={<AddIcon />} variant='primary'>
          Add webhook
        </Button>
      </Link>
    </ButtonGroup>
  )
}
