import * as React from 'react'
import cx from 'classnames'

import { TestIdentifiable } from '@toasttab/buffet-shared-types'
import { useUniqueId } from '@toasttab/buffet-utils'

export interface DividingLineProps extends TestIdentifiable {
  /**
   * Additional classes to apply to the dividing line
   */
  className?: string
  /**
   * Determines if the y-axis margin should be applied.
   */
  noMargin?: boolean
}

export const DividingLine = ({
  testId,
  className,
  noMargin
}: DividingLineProps) => {
  testId = useUniqueId(testId, 'dividing-line-')
  return (
    <hr
      className={cx(
        'bg-darken-12',
        'h-px border-0 p-0 mx-0',
        { 'my-4 md:my-6': !noMargin },
        className
      )}
      data-testid={testId}
    />
  )
}
