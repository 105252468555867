import * as React from 'react'
import cx from 'classnames'
import { generateId } from '../utils'
import {
  CheckCircleSelectedIcon,
  InfoIcon,
  WarningOutlineIcon,
  CloseIcon
} from '@toasttab/buffet-pui-icons'
import { t, loadStrings } from '../defaultStrings'

const getVariantClasses = (variant: AlertVariant) => {
  switch (variant) {
    case 'warning':
      return 'bg-warning-0'
    case 'error':
      return 'bg-error-0'
    case 'success':
      return 'bg-success-0'
    case 'neutral':
      return 'bg-gray-0'
    case 'info':
    default:
      return 'bg-info-0'
  }
}

const getOutlineClasses = (variant: AlertVariant, outlined: boolean) => {
  if (!outlined) {
    return ''
  }

  switch (variant) {
    case 'warning':
      return 'border border-warning-50'
    case 'error':
      return 'border border-error-25'
    case 'success':
      return 'border border-success-25'
    case 'neutral':
      return 'border border-gray-50'
    case 'info':
    default:
      return 'border border-info-25'
  }
}

const Icon = ({ variant }: { variant: AlertVariant }) => {
  switch (variant) {
    case 'success':
      return (
        <CheckCircleSelectedIcon
          testId='alert-done-icon'
          aria-label={t('success')}
          className='text-success'
        />
      )
    case 'warning':
      return (
        <WarningOutlineIcon
          testId='alert-warning-icon'
          aria-label={t('error')}
          className='text-warning'
        />
      )
    case 'error':
      return (
        <WarningOutlineIcon
          testId='alert-warning-icon'
          accessibility='decorative'
          className='text-error'
        />
      )
    case 'neutral':
      return (
        <InfoIcon
          testId='alert-neutral-icon'
          accessibility='decorative'
          className='text-secondary'
        />
      )
    case 'info':
    default:
      return (
        <InfoIcon
          testId='alert-info-icon'
          accessibility='decorative'
          className='text-info-75'
        />
      )
  }
}

export type AlertVariant = 'info' | 'neutral' | 'warning' | 'error' | 'success'

export interface AlertProps extends React.HTMLAttributes<HTMLDivElement> {
  variant?: AlertVariant
  testId?: string | number
  showIcon?: boolean
  onDismiss?: React.MouseEventHandler<HTMLButtonElement>
  outlined?: boolean
  title?: string
}

export const Alert = React.forwardRef<HTMLDivElement, AlertProps>(
  (
    {
      variant = 'info',
      testId = generateId(),
      showIcon = true,
      outlined = false,
      title,
      ...rest
    },
    ref
  ) => {
    loadStrings()
    const { className, onDismiss, ...props } = rest

    const hasOnDismiss = onDismiss !== undefined

    return (
      <div
        ref={ref}
        className={cx(
          'inline-block',
          'rounded space-x-2',
          'px-4 py-3',
          'text-default',
          getVariantClasses(variant),
          getOutlineClasses(variant, outlined),
          className
        )}
        data-testid={testId}
        {...props}
      >
        <div className={cx('flex items-start', outlined && '-m-px')}>
          {showIcon ? (
            <div className='pr-2 leading-none'>
              <Icon variant={variant} />
            </div>
          ) : null}
          <div className='flex-grow type-default'>
            {title && <div className='font-bold'>{title}</div>}
            {props.children}
          </div>
          {hasOnDismiss ? (
            <button
              title={t('dismiss')}
              onClick={onDismiss}
              type='button'
              className='flex items-center p-1 -my-3 -mr-3 outline-none group'
            >
              <CloseIcon
                className='p-2 rounded-full group-hover:bg-darken-4 group-focus-visible:shadow-focus-inset'
                accessibility='decorative'
              />
            </button>
          ) : null}
        </div>
      </div>
    )
  }
)

/**
 * A note about this.
 *
 * This should only be used in javascript. The preferred method of accessing the variants is with the string key in typescript.
 *
 * If you use an incorrect key, the typescript compiler will not compile, so there is no need to use the "safe" object.
 */
//@ts-ignore
Alert.Variant = Object.freeze({
  neutral: 'neutral',
  info: 'info',
  warning: 'warning',
  error: 'error',
  success: 'success'
})
