import * as React from 'react'
import cx from 'classnames'
import { useUniqueId } from '@toasttab/buffet-utils'

import { SharedTextToggleSwitchProps, SharedSwitchProps } from '../types'
import { Legend } from '@toasttab/buffet-pui-text-base'

const sizes = {
  xs: 'xs',
  sm: 'sm',
  base: 'base', // deprecated in favor of 'lg'
  lg: 'lg',
  auto: 'auto'
}

export interface TextToggleSwitchComponent
  extends React.ForwardRefExoticComponent<
    TextToggleSwitchProps & React.RefAttributes<HTMLInputElement>
  > {
  Size?: typeof sizes
}

export interface TextToggleSwitchProps
  extends SharedTextToggleSwitchProps,
    SharedSwitchProps,
    Omit<
      React.InputHTMLAttributes<HTMLInputElement>,
      'size' | 'value' | 'defaultValue' | 'children'
    > {
  /** Describes the purpose of the switch, displayed above as a label for the field */
  label: string
  /** Allows for the label to only be available for screen readers */
  hideLabel?: boolean
  /** Whether the switch is in the active state (the right side) */
  isActive?: boolean
  /** Optional prefix for the aria-label - e.g. "Takeout enabled"
   * @deprecated use label instead, which can be hidden with hideLabel
   */
  ariaLabelPrefix?: string
  /** Labels of the switch in the form of [ 'Inactive label', 'Active label' ]
   * @deprecated use options instead
   */
  labels?: Array<string>
  /** Options of the switch in the form of [ 'Inactive label', 'Active label' ]  */
  options?: Array<string>
  /** Components such as Icons can optionally be used to label the switch in the form of [<Component1>, <Component2>]
   * @deprecated use optionComponents instead
   */
  labelComponents?: Array<React.ReactNode>
  optionsComponents?: Array<React.ReactNode>
}

export const TextToggleSwitch: TextToggleSwitchComponent = React.forwardRef<
  HTMLInputElement,
  TextToggleSwitchProps
>(
  (
    {
      label,
      hideLabel,
      isActive = false,
      disabled = false,
      containerClassName,
      // eslint-disable-next-line deprecation/deprecation
      ariaLabelPrefix,
      // eslint-disable-next-line deprecation/deprecation
      labels,
      options,
      // eslint-disable-next-line deprecation/deprecation
      labelComponents,
      optionsComponents,
      onChange,
      testId,
      uppercase = false,
      size = 'auto',
      'aria-label': ariaLabel,
      className,
      style,
      id,
      ...props
    },
    ref
  ) => {
    testId = useUniqueId(testId, 'text-toggle-switch-')
    const inputId = useUniqueId(id, 'text-toggle-switch-')

    const labelBaseStyle = cx(
      'flex justify-center items-center h-full w-1/2 select-none text-center px-2',
      'type-default m-0'
    )

    const displayOptions = options || labels || ['Off', 'On']
    const displayComponents = optionsComponents || labelComponents || []

    const displayElements =
      displayComponents?.length === 2 ? displayComponents : displayOptions

    const secondRef = React.useRef<HTMLInputElement>(null)

    return (
      <fieldset className={containerClassName}>
        <Legend className={cx('mb-1', { 'sr-only': hideLabel })}>
          {label}
        </Legend>
        <div
          data-testid={testId}
          className={cx(
            'group relative flex justify-around items-center rounded-input bg-darken-8',
            'text-secondary w-24',
            className,
            {
              'h-12': size === 'base' || size === 'lg',
              'h-10': size === 'sm',
              'h-8': size === 'xs',
              'h-12 md:h-10': size === 'auto',
              'cursor-default': disabled,
              'cursor-pointer focus-within:shadow-focus hover:text-primary-100':
                !disabled
            }
          )}
        >
          {displayOptions.map((option, i) => {
            const isOptionChecked =
              (isActive && option === displayOptions[1]) ||
              (!isActive && option === displayOptions[0])
            return (
              <label
                key={option}
                className={cx('flex-1 text-secondary', labelBaseStyle, {
                  uppercase,
                  'cursor-pointer hover:text-link':
                    !disabled && !isOptionChecked
                })}
              >
                <span
                  className={cx(
                    'min-w-0 truncate',
                    disabled && 'text-disabled'
                  )}
                >
                  {displayElements[i]}
                </span>
                <input
                  ref={i === 1 ? secondRef : ref}
                  id={`${inputId}-${option}`}
                  className={cx('absolute top-0 w-full h-full m-0 sr-only', {
                    'cursor-pointer': !disabled,
                    'cursor-default': disabled
                  })}
                  type='radio'
                  checked={isOptionChecked}
                  aria-checked={isOptionChecked}
                  onChange={(e) => !disabled && onChange && onChange(e)}
                  disabled={disabled}
                  data-testid={`${testId}-input-${option}`}
                  {...props}
                />
              </label>
            )
          })}
          <span
            className={cx(
              'absolute font-semibold rounded-input bg-white shadow',
              'pointer-events-none transition-all duration-300',
              labelBaseStyle,
              {
                'text-primary-75 group-hover:text-primary-100': !disabled,
                'text-disabled': disabled,
                uppercase
              }
            )}
            style={{
              left: isActive ? '50%' : '0%'
            }}
            aria-hidden='true'
          >
            <span
              className={cx('min-w-0 truncate', disabled && 'text-disabled')}
            >
              {isActive ? displayElements[1] : displayElements[0]}
            </span>
          </span>
        </div>
      </fieldset>
    )
  }
)

TextToggleSwitch.Size = Object.freeze(sizes)
