import { useBanquetProps } from 'banquet-runtime-modules'
import { useNavigate, useParams } from 'react-router-dom'
import { Button } from '@toasttab/buffet-pui-buttons'
import { useDeleteSubscription } from '@local/manage-webhooks-shared'
import { useSnackBar } from '@toasttab/buffet-pui-snackbars'
import { useState } from 'react'

import { ConfirmDeleteModal } from '../ConfirmDeleteModal/ConfirmDeleteModal'

export function DeleteSubscriptionButton() {
  const { guid } = useParams()
  const { auth } = useBanquetProps()
  const userGuid = auth?.userInfo?.guid

  const navigate = useNavigate()

  const [showDeleteModal, setShowDeleteModal] = useState(false)

  const { showErrorSnackBar, showSuccessSnackBar } = useSnackBar()

  const { deleteSubscription, deleteLoading } = useDeleteSubscription({
    onDelete: () => {
      showSuccessSnackBar('Webhook deleted!', {
        testId: 'delete-success-snackbar'
      })
      navigate('/subscriptions')
    },
    onError: () => {
      showErrorSnackBar('There was an error deleting the subscription', {
        testId: 'delete-error-snackbar'
      })
      setShowDeleteModal(false)
    }
  })

  if (!guid || !userGuid) return null
  return (
    <>
      <Button
        variant='destructive'
        testId='delete-subscription-button'
        onClick={() => setShowDeleteModal(true)}
      >
        Delete webhook
      </Button>
      <ConfirmDeleteModal
        isOpen={showDeleteModal}
        onClose={() => setShowDeleteModal(false)}
        onDelete={() =>
          deleteSubscription({
            variables: {
              guid,
              deletedBy: userGuid
            }
          })
        }
        isDeleting={deleteLoading}
      />
    </>
  )
}
