import { FunctionComponent, ReactNode } from 'react'
import { Event, EventStatusBadge } from '@local/manage-webhooks-shared'
import { Formats, format } from '@toasttab/buffet-pui-date-utilities'

export interface EventFieldsContentProps {
  event: Event
}
export const EventFieldsContent: FunctionComponent<EventFieldsContentProps> = ({
  event
}) => {
  return (
    <>
      <LabelledContent label='Webhook GUID'>
        {event.webhookGuid}
      </LabelledContent>
      <LabelledContent label='Status'>
        <EventStatusBadge status={event.status} />
      </LabelledContent>
      <LabelledContent label='Webhook URL'>
        {event.subscriptionUri}
      </LabelledContent>
      <LabelledContent label='Timestamp'>
        {format(
          new Date(event.timestamp),
          Formats.dateTime.medium_with_seconds,
          'en-US'
        )}
      </LabelledContent>
      <LabelledContent label='Subscription GUID'>
        {event.subscriptionGuid}
      </LabelledContent>
      <LabelledContent label='Attempts'>{event.attempts}</LabelledContent>
      <LabelledContent label='Event category'>
        {event.eventCategory}
      </LabelledContent>
      <LabelledContent label='Response code'>
        {event.responseCode}
      </LabelledContent>
      <LabelledContent label='Event type'>{event.eventType}</LabelledContent>
      <LabelledContent label='Route key'>{event.routeKey}</LabelledContent>
      <LabelledContent label='Event GUID'>{event.eventGuid}</LabelledContent>
      <LabelledContent label='Signature'>{event.signature}</LabelledContent>
    </>
  )
}

export interface LabelledContentProps {
  label: string
  className?: string
  children: ReactNode
}

export const LabelledContent: FunctionComponent<LabelledContentProps> = ({
  label,
  className,
  children
}) => (
  <div className={className}>
    <div className='type-default font-bold'>{label}</div>
    <div className='type-subhead break-all'>{children}</div>
  </div>
)

interface JSONContentProps {
  children: ReactNode
}
export const JSONContent: FunctionComponent<JSONContentProps> = ({
  children
}) => (
  <div className='bg-gray-25 p-8 type-subhead overflow-x-auto'>{children}</div>
)
