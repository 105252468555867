import { ApolloProvider } from '@apollo/client'
import { AppRoutes } from '@local/app-routes'
import { SnackBarProvider } from '@toasttab/buffet-pui-snackbars'
import { DomainContextProvider } from '@local/current-domain'
import { AdminPermissionContextProvider } from '@local/manage-webhooks-shared'
import { BanquetPartnerContextProvider } from '@local/banquet-partner-props'

import { getApolloClient } from './apolloClient'

const apolloClient = getApolloClient()

export const App = () => {
  return (
    <BanquetPartnerContextProvider>
      <DomainContextProvider>
        <AdminPermissionContextProvider>
          <ApolloProvider client={apolloClient}>
            <SnackBarProvider>
              <div id='banquetPortalsContainer' />
              <AppRoutes />
            </SnackBarProvider>
          </ApolloProvider>
        </AdminPermissionContextProvider>
      </DomainContextProvider>
    </BanquetPartnerContextProvider>
  )
}
