import * as React from 'react'
import cx from 'classnames'
import { IconWrapper, IconProps, sizes } from '../../IconWrapper'
export const WarningOutlineIcon = React.forwardRef(function WarningOutlineIcon(
  props: IconProps,
  ref?: React.ForwardedRef<HTMLElement>
) {
  return (
    <IconWrapper ref={ref} {...props}>
      <>
        {
          <svg
            width={24}
            height={24}
            viewBox='0 0 24 24'
            fill='none'
            className={cx(
              'inline-block align-middle',
              sizes[props.size || 'sm'].className
            )}
          >
            <path
              d='M10.027 4.623a2.257 2.257 0 013.904 0l4.003 6.39 3.703 5.992c1.001 1.498-.2 3.495-2.002 3.495H4.322c-1.801 0-2.902-1.997-2.001-3.495l3.703-5.991 4.003-6.39zM11.929 8.518v4.493'
              stroke='currentColor'
              strokeWidth={sizes[props.size || 'sm'].strokeWidth}
              strokeMiterlimit={10}
              strokeLinecap='round'
              strokeLinejoin='round'
            />
            <path
              d='M11.929 17.567a1 1 0 10-1.001-.999 1 1 0 001 .999z'
              fill='currentColor'
            />
          </svg>
        }
      </>
    </IconWrapper>
  )
})
