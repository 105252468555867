export type BuffetInputStyle = 'default' | 'contained'

declare global {
  var IS_STORYBOOK: boolean
}

export const getInputStyle = (): BuffetInputStyle | undefined => {
  // For storybook only we currently allow an override of the input style via local storage
  if (window?.IS_STORYBOOK) {
    const overriddenInputStyle = localStorage.getItem('buffetInputStyle')
    if (overriddenInputStyle) {
      return overriddenInputStyle as BuffetInputStyle
    }
  }
  try {
    // @ts-ignore so that we don't have to include node types
    return process.env.BUFFET_INPUT_STYLE as BuffetInputStyle
  } catch {
    return undefined
  }
}

export const getBuffetConfig = () => ({
  inputStyle: getInputStyle()
})
