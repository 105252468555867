import * as React from 'react'
import { useUniqueId } from '@toasttab/buffet-utils'
import { TestIdentifiable } from '@toasttab/buffet-shared-types'
import {
  HelperText,
  HelperTextProps,
  LabeledHelperTextProps,
  Legend
} from '@toasttab/buffet-pui-text-base'

export interface CardSelectorItem {
  contents: React.ReactNode
  value: string | number
  disabled?: boolean
  checked?: boolean
  key?: string
}

export interface SelectionGroupProps
  extends TestIdentifiable,
    React.FieldsetHTMLAttributes<HTMLFieldSetElement>,
    HelperTextProps,
    LabeledHelperTextProps {
  /** Label to apply to the field */
  label?: React.ReactNode
  /** Sublabel to apply to the field */
  subLabel?: React.ReactNode
  /** CSS classes to apply to the outer container */
  className?: string
  /** CSS classes to apply to the inner/items container (e.g. if you need to adjust the space between the label and the items). Defaults to 'my-2' */
  itemsContainerClassName?: string
  /** Is this group used for multiple selection (e.g. checkboxes)? */
  multiple?: boolean
  /** The contents of the container */
  children: React.ReactNode
}

export const inlineSelectionItemsClasses = 'flex flex-row'

export const SelectionGroup = ({
  label,
  subLabel,
  testId,
  helperIconButton,
  required,
  errorText,
  helperText,
  invalid,
  preserveHelpSpace,
  disabled,
  multiple,
  className,
  itemsContainerClassName = 'my-2 flex flex-col gap-1',
  children,
  ...restProps
}: SelectionGroupProps) => {
  const derivedTestId = useUniqueId(testId, 'selection-group-')
  return (
    <fieldset
      {...restProps}
      className={className}
      role={!multiple ? 'radiogroup' : undefined}
      data-testid={derivedTestId}
      aria-required={!multiple && required ? 'true' : undefined} // force the attribute to not render at all if not required
    >
      {label && (
        <Legend
          disabled={disabled}
          helperIconButton={helperIconButton}
          required={required}
          subLabel={subLabel}
          data-testid={`${derivedTestId}-label`}
        >
          {label}
        </Legend>
      )}
      <div className={itemsContainerClassName}>{children}</div>
      {(errorText || helperText) && (
        <HelperText
          testId={`${testId}-helper-text`}
          disabled={disabled}
          invalid={invalid}
          errorText={errorText}
          helperText={helperText}
          preserveHelpSpace={preserveHelpSpace}
        />
      )}
    </fieldset>
  )
}
