import { Colors } from '@toasttab/buffet-pui-badge'

import { EventStatus, EventStatusBuffetBadgeProps } from '../../types'

export const getStatusBadgeFromEventStatus = (eventStatus: EventStatus) => {
  let eventStatusBadge: EventStatusBuffetBadgeProps = {}

  switch (eventStatus) {
    case EventStatus.SENT_FAIL:
      eventStatusBadge = { message: 'FAILURE', color: Colors.error }
      break
    case EventStatus.SENT_SUCCESS:
      eventStatusBadge = { message: 'SUCCESS', color: Colors.success }
      break
    case EventStatus.NOT_SENT:
      eventStatusBadge = {
        message: 'NOT SENT',
        color: Colors.gray
      }
      break
    default:
      eventStatusBadge = { message: 'PENDING', color: Colors.primary }
  }
  return eventStatusBadge
}
