/** check if error is ApolloError and code is 404 or 403 */
export function errorNotFoundOrForbidden(error: Error): boolean {
  if (
    error.name === 'ApolloError' &&
    (error.message === '404: Not Found' || error.message === '403: Forbidden')
  )
    return true

  return false
}
