import { PropsWithChildren, createContext, useContext } from 'react'

import { useManagePartnersPermissions } from './useManagePartnersPermissions'

export type AdminPermissionContextValue = {
  hasEditPermissions: boolean
}
export const AdminPermissionContext =
  createContext<AdminPermissionContextValue>({ hasEditPermissions: false })

export function AdminPermissionContextProvider({
  children
}: PropsWithChildren) {
  const { hasEditPermissions } = useManagePartnersPermissions()
  return (
    <AdminPermissionContext.Provider value={{ hasEditPermissions }}>
      {children}
    </AdminPermissionContext.Provider>
  )
}

export function useAdminPermissionContext() {
  return useContext(AdminPermissionContext)
}
