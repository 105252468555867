import * as React from 'react'
import {
  FloatingFocusManager,
  FloatingPortal,
  FloatingContext,
  FloatingNode,
  useFloatingNodeId
} from '@floating-ui/react'

interface PortalManagementContainerProps {
  portalContainerEl?: HTMLElement | null
  children: React.ReactElement
  zIndexClassName?: 'z-30' | 'z-40'
}

export interface PortalManagementProps extends PortalManagementContainerProps {
  /**
   * Floating UI's context
   */
  context: FloatingContext
  /**
   * Set this to true if the portalled content _behaves_ as a modal - particularly with respect to focus trap.
   * Do you want the user to only interact with the floating content until they close it? If so this should be true.
   */
  isModal?: boolean
  /**
   * Ensures the floating element closes when keyboard nav is used to remove focus from inside the floating element.
   * (defaults to true)
   * See https://floating-ui.com/docs/FloatingFocusManager#closeonfocusout
   */
  closeOnFocusOut?: boolean
  /**
   * Disable the default initial focus of modal behavior (see https://floating-ui.com/docs/FloatingFocusManager#initialfocus)
   * e.g. For select components that have additional actions, we want the `useListNavigation` focus control to take precedence
   * over the default focus control for modals (we treat an open select with additional actions as a modal)
   */
  disableInitialFocus?: boolean
  /**
   * Set this to true if you're not using the focus trap at all (e.g. with snackbars)
   */
  disableFocusManager?: boolean
}

const createPortalElForCssScope = (cssScope: string) => {
  const portalContainerId = `portal-container-${cssScope}`
  let portalContainerEl = document.getElementById(portalContainerId)
  if (!portalContainerEl) {
    portalContainerEl = document.createElement('div')
    portalContainerEl.id = portalContainerId
    portalContainerEl.setAttribute(cssScope, 'true')
    portalContainerEl.setAttribute(`data-testid`, `portal-${cssScope}`)
    document.body.appendChild(portalContainerEl)
  }
  return portalContainerEl
}

const getCssScope = () => {
  try {
    // @ts-ignore so that we don't have to include node types
    return process.env.CSS_SCOPE
  } catch {
    // IMPORTANT: We're using a try/catch here in case (for some reason) the SPA using this component
    // does not expose process.env variables in the webpack runtime.
    // You might be inclined (like me) to use a safe operator on process.env.CSS_SCOPE instead of
    // resorting to the whole old-school try catch thing. I mean... Why wouldn't you right?
    // Well... Here's why.
    // If you use a safe operator, the bundled code will look something like this:
    // `var cssScope = (_a = process === null || process === void 0 ? void 0 : process.env) === null || _a === void 0 ? void 0 : _a.CSS_SCOPE;`
    // Looks fine right? Clever even.
    // But... when that code actually runs in a SPA you'll get `process not defined` errors (because
    // presumably there is some safety mechanism to stop us using the whole process object in the runtime).
    // If you're like me these confusing errors might cause you to question some of your career/life choices.
    // Trust me, you don't want that. Thank me later.
    return undefined
  }
}

const PortalManagementContainer = ({
  children,
  portalContainerEl,
  zIndexClassName = 'z-40'
}: PortalManagementContainerProps) => {
  const floatingPortalNodeId = useFloatingNodeId()
  const _portalContainerEl = React.useMemo(() => {
    const cssScope = getCssScope()
    if (portalContainerEl) {
      return portalContainerEl
    }
    if (cssScope) {
      return createPortalElForCssScope(cssScope)
    }
    return document.getElementById('banquetPortalsContainer') || document.body
  }, [portalContainerEl])

  return (
    <FloatingNode id={`${floatingPortalNodeId}:${zIndexClassName}`}>
      <FloatingPortal root={_portalContainerEl}>{children}</FloatingPortal>
    </FloatingNode>
  )
}

export const PortalManagement = ({
  children,
  context,
  disableFocusManager,
  isModal,
  closeOnFocusOut,
  disableInitialFocus,
  ...restProps
}: PortalManagementProps) => {
  if (disableFocusManager) {
    return (
      <PortalManagementContainer {...restProps}>
        {children}
      </PortalManagementContainer>
    )
  }

  return (
    <PortalManagementContainer {...restProps}>
      <FloatingFocusManager
        context={context}
        modal={isModal || false}
        closeOnFocusOut={closeOnFocusOut}
        initialFocus={disableInitialFocus ? -1 : 0}
      >
        {children}
      </FloatingFocusManager>
    </PortalManagementContainer>
  )
}
