import * as React from 'react'
import classnames from 'classnames'
import {
  Tabs as ReachTabs,
  TabsProps as ReachTabsProps,
  TabList as ReachTabList,
  TabListProps as ReachTabListProps,
  Tab as ReachTab,
  TabProps as ReachTabProps,
  TabPanels as ReachTabPanels,
  TabPanelsProps as ReachTabPanelsProps,
  TabPanel as ReachTabPanel,
  TabPanelProps as ReachTabPanelProps,
  useTabsContext,
  TabsKeyboardActivation
} from '@reach/tabs'
import type { TabsContextValue } from '@reach/tabs'
import { useIntersection } from 'react-use'
import { ChevronLeftIcon, ChevronRightIcon } from '@toasttab/buffet-pui-icons'
import { ScreenSize, useScreenSize } from '@toasttab/use-screen-size'
import { t, loadStrings } from '../defaultStrings'

import styles from './styles.module.css'
const cx = classnames.bind(styles)

export { useTabsContext, TabsContextValue, TabsKeyboardActivation }

export interface TabProps extends ReachTabProps {
  testId?: string
  index?: number
}

const getTextContent = function (children?: React.ReactNode): string {
  let label = ''

  React.Children.map(children, (child) => {
    if (typeof child === 'string') {
      label += child
    }
  })

  return label
}

export const Tab = ({
  index,
  testId = `tab-label-${index}`,
  children
}: React.PropsWithChildren<TabProps>) => {
  const { selectedIndex } = useTabsContext()
  const isSelected = selectedIndex === index

  loadStrings()

  return (
    <ReachTab
      data-testid={testId}
      className={cx(
        'type-default md:type-large leading-none text-secondary focus-visible:text-default hover:text-default',
        // Overrides the default styles of some browsers; important for components that exist on pages without normalize.css
        'py-0',
        {
          'px-2 md:px-3': index === undefined || index > 0,
          'pl-0 pr-2 md:pr-3 text-left': index === 0
        },
        'focus-visible:shadow-focus outline-none',
        styles.tab
      )}
    >
      <div className='relative flex flex-col items-end w-full h-12 md:pt-0 md:h-14'>
        <span
          className={cx('m-auto', {
            'font-semibold text-default': isSelected
          })}
          data-text-content={getTextContent(children)}
        >
          {children}
        </span>
        <div
          className={cx('absolute w-full bg-brand-50')}
          style={{
            height: isSelected ? '3px' : '0px',
            bottom: '-1px'
          }}
        />
      </div>
    </ReachTab>
  )
}

const scrollRight = (
  ref: React.RefObject<HTMLDivElement | undefined>,
  buttonSize: number
) => {
  const target = ref.current
  if (target) {
    const left = Math.min(
      target.scrollWidth - target.clientWidth,
      target.scrollLeft + (target.clientWidth - 2 * buttonSize)
    )
    target.scroll({ left: left, behavior: 'smooth' })
  }
}

const scrollLeft = (
  ref: React.RefObject<HTMLDivElement | undefined>,
  buttonSize: number
) => {
  const target = ref.current
  if (target) {
    const left = Math.max(
      0,
      target.scrollLeft - (target.clientWidth - 2 * buttonSize)
    )
    target.scroll({ left: left, behavior: 'smooth' })
  }
}

/**
 * @deprecated This component is no longer needed and can be removed
 */
export const TabListScrollContainer = ({
  children
}: React.PropsWithChildren<unknown>) => {
  return <div>{children}</div>
}

interface TabListScrollContainerProps {
  children: React.ReactNode
  className?: string
}

const TabListScrollContainerInternal = ({
  className,
  children
}: TabListScrollContainerProps) => {
  const screenSize = useScreenSize()

  const ref = React.useRef<HTMLDivElement>(null)
  const modalIntersectionOptions = {
    root: ref.current,
    rootMargin: '1px',
    threshold: 1
  }
  const leftRef = React.useRef<HTMLDivElement>(null)
  const leftIntersection = useIntersection(leftRef, modalIntersectionOptions)
  const rightRef = React.useRef<HTMLDivElement>(null)
  const rightIntersection = useIntersection(rightRef, modalIntersectionOptions)

  const fontSize =
    parseFloat(getComputedStyle(document.documentElement).fontSize) || 16
  const halfFontSize = fontSize / 2

  const buttonSize = screenSize >= ScreenSize.MD ? 3.5 * fontSize : 3 * fontSize

  const isOverflowLeft = leftIntersection && !leftIntersection.isIntersecting
  const isOverflowRight = rightIntersection && !rightIntersection.isIntersecting

  const clipPathInsetLeft = isOverflowLeft ? buttonSize : 0
  const clipPathInsetRight = isOverflowRight ? buttonSize : 0

  const clipPath =
    isOverflowLeft || isOverflowRight
      ? `inset(0 ${clipPathInsetRight}px 0 ${clipPathInsetLeft}px)`
      : 'none'

  return (
    <div className={cx('border-b border-gray-50', className)}>
      <div className='relative h-12 md:h-14'>
        {/* Account for the focus shadow by making
         * the box 4px wider than the container
         * wider than the container that it is in
         */}
        <div
          className='absolute top-0 left-0 -mt-1 -ml-1'
          style={{
            clipPath,
            width: `calc(100% + ${halfFontSize}px)`,
            height: `${buttonSize + halfFontSize}px`
          }}
        >
          <div
            className={cx('overflow-auto py-1 flex', styles.tabContainer)}
            ref={ref}
          >
            <div className='h-1 w-1 flex-none' ref={leftRef} />
            <div className='flex-none'>{children}</div>
            <div className='h-1 w-1 flex-none' ref={rightRef} />
          </div>
        </div>
        {isOverflowLeft && (
          <button
            className={cx(
              'absolute left-0 flex items-center justify-center w-12 h-12 text-gray-100',
              'outline-none cursor-pointer group md:h-14 md:w-14'
            )}
            style={{
              top: '1px'
            }}
            onClick={() => scrollLeft(ref, buttonSize)}
          >
            <ChevronLeftIcon
              className='p-2 rounded-full group-hover:bg-darken-4 group-focus-visible:shadow-focus'
              aria-label={t('scroll-left')}
            />
          </button>
        )}
        {isOverflowRight && (
          <button
            className={cx(
              'absolute right-0 flex items-center justify-center w-12 h-12 text-gray-100',
              'outline-none cursor-pointer group md:h-14 md:w-14'
            )}
            style={{
              top: '1px'
            }}
            onClick={() => scrollRight(ref, buttonSize)}
          >
            <ChevronRightIcon
              className='p-2 rounded-full group-hover:bg-darken-4 group-focus-visible:shadow-focus'
              aria-label={t('scroll-right')}
            />
          </button>
        )}
      </div>
    </div>
  )
}

export interface TabListProps extends ReachTabListProps {
  /** Use to apply tailwind classes, like width, padding or margin */
  className?: string
  /** When true, uses the tailwind class, flex, when false, uses flex-inline */
  block?: boolean
  /** disable the scroll behavior when the tabs cannot fully fit in their container */
  disableScroll?: boolean
  /** Allow tabs to wrap to multiple lines */
  enableMultiLineText?: boolean
}

/**
 * Clones the children and provides them with the index
 *
 * Each child should be a `<Tab>`
 *
 */
export const TabList = ({
  children,
  className,
  block = false,
  disableScroll = false,
  enableMultiLineText = false
}: React.PropsWithChildren<TabListProps>) => {
  if (React.Children.count(children) === 0) {
    throw Error('you must provide an array of children to the TabList')
  }

  const ScrollContainer = ({
    children,
    ...props
  }: TabListScrollContainerProps) => {
    if (disableScroll) return <>{children}</>

    return (
      <TabListScrollContainerInternal {...props}>
        {children}
      </TabListScrollContainerInternal>
    )
  }

  return (
    <ScrollContainer className={className}>
      <ReachTabList
        className={cx(disableScroll && className, 'justify-start', {
          flex: block,
          'whitespace-nowrap': !enableMultiLineText,
          'border-b border-gray-50': disableScroll,
          'inline-flex': !block
        })}
        style={{
          minWidth: '100%'
        }}
      >
        {React.Children.map(children, (child, index) => {
          if (React.isValidElement<TabProps>(child)) {
            return React.cloneElement<TabProps>(child, { index })
          }
          console.warn('child is not a valid ReactNode<TabProps>')
          return child
        })}
      </ReachTabList>
    </ScrollContainer>
  )
}

export interface TabPanelsProps extends ReachTabPanelsProps {
  /** Use to apply tailwind classes, like width, padding or margin */
  className?: string
}

/**
 * Maps through the children provided and applies `TabPanelProps` to each of them.
 *
 * Each child SHOULD be a `<TabPanel>`
 *
 * @param children an array of React.ReactNode that each accepts `TabPanelProps` as properties
 */
export const TabPanels = ({
  children,
  ...props
}: React.PropsWithChildren<TabPanelsProps>) => {
  return (
    <ReachTabPanels {...props}>
      {React.Children.map(children, (child, index) => {
        if (React.isValidElement<TabPanelProps>(child)) {
          return React.cloneElement<TabPanelProps>(child, { index })
        }
        console.warn('child is not a valid ReactNode<TabPanelProps>')
        return child
      })}
    </ReachTabPanels>
  )
}

export interface TabPanelProps<
  onMountResult = void,
  onSelectionResult = void,
  onBlurResult = void
> extends ReachTabPanelProps {
  index?: number
  onMount?: (index?: number) => onMountResult
  onSelection?: (index?: number) => onSelectionResult
  onBlur?: (index?: number) => onBlurResult
  className?: string
  testId?: string
}

export interface TabsProps extends ReachTabsProps {
  /** Use to apply tailwind classes, like width, padding or margin */
  className?: string
}

/**
 * A component that should render a list of `<Tab>` components.
 */
export const Tabs = React.forwardRef(
  ({ ...props }: React.PropsWithChildren<TabsProps>, ref) => {
    return <ReachTabs {...props} ref={ref} />
  }
)

/**
 *  A container for the tab contents
 *
 * @param index the 'id' of the panel. Should NOT be specified if wrapped in a `<TabPanels>` component
 * @param onMount an event which will be fired the FIRST time an panel is selected
 * @param onSelection an event which will be fired EVERY time a panel is selected
 * @param onBlur an event which will be fired EVERY time a panel is left
 * @param testId applied as `data-testid` which defaults to `tab-panel-{index}` if not specified
 */
export const TabPanel = ({
  index,
  onMount = () => {},
  onSelection = () => {},
  onBlur = () => {},
  testId = `tab-panel-${index}`,
  className,
  children
}: React.PropsWithChildren<TabPanelProps>) => {
  const { selectedIndex } = useTabsContext()
  const isSelected = selectedIndex === index
  const isMounted = React.useRef(false)

  //can these be converted to update a context that the tab panel children have access to?

  React.useEffect(() => {
    if (isSelected && !!onMount && !isMounted.current) {
      onMount(index)
      isMounted.current = true
    }
  }, [isSelected, onMount, index])

  React.useEffect(() => {
    if (isSelected && onSelection) {
      onSelection(index)
    }
  }, [isSelected, onSelection, index])

  React.useEffect(() => {
    return () => {
      if (isSelected && onBlur) {
        onBlur(index)
      }
    }
  }, [isSelected, index, onBlur])

  return (
    <ReachTabPanel
      data-testid={testId}
      className={cx('outline-none text-default type-default', className)}
    >
      {children}
    </ReachTabPanel>
  )
}
