import {
  DepotEditSubscriptionFormValues,
  SavedSubscription
} from '@local/manage-webhooks-shared'
import { useDepotEditSubscription } from '@local/manage-webhooks-shared/graphql-depot'
import { FormikContextType, useFormik } from 'formik'
import { useMemo } from 'react'

import { buildDepotEditSubscriptionsFormInitialValues } from '../utils/initialValuesBuilder'
import { useDepotGetEditSubscriptionFormSchema } from '../utils/formValidation/formValidation'
import { getDepotSubscriptionFromForm } from '../utils/getObjectFromFormField'

export function useDepotEditSubscriptionForm(
  existingSubscription: SavedSubscription
): FormikContextType<DepotEditSubscriptionFormValues> {
  const { saveSubscription } = useDepotEditSubscription()

  const initialValues = useMemo(
    () => buildDepotEditSubscriptionsFormInitialValues(existingSubscription),
    [existingSubscription]
  )

  const validationSchema = useDepotGetEditSubscriptionFormSchema()

  return useFormik<DepotEditSubscriptionFormValues>({
    initialValues,
    onSubmit: (value) =>
      saveSubscription(
        getDepotSubscriptionFromForm(value, existingSubscription)
      ),
    enableReinitialize: true,
    validateOnMount: true,
    validationSchema
  })
}
