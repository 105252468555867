import { MerryGoRound } from '@toasttab/buffet-pui-loading-indicators'

export function LoadingPageBody() {
  return (
    <div className='flex flex-col items-center gap-y-2 mt-20'>
      <MerryGoRound size='sm' />
      <div>Loading...</div>
    </div>
  )
}
