import { useDomainContext } from '@local/current-domain'
import { Partner, Subscription } from '@local/manage-webhooks-shared'
import {
  SortingState,
  getCoreRowModel,
  getSortedRowModel,
  useReactTable
} from '@tanstack/react-table'
import { useRowPagination } from '@toasttab/buffet-pui-pagination'
import { useState } from 'react'

import { useSubscriptionTableColumns } from './useSubscriptionTableColumns'

export function useSubscriptionTable(
  filteredSubscriptions: Subscription[],
  partners: Partner[] | undefined
) {
  const columns = useSubscriptionTableColumns(partners)

  const [sorting, setSorting] = useState<SortingState>([])

  const { isDepotDomain } = useDomainContext()

  const table = useReactTable({
    data: filteredSubscriptions,
    columns,
    initialState: {
      columnVisibility: { subscriberName: !isDepotDomain }
    },
    state: {
      sorting
    },
    onSortingChange: (newSort) => {
      pagination.gotoPage(0)
      setSorting(newSort)
    },
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: getSortedRowModel()
  })

  const pagination = useRowPagination({
    rows: table.getSortedRowModel().rows,
    pageSize: 10
  })

  return { table, pagination }
}
