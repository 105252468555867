import * as React from 'react'
import cx from 'classnames'

export type Variant =
  | 'primary'
  | 'secondary'
  | 'link'
  | 'destructive'
  | 'destructive-secondary'
  | 'text-link'

export type Size =
  | 'lg'
  | 'base' // deprecated in favor of 'lg'
  | 'sm'
  | 'auto'

export interface ButtonCommonProps {
  /**
   * Controls the style of the button
   */
  variant?: Variant

  /**
   * Controls the height of the button
   */
  size?: Size

  /**
   * A way to provide an Icon (component) that will be applied to the right side of the text
   */
  iconRight?: React.ReactNode

  /**
   * A way to provide an Icon (component) that will be applied to the left side of the text
   */
  iconLeft?: React.ReactNode

  /**
   * Applied as data-testid to the underlying component
   */
  testId?: string | number

  /**
   * If true, overrides the polymorphism 'as' prop
   * and resolves the component type to 'button'
   */
  disabled?: boolean
}

type useButtonStylesProps = {
  size?: Size
  hasRightIcon?: boolean
  hasLeftIcon?: boolean
  variant?: Variant
  className?: string
  disabled?: boolean
}

/**
 * @deprecated
 *
 * Replace
 * ```
 * const buttonStyles = useButtonStyles(props)
 * <Link
 *   to={AUDIT_HISTORY_PATH}
 *   data-toast-track-id='audit-history'
 *   className={buttonStyles}
 * >
 *   <ButtonContent>Audit history</ButtonContent>
 * </Link>
 * ```
 * with
 * ```
 * <Button
 *   as={Link}
 *   to={AUDIT_HISTORY_PATH}
 *   data-toast-track-id='audit-history'
 *   {...props}
 * >
 * Audit history
 * </Button>
 * ```
 * This hook predated the `as` prop on IconButton.
 *
 * Additionally, it was easy to mis-use this API,
 * for example by not pairing with ButtonContent,
 * or failing to use the iconLeft and iconRight props
 * from ButtonContent when positioning icons.
 *
 */
export const useButtonStyles = ({
  size = 'auto',
  hasRightIcon = false,
  hasLeftIcon = false,
  variant = 'primary',
  className = '',
  disabled = false
}: useButtonStylesProps) => {
  const iconClasses = getIconPlacementStyles(
    size,
    variant,
    hasRightIcon,
    hasLeftIcon
  )
  const heightClasses = getHeightStyles(size)
  const paddingClasses = getPaddingStyles(size, variant)
  const variantClasses = getVariantStyles(variant, disabled)

  return cx(
    'type-default no-underline',
    'text-center',
    'min-w-button',
    'inline-flex items-center ',
    'transition ease-in-out duration-200',
    'py-0',
    'font-semibold',
    heightClasses,
    paddingClasses,
    iconClasses,
    variantClasses,
    className
  )
}

export interface ButtonContentProps {
  iconRight?: React.ReactNode
  iconLeft?: React.ReactNode
}

/**
 * @deprecated - Replace with `<Button as={Link} ...` or equivalent.
 */
export const ButtonContent: React.FC<
  React.PropsWithChildren<ButtonContentProps>
> = ({ iconRight = null, iconLeft = null, children }) => (
  <>
    <IconForButton icon={iconLeft} offset='pr-2' />
    <span className='truncate'>{children}</span>
    <IconForButton icon={iconRight} offset='pl-2' />
  </>
)

const getVariantStyles = (variant: Variant, disabled: boolean) => {
  if (disabled) {
    switch (variant) {
      case 'link':
        return 'btn-link-disabled cursor-default'
      case 'text-link':
        return 'btn-text-link-disabled cursor-default'
      case 'secondary':
      case 'destructive-secondary':
        return 'btn-secondary-disabled outline-none cursor-default'
      case 'primary':
      case 'destructive':
      default:
        return 'btn-primary-disabled cursor-default'
    }
  } else {
    switch (variant) {
      case 'link':
        return 'btn-link focus-visible:shadow-focus outline-none cursor-pointer'
      case 'text-link':
        return 'btn-text-link focus-visible:shadow-focus outline-none cursor-pointer'
      case 'primary':
        return 'btn-primary focus-visible:shadow-focus outline-none cursor-pointer'
      case 'destructive':
        return 'btn-destructive focus-visible:shadow-focus outline-none cursor-pointer'
      case 'destructive-secondary':
        return 'btn-destructive-secondary focus-visible:shadow-focus outline-none cursor-pointer'
      case 'secondary':
      default:
        return 'btn-secondary focus-visible:shadow-focus outline-none cursor-pointer'
    }
  }
}

const getHeightStyles = (size: Size) => {
  switch (size) {
    case 'auto':
      return 'h-12 md:h-10 min-h-12 md:min-h-10'
    case 'sm':
      return 'h-10 min-h-10'
    case 'lg':
    case 'base':
    default:
      return 'h-12 min-h-12'
  }
}

const getPaddingStyles = (size: Size, variant: Variant) => {
  if (variant === 'text-link') {
    return ''
  }
  switch (size) {
    case 'auto':
      return 'px-6 md:px-4'
    case 'sm':
      return 'px-4'
    case 'lg':
    case 'base':
    default:
      return 'px-6'
  }
}

const getIconPlacementStyles = (
  size: Size,
  variant: Variant,
  hasRightIcon: boolean,
  hasLeftIcon: boolean
) =>
  cx(
    'justify-center',
    variant !== 'text-link' && {
      'pr-4': (size === 'base' || size === 'lg') && hasRightIcon,
      'pl-4': (size === 'base' || size === 'lg') && hasLeftIcon,
      'pr-3': size === 'sm' && hasRightIcon,
      'pl-3': size === 'sm' && hasLeftIcon,
      'pr-4 md:pr-3': size === 'auto' && hasRightIcon,
      'pl-4 md:pl-3': size === 'auto' && hasLeftIcon
    }
  )

const IconForButton = ({
  icon,
  offset
}: {
  icon: React.ReactNode
  offset: string
}) =>
  icon ? (
    <span className={cx('mt-px flex items-center', offset)}>{icon}</span>
  ) : null
