import * as React from 'react'
import cx from 'classnames'
import { useWindowSize } from '@toasttab/buffet-use-window-size'
import { Container } from '@toasttab/use-container-size'
import {
  FlushBreakpoint,
  LayoutProviderInternal,
  useLayoutContext,
  useLayoutContextDeprecated
} from '../../common'

export interface PageProps extends React.HTMLAttributes<HTMLDivElement> {
  disableMaxWidth?: boolean
  scrollToTop?: boolean
  testId?: string | number
}

export function Page({
  style,
  children,
  className,
  testId,
  disableMaxWidth,
  scrollToTop,
  ...props
}: PageProps) {
  const {
    disableMaxWidth: disableMaxWidthDeprecated,
    scrollToTop: scrollToTopDeprecated
  } = useLayoutContextDeprecated()

  return (
    <LayoutProviderInternal
      disableMaxWidth={disableMaxWidth || disableMaxWidthDeprecated}
      scrollToTop={scrollToTop || scrollToTopDeprecated}
      flushBreakpoint={FlushBreakpoint.MD}
      isFocusView={false}
    >
      <Container
        style={{
          backgroundPosition: 'right 15rem',
          ...style
        }}
        data-testid={testId}
        className={cx('bg-fixed bg-no-repeat bg-page', className)}
        {...props}
      >
        <PageInternal {...props}>{children}</PageInternal>
      </Container>
    </LayoutProviderInternal>
  )
}

const PageInternal = ({ children, ...props }: PageProps) => {
  const { disableMaxWidth, sectionRef, setPagePositions } = useLayoutContext()
  const { width: windowWidth } = useWindowSize()
  React.useEffect(() => {
    if (sectionRef?.current) {
      setPagePositions('headerTop', sectionRef.current.offsetTop)
    }
  }, [windowWidth, setPagePositions, sectionRef])

  return (
    <section
      className='pb-20 mx-auto'
      style={{ maxWidth: disableMaxWidth ? 'none' : '68.25rem' }}
      ref={sectionRef}
      {...props}
    >
      {children}
    </section>
  )
}
