import * as React from 'react'
import cx from 'classnames'
import { TestIdentifiable } from '@toasttab/buffet-shared-types'
import { Variant, variants, WithVariant } from '../variant'

export interface SuffixProps extends TestIdentifiable {
  variant: Variant
  disabled?: boolean
  roundedClassName?: string
  readOnly?: boolean
}

const getPaddingRight = (variant: Variant) => {
  switch (variant) {
    case variants.textOnly:
    case variants.bgGray:
      return 'calc(0.75rem - 1px)'
    default:
      return '0px'
  }
}

const getMarginRight = (variant: Variant) => {
  switch (variant) {
    case variants.icon:
      return 'calc(0.75rem - 1px)'
    case variants.iconButton:
      return 'calc(0.25rem - 1px)'
    default:
      return '0px'
  }
}

export const Suffix: WithVariant<
  React.FC<React.PropsWithChildren<SuffixProps>>
> = ({
  testId,
  variant,
  disabled,
  roundedClassName = 'rounded-r-input',
  readOnly,
  children
}) => {
  return (
    <div
      /**
       *  Hack to make the box-shadow used for the border work
       *  works ok in this instance as the item is not clickable
       */
      style={{
        zIndex: variant === variants.bgGray ? -1 : 'unset',
        paddingRight: getPaddingRight(variant),
        marginRight:
          roundedClassName === 'rounded-r-full'
            ? undefined
            : getMarginRight(variant),
        lineHeight: 0
      }}
      data-testid={testId}
      className={cx(
        'type-default whitespace-nowrap',
        variant === variants.bgGray && roundedClassName,
        {
          'text-disabled': disabled && variant === variants.textOnly,
          'text-secondary':
            !disabled && (variant === variants.textOnly || readOnly),
          'bg-gray-0 pl-3 leading-none h-full flex items-center text-disabled':
            disabled && variant === variants.bgGray,
          'bg-gray-25 pl-3 leading-none h-full flex items-center':
            !disabled && variant === variants.bgGray,
          'text-disabled leading-none absolute right-0 pointer-events-none':
            disabled && variant === variants.icon,
          'text-secondary leading-none absolute right-0 pointer-events-none':
            !disabled && variant === variants.icon,
          'leading-none': variant === variants.iconButton,
          'border-l': !disabled && variant === variants.bgGray && readOnly,
          'focus-within:bg-darken-4 focus-within:rounded-full':
            variant === variants.iconButton
        }
      )}
    >
      {children}
    </div>
  )
}

Suffix.Variant = variants
