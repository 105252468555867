import {
  NewSubscription,
  StatusBadge,
  Subscription,
  SubscriptionStatus
} from '@local/manage-webhooks-shared'
import { useToastAdminEditSubscription } from '@local/manage-webhooks-shared/graphql-toast-admin'
import { Button, IconButton } from '@toasttab/buffet-pui-buttons'
import { PauseIcon, ToastBadgeIcon } from '@toasttab/buffet-pui-icons'
import { Modal, ModalBody, ModalHeader } from '@toasttab/buffet-pui-modal'
import { useSnackBar } from '@toasttab/buffet-pui-snackbars'
import { FunctionComponent, useState } from 'react'

import { isDevOrPreprod } from '../utils'

export interface PreprodTestingBarProps {
  subscriptionGuid: string
}

export const PreprodTestingModal: FunctionComponent<PreprodTestingBarProps> = ({
  subscriptionGuid
}) => {
  const shouldShowTestingModal = isDevOrPreprod(window.location.origin)
  const [modalOpen, setModalOpen] = useState(false)
  const { showSnackBar } = useSnackBar()
  const onPause = () => {
    showSnackBar('Webhook paused')
  }
  const { saveSubscription } = useToastAdminEditSubscription(
    subscriptionGuid,
    onPause
  )
  const pausedSubscription: Partial<Subscription> = {
    guid: subscriptionGuid,
    paused: true,
    stopped: false
  }
  return (
    <>
      {shouldShowTestingModal && subscriptionGuid && (
        <>
          <IconButton
            icon={<ToastBadgeIcon className='text-brand-75' size='md' />}
            onClick={() => setModalOpen(true)}
            testId='open-preprod-testing-bar-button'
          />
          <Modal
            position='pin-right'
            onRequestClose={() => setModalOpen(false)}
            isOpen={modalOpen}
            shouldCloseOnOverlayClick
            overflowBehavior='body'
            testId={'preprod-testing-modal'}
          >
            <ModalHeader>Webhook testing utils</ModalHeader>
            <ModalBody>
              <div className='flex flex-col justify-between mt-4 h-full'>
                <div className='flex flex-col justify-start'>
                  <div className='flex flex-col'>
                    <p>
                      Press this button to set this subscription's status to{' '}
                    </p>

                    <span>
                      <StatusBadge status={SubscriptionStatus.Paused} />
                    </span>
                  </div>
                  <Button
                    iconLeft={<PauseIcon size='sm' />}
                    onClick={() =>
                      saveSubscription(pausedSubscription as NewSubscription)
                    }
                    className='mt-8 ml-0 mr-10'
                  >
                    Pause subscription
                  </Button>
                </div>
                <p className='self-end'>
                  Have some ideas for other tools to add? Reach out to{' '}
                  <a
                    href='https://toasttab.slack.com/archives/CTYDHULT1'
                    className='text-link'
                  >
                    #webhooks-dev
                  </a>{' '}
                  on Slack
                </p>
              </div>
            </ModalBody>
          </Modal>
        </>
      )}
    </>
  )
}
