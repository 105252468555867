import { PropsWithChildren, createContext, useContext, useMemo } from 'react'

import { getIsDepotDomain } from '../isDepotDomain'

type DomainContextValue = {
  isDepotDomain: boolean
  hostDomain: HOST_DOMAIN
}
const DomainContext = createContext<DomainContextValue>(
  {} as DomainContextValue
)

export function DomainContextProvider({ children }: PropsWithChildren) {
  const isDepotDomain = useMemo(() => getIsDepotDomain(), [])
  const hostDomain = useMemo(() => {
    if (getIsDepotDomain()) return HOST_DOMAIN.DEPOT
    return HOST_DOMAIN.TOASTWEB
  }, [])
  return (
    <DomainContext.Provider value={{ isDepotDomain, hostDomain }}>
      {children}
    </DomainContext.Provider>
  )
}

export function useDomainContext() {
  return useContext(DomainContext)
}

export enum HOST_DOMAIN {
  TOASTWEB = 'TOASTWEB',
  DEPOT = 'DEPOT'
}

export function DomainContextProviderForTesting({
  children,
  isDepotDomain,
  hostDomain
}: PropsWithChildren<{ isDepotDomain?: boolean; hostDomain?: HOST_DOMAIN }>) {
  const domain = isDepotDomain ? HOST_DOMAIN.DEPOT : HOST_DOMAIN.TOASTWEB
  return (
    <DomainContext.Provider
      value={{
        isDepotDomain: isDepotDomain ?? false,
        hostDomain: hostDomain ?? domain
      }}
    >
      {children}
    </DomainContext.Provider>
  )
}
