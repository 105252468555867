import {
  EventCategory,
  Partner,
  SavedSubscription,
  ToastAdminEditSubscriptionFormValues
} from '@local/manage-webhooks-shared'
import { useToastAdminEditSubscription } from '@local/manage-webhooks-shared/graphql-toast-admin'
import { FormikContextType, useFormik } from 'formik'
import { useMemo } from 'react'

import { buildToastAdminEditSubscriptionsFormInitialValues } from '../utils/initialValuesBuilder'
import { useToastAdminGetEditSubscriptionFormSchema } from '../utils/formValidation/formValidation'
import { getToastAdminSubscriptionFromForm } from '../utils/getObjectFromFormField'

export function useToastAdminSubscriptionForm(
  existingSubscription: SavedSubscription | undefined,
  partners: Partner[],
  eventCategories: EventCategory[]
): FormikContextType<ToastAdminEditSubscriptionFormValues> {
  const { saveSubscription } = useToastAdminEditSubscription(
    existingSubscription?.guid
  )

  const initialValues = useMemo(
    () =>
      buildToastAdminEditSubscriptionsFormInitialValues(
        existingSubscription,
        partners
      ),
    [partners, existingSubscription]
  )

  const validationSchema = useToastAdminGetEditSubscriptionFormSchema(
    partners,
    eventCategories
  )

  return useFormik<ToastAdminEditSubscriptionFormValues>({
    initialValues,
    onSubmit: (value) =>
      saveSubscription(
        getToastAdminSubscriptionFromForm(value, existingSubscription, partners)
      ),
    enableReinitialize: true,
    validateOnMount: true,
    validationSchema
  })
}
