import { LoadingPageBody } from '@local/app-routes/LoadingPageBody/LoadingPageBody'
import { NoSubscriptionPageBody } from '@local/app-routes/NoSubscriptionPageBody/NoSubscriptionPageBody'
import { useDomainContext } from '@local/current-domain'
import { filterByStatus } from '@local/manage-webhooks-shared'
import { useWebhooksContext } from '@local/manage-webhooks-shared/WebhooksContext/WebhooksContext'
import {
  FilterOption,
  filterOptions
} from '@local/manage-webhooks-shared/subscription-status-utils/filterOptions'
import {
  SubscriptionTable,
  useSubscriptionTable
} from '@local/subscriptions-table'
import { Select } from '@toasttab/buffet-pui-select'
import { ErrorPage500 } from '@toasttab/buffet-pui-error-pages'
import { useMemo, useState } from 'react'
import { NoWebhooksMatchPanel } from '@local/app-routes/NoWebhooksMatchPanel/NoWebhooksMatchPanel'

import { SubscriptionStatusSummary } from './SubscriptionStatusSummary/SubscriptionStatusSummary'
import { SubscriptionsActionButtons } from './SubscriptionsActionButtons/SubscriptionsActionButtons'

export const SubscriptionsPage = () => {
  const { isDepotDomain } = useDomainContext()

  const { loading, error, subscriptions, partners } = useWebhooksContext()

  const [statusFilter, setStatusFilter] = useState<FilterOption>(
    FilterOption.ALL
  )

  const filteredSubscriptions = useMemo(() => {
    if (!subscriptions) return []
    if (statusFilter === FilterOption.ALL) {
      return subscriptions
    } else {
      return filterByStatus(subscriptions, statusFilter)
    }
  }, [statusFilter, subscriptions])

  const { table, pagination } = useSubscriptionTable(
    filteredSubscriptions,
    partners
  )

  if (loading) return <LoadingPageBody />

  if (error) return <ErrorPage500 />

  if (!subscriptions?.length) return <NoSubscriptionPageBody />

  return (
    <>
      <div className='flex justify-between mb-6 items-end'>
        <Select
          label='Status'
          options={filterOptions}
          value={statusFilter}
          onChange={(value: FilterOption) => {
            pagination.gotoPage(0)
            setStatusFilter(value)
          }}
          containerClassName='w-40'
          testId='status-filter-select'
        />
        {!isDepotDomain && <SubscriptionsActionButtons />}
      </div>
      <SubscriptionStatusSummary />

      {!filteredSubscriptions.length ? (
        <NoWebhooksMatchPanel
          handleClearSearch={() => setStatusFilter(FilterOption.ALL)}
        />
      ) : (
        <SubscriptionTable table={table} pagination={pagination} />
      )}
    </>
  )
}
