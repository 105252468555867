export const createPageRange = ({
  currentPage,
  totalPageCount,
  visiblePages
}: {
  currentPage: number
  totalPageCount: number
  visiblePages: number
}) => {
  if (totalPageCount <= 0 || visiblePages <= 0) {
    return []
  }

  if (totalPageCount <= visiblePages) {
    return Array(totalPageCount)
      .fill(null)
      .map((_, index) => index)
  }

  const lastPage = totalPageCount - 1
  currentPage = Math.max(0, Math.min(currentPage, lastPage))

  // Need at least 5 to show (start, ..., current, ..., end) unless there are less than 5 pages
  if (visiblePages < 5) {
    return [currentPage]
  }

  let pagesAroundCenter = visiblePages - 1
  let start = currentPage
  let end = currentPage
  let pages: Array<number | null> = [currentPage]

  while (start > 0 || end < lastPage) {
    if (start > 0) {
      pages.unshift(--start)
      if (--pagesAroundCenter <= 0) break
    }

    if (end < lastPage) {
      pages.push(++end)
      if (--pagesAroundCenter <= 0) break
    }
  }

  const [firstValue, , ...rest] = pages

  if (firstValue! > 0) {
    pages = [0, null, ...rest]
  }

  const lastValue = pages[pages.length - 1]!

  if (lastValue < lastPage) {
    pages.length -= 2
    pages.push(null, lastPage)
  }

  return pages
}
