import { gql } from '@apollo/client'

export const GET_EVENTS = gql`
  query getEvents($getEventsInput: GetEventsInput) {
    getEvents(getEventsInput: $getEventsInput) {
      lastEvaluatedKey
      webhookEvents {
        subscriptionGuid
        timestamp
        messageBody
        webhookGuid
        subscriptionUri
        signature
        eventCategory
        eventType
        eventGuid
        routeKey
        responseCode
        attempts
        status
      }
    }
  }
`

export const GET_EVENT_BY_KEYS = gql`
  query getEventByKeysQuery($partitionKey: String!, $sortKey: String!) {
    getEventByKeys(partitionKey: $partitionKey, sortKey: $sortKey) {
      subscriptionGuid
      timestamp
      messageBody
      webhookGuid
      subscriptionUri
      signature
      eventCategory
      eventType
      eventGuid
      routeKey
      responseCode
      attempts
      status
    }
  }
`
