import { DeleteSubscriptionButton } from '@local/confirm-delete-modal/DeleteSubscriptionButton/DeleteSubscriptionButton'
import { useDomainContext } from '@local/current-domain'

import { DepotDeleteSubscriptionButton } from './DepotDeleteSubscriptionButton'

export function DeleteButton() {
  const { isDepotDomain } = useDomainContext()

  if (isDepotDomain) {
    return <DepotDeleteSubscriptionButton />
  }

  return <DeleteSubscriptionButton />
}
