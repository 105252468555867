import React from 'react'
import classnames from 'classnames'
import { useUniqueId } from '@toasttab/buffet-utils'
import { TestIdentifiable } from '@toasttab/buffet-shared-types'

import styles from './styles.module.css'

const cx = classnames.bind(styles)

export type Size = 'xxs' | 'xs' | 'sm' | 'md' | 'lg' | 'xl' | 'xxl'

export const SIZE: {
  [P in Size]: P
} = {
  xxs: 'xxs',
  xs: 'xs',
  sm: 'sm',
  md: 'md', // TODO: This is what is defined as small in figma (and there is no sm, lg, xl, or xxl sizes)
  lg: 'lg',
  xl: 'xl',
  xxl: 'xxl'
}
export interface MerryGoRoundProps extends TestIdentifiable {
  /** Size of the merry go round */
  size?: Size
  className?: string
}

const Circle = ({ className }: { className: string }) => (
  <div
    className={cx('absolute rounded-full', className)}
    style={{
      height: '45%',
      width: '45%'
    }}
  />
)

export const MerryGoRound = ({
  // Literal used here for storybook generation
  size = 'xxs',
  testId,
  className
}: MerryGoRoundProps) => {
  testId = useUniqueId(testId, 'loading-indicator-')

  return (
    <div
      className={cx('relative', className, {
        'w-6 h-6': size === SIZE.xxs,
        'w-8 h-8': size === SIZE.xs,
        'w-10 h-10': size === SIZE.sm,
        'w-12 h-12': size === SIZE.md,
        'w-16 h-16': size === SIZE.lg,
        'w-20 h-20': size === SIZE.xl,
        'w-24 h-24': size === SIZE.xxl
      })}
      data-testid={testId}
    >
      <Circle className={cx('top-0 left-0', styles.topLeft)} />
      <Circle className={cx('top-0 right-0', styles.topRight)} />
      <Circle className={cx('bottom-0 right-0', styles.bottomRight)} />
      <Circle className={cx('bottom-0 left-0', styles.bottomLeft)} />
    </div>
  )
}

MerryGoRound.Size = SIZE
