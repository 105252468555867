import * as React from 'react'
import cx from 'classnames'
import { IconWrapper, IconProps, sizes } from '../../IconWrapper'
export const RestartIcon = React.forwardRef(function RestartIcon(
  props: IconProps,
  ref?: React.ForwardedRef<HTMLElement>
) {
  return (
    <IconWrapper ref={ref} {...props}>
      <>
        {
          <svg
            width={24}
            height={24}
            viewBox='0 0 24 24'
            fill='none'
            className={cx(
              'inline-block align-middle',
              sizes[props.size || 'sm'].className
            )}
          >
            <path
              d='M7.771 9.003a.75.75 0 10-.4-1.446l.4 1.446zm-4.055.344l-.723.2c.11.4.524.633.923.523l-.2-.723zm-.344-4.055a.75.75 0 10-1.446.4l1.446-.4zm-.283 3.654a.75.75 0 001.425.47l-1.425-.47zm2.308 7.494a.75.75 0 00-1.264.808l1.264-.808zM7.37 7.557L3.516 8.624l.4 1.446 3.855-1.067-.4-1.446zm-2.932 1.59L3.372 5.292l-1.446.4 1.067 3.855 1.446-.4zm.075.269a8.252 8.252 0 013.33-4.33l-.82-1.256A9.752 9.752 0 003.09 8.946l1.425.47zm3.33-4.33a8.252 8.252 0 015.303-1.3l.145-1.493A9.751 9.751 0 007.024 3.83l.82 1.257zm5.303-1.3c1.865.18 3.612.99 4.955 2.296l1.046-1.076a9.751 9.751 0 00-5.856-2.713l-.145 1.493zm4.955 2.296a8.25 8.25 0 012.436 4.888l1.488-.188a9.75 9.75 0 00-2.878-5.776l-1.046 1.076zm2.436 4.888a8.251 8.251 0 01-1.15 5.338l1.279.784a9.752 9.752 0 001.36-6.31l-1.49.188zm-1.15 5.338a8.251 8.251 0 01-4.233 3.451l.51 1.41a9.752 9.752 0 005.002-4.077l-1.28-.784zm-4.233 3.451a8.251 8.251 0 01-5.461.052l-.483 1.42a9.752 9.752 0 006.454-.061l-.51-1.411zm-5.461.052a8.251 8.251 0 01-4.297-3.37l-1.264.807A9.752 9.752 0 009.21 21.23l.483-1.42z'
              fill='currentColor'
            />
          </svg>
        }
      </>
    </IconWrapper>
  )
})
