import {
  TimePoint,
  timePointFromString
} from '@toasttab/buffet-pui-date-utilities'

export const getTimePointFromDate = (
  date: Date | undefined
): TimePoint | null => {
  if (!date) {
    return null
  }
  return timePointFromString(`${date.getHours()}:${date.getMinutes()}`)
}

export function setTimeToZero(date: Date): Date {
  const dateTmp = new Date(date)
  dateTmp.setHours(0, 0, 0, 0)
  return dateTmp
}

export const formatHourToMilitaryTime = (
  hoursValue: number | string,
  isPm: boolean
) => {
  const hours = Number(hoursValue)
  if (isPm) {
    if (hours === 12) return hours
    return hours + 12
  }

  return hours === 12 ? 0 : hours
}

export function combineDateTimePoint(
  date: Date | undefined,
  timePoint: TimePoint | null | undefined
): Date | undefined {
  if (!date) return undefined

  const dateTmp = new Date(date)

  if (!timePoint) return dateTmp

  const hours = formatHourToMilitaryTime(
    timePoint.hours ?? 0,
    timePoint.isPm ?? false
  )
  const minutes = Number(timePoint.minutes ?? 0)

  dateTmp.setHours(hours, minutes, 0, 0)
  return dateTmp
}
