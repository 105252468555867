import { FunctionComponent, useEffect, useMemo } from 'react'
import {
  Column,
  PluginHook,
  TableInstance,
  UseSortByInstanceProps,
  UseSortByOptions,
  UseTableOptions,
  useSortBy,
  useTable
} from 'react-table'
import {
  Event,
  getSortableHeaderCells,
  getTableRows
} from '@local/manage-webhooks-shared'
import { EventStatusBadge } from '@local/manage-webhooks-shared/event-status-badge/EventStatusBadge/EventStatusBadge'
import { IconButton } from '@toasttab/buffet-pui-buttons'
import { Formats, format } from '@toasttab/buffet-pui-date-utilities'
import {
  AutorenewIcon,
  InfoIcon,
  RestartIcon
} from '@toasttab/buffet-pui-icons'
import { Body, Head, Row, Table } from '@toasttab/buffet-pui-table'
import { Tooltip } from '@toasttab/buffet-pui-tooltip'

import { EventDetailsGuidButton } from '../EventDetailsModal/EventDetailsGuidButton/EventDetailsGuidButton'

interface TableOptions extends UseTableOptions<any>, UseSortByOptions<any> {}

type useTableCustomOptions<D extends object> = (
  options: TableOptions,
  ...plugins: Array<PluginHook<D>>
) => TableInstance<D> & UseSortByInstanceProps<D>

export interface EventsTableProps {
  events: Event[]
  onResend: (eventGuid: string, timestamp: string) => void
  onSortChange?: (accessor: string, sortDesc: boolean) => void
  isSortedNewestFirst?: boolean
  replaying: string[]
}

export const EventsTable: FunctionComponent<EventsTableProps> = ({
  events,
  onResend,
  onSortChange,
  isSortedNewestFirst,
  replaying
}) => {
  const columns: Column<any>[] = useMemo(
    () => [
      {
        Header: 'GUID',
        accessor: 'guid',
        id: 'data-table-guid',
        Cell: ({ cell }: any) => {
          const {
            row: {
              original: { guid }
            }
          } = cell
          return (
            <EventDetailsGuidButton
              event={events.find((event) => event.webhookGuid === guid)!}
              onReplay={onResend}
            />
          )
        },
        disableSortBy: true
      },
      {
        Header: () => (
          <Tooltip
            content={() =>
              'This value refers to the time in which the webhook event was initially sent to the API endpoint'
            }
            placement='right'
          >
            <span className='flex flex-row'>
              <div className='mr-1'>Initially sent</div>

              <InfoIcon className='' size='sm' />
            </span>
          </Tooltip>
        ),
        accessor: 'timestamp',
        Cell: ({ cell }: any) => {
          const {
            row: {
              original: { timestamp }
            }
          } = cell
          return (
            <div>
              {format(
                new Date(timestamp),
                Formats.dateTime.medium_with_seconds,
                'en-US'
              )}
            </div>
          )
        }
      },
      {
        Header: 'Attempts made',
        accessor: 'attempts',
        disableSortBy: true
      },
      {
        Header: 'Response code',
        accessor: 'responseCode',
        disableSortBy: true
      },
      {
        Header: 'Status',
        accessor: 'status',
        disableSortBy: true,
        Cell: ({ cell }: any) => {
          const {
            row: {
              original: { status }
            }
          } = cell
          return <EventStatusBadge status={status} />
        }
      },
      {
        Header: 'Replay',
        accessor: 'replayGuid',
        disableSortBy: true,
        Cell: ({ cell }: any) => {
          const {
            row: {
              original: { replayGuid, timestamp }
            }
          } = cell
          return (
            <IconButton
              icon={
                replaying.includes(replayGuid) ? (
                  <AutorenewIcon
                    className='text-secondary animate-spin'
                    size='sm'
                    aria-label='replaying'
                  />
                ) : (
                  <RestartIcon
                    className='text-secondary'
                    size='sm'
                    aria-label='replay'
                  />
                )
              }
              onClick={() => onResend(replayGuid, timestamp)}
              disabled={replaying.includes(replayGuid)}
            ></IconButton>
          )
        }
      }
    ],
    [events, onResend, replaying]
  )

  const data = useMemo(() => {
    return events.map((event: Event) => ({
      guid: event.webhookGuid,
      responseCode: event.responseCode != null ? event.responseCode : '-',
      timestamp: event.timestamp,
      attempts: event.attempts,
      status: event.status,
      replayGuid: event.webhookGuid
    }))
  }, [events])

  const { headers, rows, prepareRow, toggleSortBy } = (
    useTable as useTableCustomOptions<any>
  )(
    {
      columns,
      data,
      manualSortBy: true,
      initialState: {
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        //@ts-ignore sortBy doesn't match generic type <objec>
        sortBy: [{ id: 'timestamp', desc: isSortedNewestFirst }]
      }
    },
    useSortBy
  )

  useEffect(() => {
    toggleSortBy('timestamp', isSortedNewestFirst)
  }, [toggleSortBy, isSortedNewestFirst])

  return (
    <>
      <Table
        className='table table-striped table-bordered table-auto'
        testId='events-table'
      >
        <Head>
          <Row>{getSortableHeaderCells(headers, onSortChange, true)}</Row>
        </Head>
        <Body>{getTableRows(rows, prepareRow, columns, 'event-row')}</Body>
      </Table>
    </>
  )
}
