import { TimePoint } from '@toasttab/buffet-pui-date-utilities'

export type DisabledState =
  | boolean
  | Partial<{
      hours: boolean
      minutes: boolean
      isPm: boolean
    }>

export const isInteger = Number.isInteger as (value: any) => value is number

export const hasCustomRenderComponent = <T>(
  renderProp: T | boolean | undefined | null
): renderProp is T | null | false =>
  renderProp !== undefined && renderProp !== true

export const getDisabledState = (disabled: DisabledState | undefined) => {
  if (!disabled || typeof disabled === 'boolean') {
    const disabledState = Boolean(disabled)

    return {
      hours: disabledState,
      minutes: disabledState,
      isPm: disabledState
    }
  }

  return {
    hours: Boolean(disabled.hours),
    minutes: Boolean(disabled.minutes),
    isPm: Boolean(disabled.isPm)
  }
}

export const formatTimeValue = (
  value: string | number | null | undefined
): string => {
  if (value == null) {
    return ''
  }

  return value < 10 ? `0${value}` : `${value}`
}

export const clampTimePoint = (
  { hours, minutes, isPm }: TimePoint,
  defaultValue: TimePoint,
  shouldConvertTo12HourTime: boolean
): TimePoint => {
  let adjustedIsPm = isPm
  let clampedHours =
    hours === null ? defaultValue.hours : Math.max(0, Math.min(23, +hours))
  const clampedMinutes =
    minutes === null
      ? defaultValue.minutes
      : Math.max(0, Math.min(59, +minutes))

  if (!shouldConvertTo12HourTime) {
    adjustedIsPm = false
  } else if (typeof clampedHours === 'number' && clampedHours > 12) {
    clampedHours -= 12
    adjustedIsPm = true
  } else if (clampedHours === 0) {
    clampedHours = 12
    adjustedIsPm = false
  }

  return {
    isPm: Boolean(adjustedIsPm),
    hours: clampedHours,
    minutes: clampedMinutes
  }
}
