import * as React from 'react'

import type { useTooltip } from './useTooltip'

type ContextType = ReturnType<typeof useTooltip> | null

export const TooltipContext = React.createContext<ContextType>(null)

export const useTooltipContext = () => {
  const context = React.useContext(TooltipContext)

  if (context == null) {
    throw new Error('Tooltip components must be wrapped in <Tooltip />')
  }

  return context
}
