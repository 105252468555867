import * as React from 'react'
import useResizeObserver from '@react-hook/resize-observer'

export type UseMeasureRect = Pick<DOMRectReadOnly, 'height' | 'width'>
export type UseMeasureRef<E extends Element = Element> = (element: E) => void
export type UseMeasureResult<E extends Element = Element> = [
  UseMeasureRef<E>,
  UseMeasureRect
]

/**
 * Similar to useMeasure from react-use
 * but only returns width / height,
 * and returns more promptly.
 */
export function useMeasure<
  E extends HTMLElement = HTMLElement
>(): UseMeasureResult<E> {
  const [element, setElement] = React.useState<E | null>(null)

  const [contentRect, setContentRect] = React.useState<{
    height: number
    width: number
  }>()

  useResizeObserver(element, (entry) => {
    setContentRect(entry.contentRect)
  })

  const width = contentRect?.width || 0
  const height = contentRect?.height || 0

  const ref = React.useCallback<UseMeasureRef<E>>(
    (el: E) => {
      if (el) {
        if (!contentRect) {
          setContentRect(getContentRect(el))
        }
        setElement(el)
      }
    },
    [contentRect]
  )

  return [ref, { width, height }]
}

const getContentRect = (el: HTMLElement) => {
  const rect = el.getBoundingClientRect()
  const styles = getComputedStyle(el)
  return {
    width:
      rect.width -
      parseFloat(styles.paddingLeft) -
      parseFloat(styles.paddingRight),
    height:
      rect.height -
      parseFloat(styles.paddingTop) -
      parseFloat(styles.paddingBottom)
  }
}
