import { useQuery } from '@apollo/client'
import { useBanquetPartner } from '@local/banquet-partner-props'
import {
  EventCategory,
  Partner,
  Subscription
} from '@local/manage-webhooks-shared/types'
import { useSentry } from 'banquet-runtime-modules'

import { DEPOT_GET_WEBHOOKS } from './DEPOT_GET_WEBHOOKS'

export function useDepotWebhooks() {
  const { partner } = useBanquetPartner()
  const { hub } = useSentry()

  const { loading, error, data } = useQuery<{
    getSubscriptionsForSubscriber: Subscription[]
    getAvailableEventCategoriesForSubscriber: EventCategory[]
  }>(DEPOT_GET_WEBHOOKS, {
    skip: !partner?.guid,
    context: { headers: { 'toast-organization-guid': partner?.guid } },
    onError: (newError) => {
      hub.captureException(newError)
    }
  })

  return {
    loading,
    error,
    subscriptions: data?.getSubscriptionsForSubscriber,
    partners: [partner as Partner],
    eventCategories: data?.getAvailableEventCategoriesForSubscriber
  }
}
