import * as React from 'react'
import {
  ContainerSize,
  EMPTY_CONTAINER_MEASUREMENTS,
  EMPTY_SIZES
} from './constants'
import { ContainerContextType, Sizes } from './types'
import { useMeasure } from './useMeasure'

export interface ContainerProps extends React.HTMLAttributes<HTMLDivElement> {
  testId?: string
  children: React.ReactNode
}

const ContainerContext = React.createContext<ContainerContextType>({
  ...EMPTY_CONTAINER_MEASUREMENTS,
  ...EMPTY_SIZES
})

export const Container = ({ testId, children, ...props }: ContainerProps) => {
  const [ref, { height, width }] = useMeasure<HTMLDivElement>()

  const value = React.useMemo<ContainerContextType>(
    () => ({
      width,
      height,
      ...getSizes(width)
    }),
    [width, height]
  )

  return (
    <ContainerContext.Provider value={value}>
      <div ref={ref} data-testid={testId} {...props}>
        {children}
      </div>
    </ContainerContext.Provider>
  )
}

export const useContainerSize = () => {
  return React.useContext(ContainerContext)
}

const getSizes = (width: number): Sizes => {
  return {
    isXs: width >= ContainerSize.XS,
    isSm: width >= ContainerSize.SM,
    isMd: width >= ContainerSize.MD,
    isMl: width >= ContainerSize.ML,
    isLg: width >= ContainerSize.LG,
    isXl: width >= ContainerSize.XL,
    isXxl: width >= ContainerSize.XXL
  }
}
