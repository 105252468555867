import { gql } from '@apollo/client'

export const GET_WEBHOOKS = gql`
  query getWebhooks {
    getSubscriptions {
      name
      guid
      uri
      eventCategory {
        guid
        name
        eventSubjectType
      }
      subscriberType
      subscriberGuid
      stopped
      paused
    }
    getPartners {
      guid
      name
    }
    getEventCategories {
      guid
      name
      eventSubjectType
    }
  }
`
