import { gql } from '@apollo/client'

export const CREATE_SUBSCRIPTION = gql`
  mutation createSubscription($newSubscription: NewSubscriptionInput) {
    createSubscription(newSubscription: $newSubscription) {
      name
      guid
      uri
      paused
      stopped
      notificationEmail
      subscriberType
      subscriberGuid
      eventCategory {
        guid
        name
        eventSubjectType
      }
    }
  }
`
