import { useDomainContext } from '@local/current-domain'
import { useAdminPermissionContext } from '@local/manage-webhooks-shared'
import { Button } from '@toasttab/buffet-pui-buttons'
import { EditIcon, PreviewIcon } from '@toasttab/buffet-pui-icons'
import { Tooltip } from '@toasttab/buffet-pui-tooltip'
import { FunctionComponent } from 'react'
import { Link } from 'react-router-dom'

export interface SubscriptionActionsProps {
  guid: string
  subscriptionName: string
}

export const SubscriptionActions: FunctionComponent<
  SubscriptionActionsProps
> = (props) => {
  return (
    <div className='flex flex-row justify-items-stretch'>
      <ViewSubscriptionEventsButton {...props} />
      <EditSubscriptionButton {...props} />
    </div>
  )
}

function ViewSubscriptionEventsButton({
  guid,
  subscriptionName
}: SubscriptionActionsProps) {
  return (
    <Tooltip
      content={() => 'View webhook events'}
      placement='bottom'
      className='w-16 text-center'
    >
      <Button
        as={Link}
        variant='link'
        to={`/events/${guid}`}
        aria-label={`view-subscription-events-${subscriptionName}`}
        data-testid='events-link'
      >
        <PreviewIcon
          className='text-secondary'
          size='sm'
          accessibility='decorative'
        />
      </Button>
    </Tooltip>
  )
}

function EditSubscriptionButton({
  guid,
  subscriptionName
}: SubscriptionActionsProps) {
  const { hasEditPermissions } = useAdminPermissionContext()
  const { isDepotDomain } = useDomainContext()

  if (!hasEditPermissions && !isDepotDomain)
    return (
      <Tooltip
        content={() => 'No permission to edit'}
        placement='bottom'
        className='w-16 text-center'
      >
        <Button
          disabled
          variant='link'
          aria-label={`disabled-edit-subscription-${subscriptionName}`}
          data-testid='disabled-edit-link'
        >
          <EditIcon
            className='text-secondary'
            size='sm'
            accessibility='decorative'
          />
        </Button>
      </Tooltip>
    )

  return (
    <Tooltip
      content={() => 'Edit webhook subscription'}
      placement='bottom'
      className='w-16 text-center'
    >
      <Button
        as={Link}
        variant='link'
        to={`/update/${guid}`}
        aria-label={`edit-subscription-${subscriptionName}`}
        data-testid='edit-link'
      >
        <EditIcon
          className='text-secondary'
          size='sm'
          accessibility='decorative'
        />
      </Button>
    </Tooltip>
  )
}
