import { Default404Page } from '@local/app-routes/Default404Page/Default404Page'
import { EditSubscriptionForm } from '@local/edit-subscription-form'
import { EditSubscriptionPageWrapper } from '@local/edit-subscription-form/EditSubscriptionPageWrapper/EditSubscriptionPageWrapper'
import { FormLoading } from '@local/edit-subscription-form/FormLoading/FormLoading'
import {
  errorNotFoundOrForbidden,
  useSubscription
} from '@local/manage-webhooks-shared'
import { useWebhooksContext } from '@local/manage-webhooks-shared/WebhooksContext/WebhooksContext'
import { Panel } from '@toasttab/buffet-pui-config-templates'
import { ErrorPage500 } from '@toasttab/buffet-pui-error-pages'
import { useParams } from 'react-router-dom'

export function EditSubscriptionPage() {
  const { guid } = useParams()

  const {
    partners,
    eventCategories,
    error: webhooksError
  } = useWebhooksContext()

  const { error, subscription } = useSubscription(guid)

  if (error && errorNotFoundOrForbidden(error)) {
    return (
      <EditSubscriptionPageWrapper>
        <Default404Page />
      </EditSubscriptionPageWrapper>
    )
  }

  if (error || webhooksError) {
    return (
      <EditSubscriptionPageWrapper>
        <ErrorPage500 />
      </EditSubscriptionPageWrapper>
    )
  }

  if (partners && eventCategories && (subscription || !guid))
    return (
      <EditSubscriptionForm
        existingSubscription={subscription}
        partners={partners}
        eventCategories={eventCategories}
      />
    )

  return (
    <EditSubscriptionPageWrapper>
      <Panel>
        <FormLoading />
      </Panel>
    </EditSubscriptionPageWrapper>
  )
}
