import * as React from 'react'
import cx from 'classnames'
import { InfoTooltipProps } from '../InfoTooltip'
import { IconButtonProps } from '@toasttab/buffet-pui-buttons'
import { IconProps, InfoIcon } from '@toasttab/buffet-pui-icons'

export type TooltipTextLayoutProps = React.PropsWithChildren<{
  testId?: string | number
  helperIconButton?:
    | React.FunctionComponentElement<InfoTooltipProps | IconButtonProps>
    | null
    | false
  disabled?: boolean
  className?: string
  defaultIcon?: InfoTooltipProps['icon']
  iconSize?: IconProps['size']
}>

/**
 * [View in Storybook]{@link https://doc.toasttab.com/buffet-storybook/index.html?path=/story/components-feedback-tooltip-tooltiptextlayout--usage}
 */
export const TooltipTextLayout: React.FC<TooltipTextLayoutProps> = ({
  testId = 'TooltipTextLayout',
  children,
  helperIconButton,
  disabled,
  className,
  defaultIcon = <InfoIcon accessibility='decorative' />,
  iconSize = 'xs'
}) => {
  const icon =
    helperIconButton && helperIconButton.props.icon
      ? helperIconButton?.props.icon
      : defaultIcon

  return (
    <div
      data-testid={testId}
      className={cx('flex flex-row items-center', className)}
    >
      {children}
      {helperIconButton && (
        <div className='flex ml-1 md:ml-2 type-caption'>
          {React.cloneElement<InfoTooltipProps | IconButtonProps>(
            helperIconButton,
            {
              iconColor: 'secondary',
              disabled,
              ...helperIconButton?.props,
              icon: React.cloneElement(icon, { size: iconSize, ...icon.props }),
              cropToIcon: true,
              className: cx('flex p-2', helperIconButton?.props?.className)
            }
          )}
        </div>
      )}
    </div>
  )
}
