import * as React from 'react'
import { usePagination } from './usePagination'

export interface UseRowPaginationConfig<P> {
  initialPage?: number
  currentPage?: number
  onChangePage?: (newPage: number) => void
  rows: P[] | ((rowIndex: number, pageSize: number) => P[])
  totalRows?: number
  pageSize: number
}

export const useRowPagination = <P = unknown>({
  rows,
  pageSize,
  ...props
}: UseRowPaginationConfig<P>) => {
  const totalRows = Array.isArray(rows) ? rows.length : props.totalRows!
  const totalPageCount = Math.ceil(totalRows / pageSize)

  const pageData = React.useCallback(
    (currentPage: number) => {
      if (Array.isArray(rows)) {
        return rows.slice(currentPage * pageSize, (currentPage + 1) * pageSize)
      } else {
        return rows(currentPage * pageSize, pageSize)
      }
    },
    [rows, pageSize]
  )

  return usePagination({
    pageData,
    totalPageCount,
    ...props
  })
}
