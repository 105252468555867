import * as React from 'react'
import cx from 'classnames'

export type IconButtonSizes = 'lg' | 'auto' | 'sm' | 'base'
export type IconButtonColors = 'primary' | 'secondary' | 'light'

export interface GetIconButtonStyleProps {
  contained?: boolean
  /**
   * Use cropToIcon to reduce the size of the button in the layout to the icon size.
   * The button will still overflow the icon space, but this can be useful in certain layouts
   * where there is enough space visually for a 48px button, but a parent element provides the space
   */
  cropToIcon?: boolean
  /**
   * Use with cropToIcon to override the negative margin class that is added to the button.
   */
  cropToIconMarginClassName?: string
  disabled?: boolean
  iconColor?: IconButtonColors
  size?: IconButtonSizes
  textClassName?: string
}
interface StyleResult {
  className: string
  style: React.CSSProperties
}
interface GetIconButtonStyleResults {
  // parentClassName?
  button: StyleResult
  icon: StyleResult
}

function getButtonSizeClass(size: IconButtonSizes) {
  switch (size) {
    case 'auto':
      return 'p-1 md:p-0'
    case 'sm':
      return 'p-0'
    case 'base':
    case 'lg':
    default:
      return 'p-1'
  }
}
function getTextClass({
  iconColor,
  disabled,
  textClassName
}: GetIconButtonStyleProps) {
  if (disabled) {
    if (iconColor === 'light') {
      return 'border-transparent bg-transparent cursor-default text-disabled-light'
    }
    return 'border-transparent bg-transparent cursor-default text-disabled'
  }
  if (textClassName) {
    return textClassName
  }
  if (iconColor === 'primary') {
    return 'text-primary-75 hover:text-primary-100 focus-visible:text-primary-100'
  }
  if (iconColor === 'light') {
    return 'text-light'
  }
  return 'text-secondary hover:text-default focus-visible:text-default'
}

export function getIconButtonStyle({
  iconColor = 'primary',
  contained = false,
  cropToIcon = false,
  cropToIconMarginClassName = 'md:-m-2 -m-3',
  disabled = false,
  size = 'auto',
  textClassName
}: GetIconButtonStyleProps = {}): GetIconButtonStyleResults {
  return {
    button: {
      className: cx(
        'outline-none group',
        getButtonSizeClass(size),
        getTextClass({ iconColor, disabled, textClassName }),
        {
          [cropToIconMarginClassName]: cropToIcon
        }
      ),
      style: { lineHeight: '0px' }
    },
    icon: {
      className: cx('p-2', {
        'rounded-full outline-none': !disabled,
        'group-hover:bg-darken-4 group-focus-visible:shadow-focus-inset':
          !disabled && !contained && iconColor !== 'light',
        'group-hover:bg-lighten-20 group-focus-visible:shadow-focus-inset-light':
          !disabled && !contained && iconColor === 'light'
      }),
      style: {}
    }
  }
}
