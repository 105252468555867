import { i18next } from '@toasttab/buffet-pui-translation-utilities'
import { Locales } from '@toasttab/buffet-pui-types'

export { Locales }

export type Locale = (typeof Locales)[number]

const DEFAULT_LOCALE = 'en-US'

/**
 * checkLocale - Checks a given locale against our supported locales. Returns the given locale if it is supported and returns our default locale (en-US) if it is not.
 * returns {Locale}
 */
export const checkLocale = <T extends string>(locale?: T | null): Locale =>
  Locales.find((supportedLocale) => supportedLocale === locale) ||
  DEFAULT_LOCALE

/**
 * getLocale - Get the currently set language within the i18next context
 * @returns {Locale} A Locale value, with en-US being the default if not set
 */
export const getLocale = (): Locale => checkLocale(i18next.language)
