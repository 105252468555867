import * as React from 'react'
import cx from 'classnames'
import { IconWrapper, IconProps, sizes } from '../../IconWrapper'
export const EditIcon = React.forwardRef(function EditIcon(
  props: IconProps,
  ref?: React.ForwardedRef<HTMLElement>
) {
  return (
    <IconWrapper ref={ref} {...props}>
      <>
        {
          <svg
            width={24}
            height={24}
            viewBox='0 0 24 24'
            fill='none'
            className={cx(
              'inline-block align-middle',
              sizes[props.size || 'sm'].className
            )}
          >
            <path
              d='M14.45 5.25l4.3 4.3m-15.1 6.6l12.5-12.6c.8-.8 2.1-.8 2.9 0l1.3 1.3c.8.8.8 2.1 0 2.9l-12.5 12.6-4.2.6c-.3 0-.6-.2-.6-.6l.6-4.2z'
              stroke='currentColor'
              strokeWidth={sizes[props.size || 'sm'].strokeWidth}
              strokeMiterlimit={10}
            />
          </svg>
        }
      </>
    </IconWrapper>
  )
})
