import { FunctionComponent, useState } from 'react'
import { Event } from '@local/manage-webhooks-shared'
import { Button } from '@toasttab/buffet-pui-buttons'

import { EventDetailsModal } from '../EventDetailsModal'

export interface EventDetailsGuidButtonProps {
  event: Event
  onReplay: (eventGuid: string, timestamp: string) => void
}

export const EventDetailsGuidButton: FunctionComponent<
  EventDetailsGuidButtonProps
> = ({ event, onReplay }) => {
  const [isOpen, setIsOpen] = useState(false)

  return (
    <>
      <Button
        variant='link'
        onClick={() => setIsOpen(true)}
        testId={`${event.webhookGuid}-show-details-button`}
      >
        {event.webhookGuid}
      </Button>
      <EventDetailsModal
        isOpen={isOpen}
        onClose={() => setIsOpen(false)}
        event={event}
        onReplay={onReplay}
      />
    </>
  )
}
