import * as React from 'react'
import {
  useDropdownCommon,
  UseDropdownCommonProps
} from '@toasttab/buffet-pui-floating-ui-base'
import { SelectItem } from '../types'
import { itemToValueFn, ItemToValueFn } from '../utils'
import { useSelectAll } from '../useSelectAll'
import { LabelProps } from '@toasttab/buffet-pui-text-base'
import { SelectButtonProps } from '@toasttab/buffet-pui-select-button'

export const selectAllKey = 'select-all-option'

export type UseSelectMultipleProps<
  TItem extends SelectItem<TValue>,
  TValue
> = UseDropdownCommonProps & {
  options?: TItem[]
  itemToValue?: ItemToValueFn<TValue, TItem>
  value?: TValue[]
  onChange: (arg: TValue[]) => void
  enableSelectAll?: boolean
}

export const useSelectMultiple = <TItem extends SelectItem<TValue>, TValue>({
  placement,
  testId = 'select',
  options = [],
  itemToValue = itemToValueFn,
  isCircularKeyboardNav,
  value = [],
  onChange,
  enableSelectAll,
  enableSearch,
  matchReferenceWidth,
  hasLabel,
  ariaLabel,
  ariaLabelledBy,
  disabled,
  onOpenChange,
  onClose
}: UseSelectMultipleProps<TItem, TValue>) => {
  const {
    isOpen,
    setIsOpen,
    activeIndex,
    refs,
    floatingStyles,
    context,
    listRef,
    listContentRef,
    testId: _testId,
    getFloatingProps,
    getReferenceProps,
    getItemProps,
    getLabelProps,
    elements,
    closeMenu
  } = useDropdownCommon<SelectButtonProps, LabelProps>({
    placement,
    testId,
    isCircularKeyboardNav,
    enableSearch,
    matchReferenceWidth,
    hasLabel,
    ariaLabel,
    ariaLabelledBy,
    disabled,
    onOpenChange,
    onClose
  })

  const selectedItems = React.useMemo(() => {
    const findOption = (val?: TValue | null) =>
      options.find((option: TItem) => itemToValue(option) === val)

    return value.map((val) => findOption(val)) as TItem[]
  }, [itemToValue, options, value])

  const optionsToRender = React.useMemo(() => {
    if (enableSelectAll) {
      return [selectAllKey as TItem, ...options]
    }

    return options
  }, [options, enableSelectAll])

  const { handleSelectAll, isAllSelected, onSelectAllClick } = useSelectAll({
    onChange,
    itemToValue,
    options,
    selectedItems
  })

  const handleSelect = (item: TItem) => {
    const selectedValues = selectedItems.map((selectedItem) =>
      itemToValue(selectedItem)
    )

    // we don't know whether the value we're handling is already selected yet
    const currentValue = itemToValue(item)
    const currentValueSelectedIndex = selectedValues.findIndex(
      (selectedValue) => selectedValue === currentValue
    )

    if (
      enableSelectAll &&
      currentValue === (selectAllKey as unknown as TValue)
    ) {
      onSelectAllClick()
    } else if (currentValueSelectedIndex >= 0) {
      selectedValues.splice(currentValueSelectedIndex, 1)
      onChange(selectedValues)
    } else {
      selectedValues.push(currentValue)
      onChange(selectedValues)
    }
  }

  return {
    isOpen,
    setIsOpen,
    activeIndex,
    refs,
    floatingStyles,
    context,
    listRef,
    listContentRef,
    selectedItems,
    handleSelect,
    handleSelectAll,
    testId: _testId,
    getFloatingProps,
    getReferenceProps,
    getItemProps,
    getLabelProps,
    isAllSelected,
    onSelectAllClick,
    optionsToRender,
    elements,
    closeMenu
  }
}
