/**
 * We assume that the Page layout component exists inside of a layout
 * spa that ensures only one scrollable element.
 * Multiple scrollable ancestors is such a bad user experience we'd presumably
 * deal with that by fixing the layout spa.
 */
export function getScrollableAncestor(node: HTMLElement): Element | null {
  // For layouts like restaurant-admin-layout
  // we can make a decision quickly (maybe!)
  if (
    document.scrollingElement &&
    document.scrollingElement.scrollHeight !==
      document.scrollingElement.clientHeight
  ) {
    return document.scrollingElement
  }

  // For layouts like spa-toast-administration that have to deal with
  // iframes and thus have more complex layouts
  // for which a non-root element is the scrollable element
  return getScrollableAncestorRecursive(node)
}

function isScrollable(node: Element): boolean {
  if (node.scrollHeight === node.clientHeight) {
    return false
  }
  const overflowY = window.getComputedStyle(node).getPropertyValue('overflow-y')
  return overflowY === 'auto' || overflowY === 'scroll'
}

function getScrollableAncestorRecursive(node: Element | null): Element | null {
  if (node == null) {
    // only for browsers that don't support document.scrollingElement presumably
    return null
  }
  if (isScrollable(node)) {
    return node
  }
  return getScrollableAncestorRecursive(node.parentElement)
}
