import * as React from 'react'
import cx from 'classnames'
import { TestIdentifiable } from '@toasttab/buffet-shared-types'

export interface PaginationControlProps extends TestIdentifiable {
  children: React.ReactNode
  disabled?: boolean
  /** This is a special disabled state */
  isPlaceholder?: boolean
  active?: boolean
  onClick: () => void
  ariaLabel?: string
}

export const PaginationControl = ({
  children,
  disabled,
  isPlaceholder,
  active,
  onClick,
  ariaLabel,
  testId
}: PaginationControlProps) => {
  const textColor = isPlaceholder
    ? 'text-secondary'
    : disabled
    ? 'text-disabled'
    : active
    ? 'text-default'
    : 'text-secondary'

  return (
    <button
      data-testid={testId}
      type='button'
      disabled={disabled}
      onClick={() => onClick()}
      aria-current={active}
      aria-label={
        active && ariaLabel ? `Current Page, ${ariaLabel}` : ariaLabel
      }
      className={cx(
        'rounded-full px-2 h-10',
        // this is necessary to get the icon to center correctly in chrome
        'flex items-center justify-center',
        'outline-none focus-visible:shadow-focus-inset',
        textColor,
        {
          'font-bold': active,
          'cursor-default': disabled,
          'hover:bg-darken-4': !disabled
        }
      )}
      style={{ minWidth: '2.5rem' }}
    >
      {children}
    </button>
  )
}
