import { EventStatusFilterOptions } from '@local/manage-webhooks-shared'

export const eventStatusFilterOptions: {
  value: EventStatusFilterOptions
  label: string
}[] = [
  { value: 'ALL', label: 'All' },
  { value: 'SENT_SUCCESS', label: 'Success' },
  { value: 'SENT_PENDING_RESPONSE', label: 'Pending' },
  { value: 'NOT_SENT', label: 'Not sent' },
  { value: 'SENT_FAIL', label: 'Failure' }
]
