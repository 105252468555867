import { Alert } from '@toasttab/buffet-pui-alerts'
import { TextareaField } from '@toasttab/buffet-pui-forms'

export function WebhookURLField({
  newSubscription
}: {
  newSubscription: boolean
}) {
  return (
    <>
      <TextareaField
        name='uri'
        label='Webhook URL'
        required
        testId='uri-field'
        helperText={<WebhookURLHelperText />}
      />
      {newSubscription && (
        <Alert>
          The Toast platform offers optional message signing that allows you to
          validate the source of data sent to your webhook endpoint. Read more
          about message signing{' '}
          <a
            href='https://doc.toasttab.com/doc/devguide/apiMessageSigning.html'
            target='_blank'
            rel='noreferrer'
            className='underline'
          >
            here
          </a>
          .
        </Alert>
      )}
    </>
  )
}

function WebhookURLHelperText() {
  return (
    <div>
       Updates are sent to this URL.{' '}
      <a
        className='underline text-link'
        href='https://doc.toasttab.com/doc/devguide/apiWebhookBasics.html'
        target='_blank'
        rel='noreferrer'
      >
        Learn more
      </a>
    </div>
  )
}
