import { useState, useEffect, useRef } from 'react'

/**
 * Rough explanation of each ScreenSize
 * | Key | Index | Media query | Device category |
 * |-----|-------|-------------|-----------------|
 * | ScreenSize.XS  | 0 | (max-width: 640px) | Mobile in portrait |
 * | ScreenSize.SM  | 1 | (min-width: 640px) | Tablets in portrait/Mobile in landscape |
 * | ScreenSize.MD  | 2 | (min-width: 768px) | Tablets in landscape/Small laptops |
 * | ScreenSize.LG  | 3 | (min-width: 1024px) | Laptops |
 * | ScreenSize.XL  | 4 | (min-width: 1280px) | Large laptops/Desktops |
 * | ScreenSize.XXL  | 5 | (min-width: 1366px) | Desktops |
 */
export enum ScreenSize {
  // eslint-disable-next-line no-unused-vars
  XS = 0,
  // eslint-disable-next-line no-unused-vars
  SM = 1,
  // eslint-disable-next-line no-unused-vars
  MD = 2,
  // eslint-disable-next-line no-unused-vars
  LG = 3,
  // eslint-disable-next-line no-unused-vars
  XL = 4,
  // eslint-disable-next-line no-unused-vars
  XXL = 5
}

const breakpoints: Array<number> = [640, 768, 1024, 1280, 1366]
const mediaQueries: Array<string> = breakpoints.map(
  (bp) => `(min-width: ${bp}px)`
)

/**
 * addListener and removeListener are deprecated in favor of their EventTarget
 * alternative, addEventListener and removeEventListener, but they are not
 * supported by old browsers.
 * https://developer.mozilla.org/en-US/docs/Web/API/MediaQueryList/addListener
 */
const addEventListeners = (
  mqls: Array<MediaQueryList>,
  action: (e: MediaQueryListEvent) => void
) =>
  mqls.forEach((mql) => {
    mql.addEventListener('change', action)
  })
const removeEventListeners = (
  mqls: Array<MediaQueryList>,
  action: (e: MediaQueryListEvent) => void
) =>
  mqls.forEach((mql) => {
    mql.removeEventListener('change', action)
  })

type ReachedBreakpointState = Record<string, boolean>

/**
 * Just call the hook and it will return how many breakpoints your app reaches:
 * ```ts
 * import { useScreenSize, ScreenSize } from '@toasttab/use-screen-size'
 *
 * const result = useScreenSize()
 *
 * if(result < ScreenSize.MD) {
 *   // Do something for
 * } else {
 *   // Do something else
 * }
 * ```
 */
export const useScreenSize = (): ScreenSize => {
  const mediaQueriesListener = useRef<Array<MediaQueryList>>(
    mediaQueries.map((mq) => window.matchMedia(mq))
  )
  const [reachedBreakpoint, setReachedBreakpoint] =
    useState<ReachedBreakpointState>(() =>
      mediaQueriesListener.current.reduce(
        (acc, { media, matches }) => Object.assign(acc, { [media]: matches }),
        {}
      )
    )

  useEffect(() => {
    const { current } = mediaQueriesListener
    const callback = ({ media, matches }: MediaQueryListEvent) => {
      setReachedBreakpoint((prevBreakpoints) => ({
        ...prevBreakpoints,
        [media]: matches
      }))
    }
    addEventListeners(current, callback)

    return () => removeEventListeners(current, callback)
  }, [])

  return Object.values(reachedBreakpoint).filter((value) => !!value).length
}
