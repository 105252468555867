import { ContainerMeasurements, Sizes } from './types'

// Note: These sizes mirror the values from tailwind (with the addition
// of XS and ML) but if your use case doesn't fall under these values, you
// can use the measurements width to check for any size.
export enum ContainerSize {
  XS = 480,
  SM = 640,
  MD = 768,
  ML = 980,
  LG = 1024,
  XL = 1280,
  XXL = 1366
}

export const EMPTY_CONTAINER_MEASUREMENTS: ContainerMeasurements = {
  height: 0,
  width: 0
}

export const EMPTY_SIZES: Sizes = {
  isXs: false,
  isSm: false,
  isMd: false,
  isMl: false,
  isLg: false,
  isXl: false,
  isXxl: false
}
