/* Locale date strings for es locale */
import { OptionalFormats } from './default'

export const es: OptionalFormats = {
  date_short_without_year: 'd/M',
  date_shorter: 'd/M/yy',
  date_short: 'd/M/yyyy',
  date_medium_without_year: 'd MMM',
  date_long: 'PPP',
  date_long_without_days: "MMMM 'de' yyyy",
  date_long_without_year: "d 'de' MMMM",
  time_shorter: 'h aaaa',
  time_short: 'h:mm aaaa',
  time_medium: 'h:mm:ss aaaa',
  time_long: 'h:mm:ss aaaa O',
  time_full: 'h:mm:ss aaaa OOOO',
  dateTime_shorter: 'd/M/yy, h aaaa',
  dateTime_short: 'dd/MM/yyyy, h:mm aaaa',
  dateTime_short_24: 'dd/MM/yyyy, HH:mm',
  dateTime_medium: 'PP, h:mm aaaa',
  dateTime_medium_with_seconds: 'PP, h:mm:ss aaaa',
  dateTime_long: "d 'de' MMMM 'de' yyyy h:mm:ss aaaa O",
  dateTime_full: "d 'de' MMMM 'de' yyyy h:mm:ss aaaa OOOO"
}
