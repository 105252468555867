import { EventCategoriesList } from '@local/event-categories-list'
import { EventsPage } from '@local/events-list'
import { ToastAdminWebhooksContextProvider } from '@local/manage-webhooks-shared/WebhooksContext/WebhooksContext'
import { BrowserRouter, Navigate, Route, Routes } from 'react-router-dom'
import { SearchEventsPage } from '@local/events-list/SearchEventsPage/SearchEventsPage'

import { EditSubscriptionPage } from '../../../src/components/EditSubscriptionPage/EditSubscriptionPage'
import { SubscriptionsPage } from '../../../src/components/SubscriptionsPage/SubscriptionsPage'
import { PageWithTabs, TabContent } from '../PageWithTabs/PageWithTabs'

export function ToastAdminRoutes() {
  return (
    <ToastAdminWebhooksContextProvider>
      <BrowserRouter basename='/toast/admin/webhooks'>
        <Routes>
          <Route path='/' element={<Navigate to='/subscriptions' replace />} />
          <Route
            key={`tab-route-${tabContents[0].id}`}
            path={`/${tabContents[0].id}`}
            element={
              <PageWithTabs tabContents={tabContents} pageTitle='Webhooks' />
            }
          />
          <Route
            key={`tab-route-${tabContents[1].id}`}
            path={`/${tabContents[1].id}`}
            element={
              <PageWithTabs tabContents={tabContents} pageTitle='Webhooks' />
            }
          />
          <Route
            key={`tab-route-${tabContents[2].id}`}
            path={`/${tabContents[2].id}/:partition?/:sort?`}
            element={
              <PageWithTabs tabContents={tabContents} pageTitle='Webhooks' />
            }
          />
          <Route path='/create' element={<EditSubscriptionPage />} />
          <Route path='/update/:guid' element={<EditSubscriptionPage />} />
          <Route path='/events/:guid' element={<EventsPage />} />
        </Routes>
      </BrowserRouter>
    </ToastAdminWebhooksContextProvider>
  )
}

const tabContents: TabContent[] = [
  {
    id: 'subscriptions',
    title: 'Subscriptions',
    node: <SubscriptionsPage />
  },
  {
    id: 'event-categories',
    title: 'Event categories',
    node: <EventCategoriesList />
  },
  {
    id: 'event-search',
    title: 'Event search',
    node: <SearchEventsPage />
  }
]
