import { EventCategory } from './eventCategory.type'

export enum SubscriptionType {
  Partner = 'partner'
}

export interface Subscription {
  name: string
  guid?: string
  subscriberType?: SubscriptionType
  subscriberGuid?: string
  uri?: string
  notificationEmail?: string
  stopped?: boolean
  paused?: boolean
  eventCategory?: EventCategory
}

export type SavedSubscription = Required<Subscription> & {
  __typename: 'Subscription'
}

export type NewSubscription = {
  guid?: string
  name: string
  subscriberType: SubscriptionType
  subscriberGuid: string
  uri: string
  notificationEmail?: string
  stopped?: boolean
  paused?: boolean
  eventCategory: { guid: string }
}
