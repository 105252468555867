import { useState, useCallback, FocusEventHandler, FocusEvent } from 'react'

export const useFocus = ({
  defaultState = false,
  onBlur = () => {},
  onFocus = () => {}
}: {
  defaultState?: boolean
  onBlur?: FocusEventHandler<HTMLInputElement>
  onFocus?: FocusEventHandler<HTMLInputElement>
} = {}): {
  focused: boolean
  onBlur?: FocusEventHandler<HTMLInputElement>
  onFocus?: FocusEventHandler<HTMLInputElement>
} => {
  const [focused, setFocused] = useState(defaultState)

  const handleOnBlur = useCallback(
    (e: FocusEvent<HTMLInputElement>) => {
      onBlur(e)
      setFocused(false)
    },
    [onBlur]
  )
  const handleOnFocus = useCallback(
    (e: FocusEvent<HTMLInputElement>) => {
      onFocus(e)
      /** We use a focus-visible polyfill that adds this class
       * see https://github.com/WICG/focus-visible
       */
      setFocused(e.currentTarget?.classList.contains('focus-visible'))
    },
    [onFocus]
  )
  return { focused, onFocus: handleOnFocus, onBlur: handleOnBlur }
}
