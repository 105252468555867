import { useLazyQuery } from '@apollo/client'
import { useSnackBar } from '@toasttab/buffet-pui-snackbars'
import { useSentry } from 'banquet-runtime-modules'
import { SetStateAction } from 'react'

import { GET_SUBSCRIPTION_SECRET } from './GET_SUBSCRIPTION_SECRET'

export function useToastAdminGetSubscriptionSecret(
  guid: string,
  setSecret: (value: SetStateAction<string | undefined>) => void
) {
  const { hub } = useSentry()
  const { showErrorSnackBar } = useSnackBar()

  const [getSecret, { loading: fetchingSecret }] = useLazyQuery(
    GET_SUBSCRIPTION_SECRET,
    {
      variables: { guid },
      onCompleted: (data) => setSecret(data.getSubscriptionSecret.secret),
      onError: (error) => {
        hub.captureException(error)
        showErrorSnackBar(
          "There was an error fetching this subscription's secret",
          {
            testId: 'secret-error-snackbar'
          }
        )
      }
    }
  )

  return {
    getSecret,
    fetchingSecret
  }
}
