import { HOST_DOMAIN, useDomainContext } from '@local/current-domain'
import { ErrorPage404 } from '@toasttab/buffet-pui-error-pages'
import { HomeIcon, WebhookIcon } from '@toasttab/buffet-pui-icons'

export function Default404Page() {
  const { hostDomain } = useDomainContext()

  return (
    <ErrorPage404
      links={[
        {
          href: homeRouteMap[hostDomain],
          icon: <HomeIcon accessibility='decorative' />,
          label: 'Home'
        },
        {
          href: webhooksRouteMap[hostDomain],
          icon: <WebhookIcon aria-label='webhook-page' />,
          label: 'Webhooks'
        }
      ]}
    />
  )
}

const homeRouteMap: { [domain in HOST_DOMAIN]: string } = {
  [HOST_DOMAIN.DEPOT]: '/',
  [HOST_DOMAIN.TOASTWEB]: '/toast/admin'
}

const webhooksRouteMap: { [domain in HOST_DOMAIN]: string } = {
  [HOST_DOMAIN.DEPOT]: '/webhooks',
  [HOST_DOMAIN.TOASTWEB]: '/toast/admin/webhooks'
}
