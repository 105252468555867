import { Header, flexRender } from '@tanstack/react-table'
import { HeadingCell } from '@toasttab/buffet-pui-table'

import { TableHeadingCellSortIndicator } from '../TableHeadingCellSortIndicator'

export function BuffetHeadingCell<T>({
  header
}: Readonly<{
  header: Header<T, unknown>
}>) {
  return (
    <HeadingCell
      testId={`data-table-${header.id}-heading`}
      className={header.column.columnDef.meta?.className}
      isSorted={!!header.column.getIsSorted()}
      {...(header.column.getCanSort() && {
        role: 'button',
        tabIndex: 0,
        title: `Toggle sort by ${header.column.columnDef?.header}`,
        onClick: header.column.getToggleSortingHandler(),
        onKeyDown: (e) => {
          if (e.key === 'Enter') {
            header.column.toggleSorting()
          }
        }
      })}
    >
      {header.isPlaceholder
        ? null
        : flexRender(header.column.columnDef.header, header.getContext())}
      <TableHeadingCellSortIndicator header={header} />
    </HeadingCell>
  )
}
