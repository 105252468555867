import { Button } from '@toasttab/buffet-pui-buttons'
import { Panel } from '@toasttab/buffet-pui-config-templates'
import { EmptyState } from '@toasttab/buffet-pui-empty-state'
import { LookupInfoIcon } from '@toasttab/buffet-pui-icons'

export function NoWebhooksMatchPanel({
  handleClearSearch
}: {
  handleClearSearch: () => void
}) {
  return (
    <Panel>
      <EmptyState
        testId='no-webhooks-match-empty-state'
        title='No webhooks match your search'
        className='my-10'
        icon={<LookupInfoIcon accessibility='decorative' />}
        button={<Button onClick={handleClearSearch}>Clear search</Button>}
      >
        Try updating or clearing your search.
      </EmptyState>
    </Panel>
  )
}

export function NoEventEmptyState() {
  return (
    <Panel>
      <EmptyState
        testId='no-event-found-empty-state'
        title='No events found'
        className='my-10'
        icon={<LookupInfoIcon accessibility='decorative' />}
      >
        Events will show up once webhook is triggered.
      </EmptyState>
    </Panel>
  )
}

export function NoEventMatchFilterEmptyState({
  handleClearSearch
}: {
  handleClearSearch: () => void
}) {
  return (
    <Panel>
      <EmptyState
        testId='no-event-found-empty-state'
        title='No events found'
        className='my-10'
        icon={<LookupInfoIcon accessibility='decorative' />}
        button={<Button onClick={handleClearSearch}>Clear filters</Button>}
      >
        Try updating or clearing your filters.
      </EmptyState>
    </Panel>
  )
}
