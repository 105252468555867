import { SavedSubscription } from '@local/manage-webhooks-shared'
import { Panel } from '@toasttab/buffet-pui-config-templates'
import { Tooltip } from '@toasttab/buffet-pui-tooltip'
import cx from 'classnames'

import { SecretSection } from '../SecretSection/SecretSection'
import { useMappedSubscriptionDetailFields } from '../useMappedSubscriptionDetailFields'

export function SubscriptionDetails({
  subscription
}: {
  subscription: SavedSubscription
}) {
  const mappedFields = useMappedSubscriptionDetailFields(subscription)

  return (
    <Panel className='xxl:pt-8 xxl:pb-8'>
      <div className='grid grid-cols-3 gap-2 gap-y-4'>
        {mappedFields?.map(({ label, value }, idx) => {
          const valueWithPlaceholder = value ? value : 'N/A'
          return (
            <div
              key={label}
              className={cx((idx + 1) % 3 && 'border-r-2 border-slate-50')}
            >
              <div className='font-bold'>{label}</div>
              <Tooltip accessibility='decorative' placement='bottom'>
                <Tooltip.Trigger
                  className='truncate inline-block pr-2 max-w-full'
                  tabIndex={0}
                >
                  {valueWithPlaceholder}
                </Tooltip.Trigger>
                <Tooltip.Content className='break-all'>
                  {valueWithPlaceholder}
                </Tooltip.Content>
              </Tooltip>
              <div></div>
            </div>
          )
        })}
        <SecretSection guid={subscription.guid} />
      </div>
    </Panel>
  )
}
