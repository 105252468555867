import * as React from 'react'
import cx from 'classnames'
import { useUniqueId } from '@toasttab/buffet-utils'

const directions = {
  horizontal: 'horizontal',
  vertical: 'vertical',
  none: 'none'
} as const

const buttonSizes = {
  lg: 'lg',
  base: 'base', // deprecated in favor of 'lg'
  sm: 'sm',
  auto: 'auto'
} as const

type ButtonPrototype = {
  displayName: string
  direction: typeof directions
  buttonSize: typeof buttonSizes
}

type ButtonGroupProps = {
  direction?: keyof typeof directions
  testId?: string | number
  buttonSize?: keyof typeof buttonSizes
} & JSX.IntrinsicElements['div']

export const ButtonGroup: React.FC<React.PropsWithChildren<ButtonGroupProps>> &
  ButtonPrototype = ({
  direction = 'horizontal',
  testId,
  buttonSize = 'auto',
  ...rest
}) => {
  const { children, className, ...props } = rest
  testId = useUniqueId(testId, 'button-')
  return (
    <div
      className={cx(
        {
          'flex space-x-2': direction === 'horizontal' && buttonSize === 'sm'
        },
        {
          'flex flex-col space-y-2':
            direction === 'vertical' && buttonSize === 'sm'
        },
        {
          'flex space-x-4':
            direction === 'horizontal' &&
            (buttonSize === 'base' || buttonSize === 'lg')
        },
        {
          'flex flex-col space-y-4':
            direction === 'vertical' &&
            (buttonSize === 'base' || buttonSize === 'lg')
        },
        {
          'flex space-x-4 md:space-x-2':
            direction === 'horizontal' && buttonSize === 'auto'
        },
        {
          'flex flex-col space-y-4 md:space-y-2':
            direction === 'vertical' && buttonSize === 'auto'
        },
        className
      )}
      data-testid={testId}
      {...props}
    >
      {children}
    </div>
  )
}

ButtonGroup.displayName = 'ButtonGroup'
ButtonGroup.direction = Object.freeze(directions)
ButtonGroup.buttonSize = Object.freeze(buttonSizes)
