import * as React from 'react'
import cx from 'classnames'
import { TestIdentifiable } from '@toasttab/buffet-shared-types'

export interface SettingGroupHeaderProps extends TestIdentifiable {
  title?: string
  subtitle?: React.ReactNode
  className?: string
}

export const SettingGroupHeader = ({
  testId,
  title,
  subtitle,
  className
}: SettingGroupHeaderProps) => {
  return (
    <header className={className} data-testid={testId}>
      {title && (
        <h3
          className={cx('font-bold type-headline-5 m-0', {
            'mb-2': subtitle
          })}
        >
          {title}
        </h3>
      )}
      {subtitle && (
        <div className={cx('type-default text-secondary')}>{subtitle}</div>
      )}
    </header>
  )
}
