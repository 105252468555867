import { ApolloError, useMutation } from '@apollo/client'
import { useSentry } from 'banquet-runtime-modules'

import { GET_WEBHOOKS } from '../../graphql-toast-admin/useToastAdminWebhooks/GET_WEBHOOKS'
import { DELETE_SUBSCRIPTION } from '../mutations'

export function useDeleteSubscription({
  onDelete,
  onError
}: {
  onDelete?: () => void
  onError?: () => void
}) {
  const { hub } = useSentry()

  const [deleteSubscription, { loading: deleteLoading, error: deleteError }] =
    useMutation<{ deleteSubscription: boolean }>(DELETE_SUBSCRIPTION, {
      onCompleted: (response) => {
        if (!response.deleteSubscription) {
          throw new ApolloError({
            errorMessage: 'Delete failed'
          })
        }
        onDelete && onDelete()
      },
      onError: (error) => {
        hub.captureException(error)
        onError && onError()
      },
      awaitRefetchQueries: true,
      refetchQueries: [GET_WEBHOOKS]
    })

  return {
    deleteSubscription,
    deleteLoading,
    deleteError
  }
}
