import { useDomainContext } from '@local/current-domain'
import { Page } from '@toasttab/buffet-pui-config-templates'
import { patternGeneralIllustrationDataUrl } from '@toasttab/buffet-pui-illustrations'
import { PropsWithChildren } from 'react'

export function PageWrapper({ children }: PropsWithChildren) {
  const { isDepotDomain } = useDomainContext()
  return (
    <Page
      style={
        isDepotDomain
          ? {
              minHeight: 'calc(100vh - 4rem)',
              backgroundImage: patternGeneralIllustrationDataUrl
            }
          : undefined
      }
    >
      {children}
    </Page>
  )
}
