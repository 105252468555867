import { FunctionComponent } from 'react'
import { Badge } from '@toasttab/buffet-pui-badge'

import { EventStatus } from '../../types'
import { getStatusBadgeFromEventStatus } from '../utils/getStatusBadgeFromEventStatus'

export interface EventStatusBadgeProps {
  status: EventStatus
}

export const EventStatusBadge: FunctionComponent<EventStatusBadgeProps> = ({
  status
}) => {
  const badgeProps = getStatusBadgeFromEventStatus(status)
  return <Badge color={badgeProps.color}>{badgeProps.message}</Badge>
}
