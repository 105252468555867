import * as yup from 'yup'

import { combineDateTimePoint } from '../utils'

export const eventsFiltersFormSchema = yup.object().shape({
  startDate: yup.date(),
  endDate: yup
    .date()
    .nullable()
    .when(['startDate'], ([startDate], schema) => {
      if (startDate) {
        return schema.min(startDate, 'End date must be after start date')
      }
      return schema
    }),
  startTimeField: yup
    .object()
    .nullable()
    .shape({
      isPm: yup.bool().required('AM/PM is required'),
      hours: yup
        .number()
        .typeError('Hours must be a number')
        .required('Hours must be a number'),
      minutes: yup
        .number()
        .typeError('Minutes must be a number')
        .required('Minutes must be a number')
    }),
  endTimeField: yup
    .object()
    .nullable()
    .shape({
      isPm: yup.bool().required('AM/PM is required'),
      hours: yup
        .number()
        .typeError('Hours must be a number')
        .required('Hours must be a number'),
      minutes: yup
        .number()
        .typeError('Minutes must be a number')
        .required('Minutes must be a number')
    })
    .when(
      ['startDate', 'endDate', 'startTimeField'],
      ([startDate, endDate, startTimeField], schema) => {
        if (startDate && endDate && startDate) {
          return schema.test(
            'valid datetime period',
            'End time must be after start time',
            (endTimeField) => {
              const startDateTime = combineDateTimePoint(
                startDate,
                startTimeField
              )
              const endDateTime = combineDateTimePoint(endDate, endTimeField)
              if (!startDateTime || !endDateTime) return true
              return startDateTime <= endDateTime
            }
          )
        }
        return schema
      }
    )
})
