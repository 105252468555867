import React from 'react'

/**
 * This Hook issues a warning if using a deprecated prop in the props and suggest the alternative
 * @param props Component props
 * @param deprecated Name of deprecated prop
 * @param alternative Name of favored prop
 */
export const useDeprecationWarning = <T extends Record<string, any>>(
  props: T,
  deprecated: keyof T,
  alternative: keyof T
) => {
  const [warned, setWarned] = React.useState(false)

  const deprecatedProp = props[deprecated]

  React.useEffect(() => {
    if (!warned && deprecatedProp !== undefined && shouldLogWarning()) {
      console.warn(
        `${
          deprecated as string
        } has been deprecated and will be removed in a future version. Use ${
          alternative as string
        } instead`
      )
      setWarned(true)
    }
  }, [deprecatedProp, alternative, warned, deprecated])
}

/**
 * This Hook issues a warning if the value for a given prop name is equal to a deprecated value
 * and proposes using the alternative value
 */
export const usePropValueDeprecationWarning = ({
  name,
  value,
  deprecatedValue,
  alternativeValue
}: {
  name: string
  value: any
  deprecatedValue: any
  alternativeValue: any
}) => {
  const [warned, setWarned] = React.useState(false)

  React.useEffect(() => {
    if (!warned && value === deprecatedValue && shouldLogWarning()) {
      console.warn(
        `${name}=${deprecatedValue} has been deprecated and will be removed in a future version. Use ${name}=${alternativeValue} instead`
      )
      setWarned(true)
    }
  }, [name, value, deprecatedValue, alternativeValue, warned])
}

export const shouldLogWarning = () =>
  ['development', 'test'].includes(
    // adding ts-ignore so we don't have to include node types
    //@ts-ignore
    process.env.NODE_ENV ?? ''
  )
