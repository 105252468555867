import * as React from 'react'
import cx from 'classnames'
import { VerticalEdgeTypes, getRoundingStyles, getShadowStyles } from '../utils'

export interface CardContainerProps
  extends React.HtmlHTMLAttributes<HTMLDivElement> {
  /**
   * Determines if shadow should be applied.
   */
  noElevation?: boolean
  /**
   * Determines if the inner padding should be applied.
   */
  noPadding?: boolean
  testId?: string | number
  /**
   * Determines which kind of border/shadow is applied.
   * flush = border/shadow goes straight to the edge of the screen
   * rounded = border/shadow is rounded
   * auto = border/shadow is flush up to 768px, then rounded
   */
  verticalEdge?: VerticalEdgeTypes
}

export const paddingStyles = cx(
  'xxl:card-padding-xxl',
  'md:card-padding-md',
  'card-padding'
)

export const CardContainer: React.FC<
  React.PropsWithChildren<CardContainerProps>
> = ({
  className,
  children,
  noElevation = false,
  noPadding = false,
  testId,
  verticalEdge = 'auto-md',
  ...props
}) => (
  <section
    className={cx(
      'card-base',
      { 'card-border': noElevation },
      { [paddingStyles]: !noPadding },
      getRoundingStyles(verticalEdge),
      // noElevation card doesn't have shadow
      !noElevation && getShadowStyles(verticalEdge),
      className
    )}
    data-testid={testId}
    {...props}
  >
    {children}
  </section>
)
