import { useFloatingParentNodeId } from '@floating-ui/react'

const ELEVATION_FULL_SCREEN_LAYOUT = 'z-30'
const ELEVATION_FLOATING_COMPONENT = 'z-40'
const ELEVATION_NESTED_FLOATING_COMPONENT = 'z-50'

/**
 * This simple hook helps us manage floating components that need to be elevated an extra amount in certain circumstances.
 *
 * When a floating component (such as a select or dropdown) is inside another floating component (such as a modal) it
 * needs to be elevated from z-40 to z50.
 *
 * But at present we only want to do that for nesting inside modal and dropdown containers. We _don't_ want to do the
 * extra elevation if the parent is a full screen layout such as a focus view or wizard template (because a full screen
 * layout can contain a modal which can in turn contain a floating component).
 */
export const useFloatingComponentZIndexClass = () => {
  // See https://floating-ui.com/docs/floatingtree#hooks
  const parentPortalId = useFloatingParentNodeId()
  if (
    !!parentPortalId &&
    !parentPortalId.endsWith(ELEVATION_FULL_SCREEN_LAYOUT)
    // We prepend a `:z30` to the floating parent node for full screen layouts so that we know not to boost.
    // We do this via the `elevation` prop on the `PortalManagement` component (see FocusView.tsx for an example)
  ) {
    return ELEVATION_NESTED_FLOATING_COMPONENT
  }
  return ELEVATION_FLOATING_COMPONENT
}
