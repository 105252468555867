import React, { createContext } from 'react'
import { SnackBarOptions, SnackBarDetails } from './SnackBarProvider'

export interface ProviderContext {
  showSuccessSnackBar: (
    message: SnackBarDetails['message'],
    options?: SnackBarOptions
  ) => void
  showErrorSnackBar: (
    message: SnackBarDetails['message'],
    options?: SnackBarOptions
  ) => void
  showSnackBar: (
    message: SnackBarDetails['message'],
    options?: SnackBarOptions
  ) => void
  closeSnackBar: (key: React.Key) => void
  snackBarOffsetRef: any
}

// @ts-ignore
export const SnackBarContext = createContext<ProviderContext>()
