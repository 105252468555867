import * as React from 'react'
import {
  TooltipTextLayout,
  TooltipTextLayoutProps
} from '@toasttab/buffet-pui-tooltip'
import cx from 'classnames'

export interface LegendProps
  extends React.LabelHTMLAttributes<HTMLLegendElement> {
  name?: string
  subLabel?: React.ReactNode
  disabled?: boolean
  className?: string
  required?: boolean
  helperIconButton?: TooltipTextLayoutProps['helperIconButton']
}

/**
 * [View in Storybook]{@link https://doc.toasttab.com/buffet-storybook/index.html?path=/story/abstract-base-text-base-legend--legend-usage}
 */
export const Legend: React.FC<LegendProps> = ({
  helperIconButton,
  children,
  subLabel,
  ...props
}) => {
  const legendText = <BaseLegend {...props}>{children}</BaseLegend>
  const wrappedLegendText = helperIconButton ? (
    <TooltipTextLayout
      helperIconButton={helperIconButton}
      disabled={props.disabled}
      iconSize='xs'
    >
      {legendText}
    </TooltipTextLayout>
  ) : (
    <div>{legendText}</div>
  )

  // When you use legend, it must be the first child of a fieldset.
  return (
    <legend className='m-0'>
      {wrappedLegendText}
      {subLabel && (
        <div
          className={cx('mt-1 type-default', {
            'text-secondary': !props.disabled,
            'text-disabled': props.disabled
          })}
        >
          {subLabel}
        </div>
      )}
    </legend>
  )
}

const BaseLegend: React.FC<LegendProps> = ({
  children,
  name,
  disabled,
  className,
  required,
  ...props
}) => {
  return (
    <div
      className={cx(
        'inline-flex flex-row font-semibold type-default',
        disabled ? 'text-disabled' : 'text-default',
        className
      )}
      {...props}
    >
      {required && (
        <span
          className={cx('mr-0.5', disabled ? 'text-disabled' : 'text-error')}
        >
          *
        </span>
      )}
      {children}
    </div>
  )
}
