import { FunctionComponent, useMemo } from 'react'
import {
  EventCategory,
  Subscription,
  getTableRows
} from '@local/manage-webhooks-shared'
import { Body, Head, HeadingCell, Row, Table } from '@toasttab/buffet-pui-table'
import { Column, useTable } from 'react-table'

import { countSubscriptionsWithEventCategory } from '../utils/countSubscriptionsWithEventCategory'

export interface EventCategoriesTableProps {
  eventCategories: EventCategory[]
  subscriptions: Subscription[]
}

export const EventCategoriesTable: FunctionComponent<
  EventCategoriesTableProps
> = ({ eventCategories, subscriptions }) => {
  const columns: Column<any>[] = useMemo(
    () => [
      {
        Header: 'Name',
        accessor: 'name',
        id: 'event-category-table-name'
      },
      {
        Header: 'Type',
        accessor: 'type',
        id: 'event-category-table-type'
      },
      {
        Header: '# of subscriptions',
        accessor: 'subscriptions',
        id: 'event-category-table-subscriptions'
      }
    ],
    []
  )

  const data = useMemo(() => {
    return eventCategories.map((eventCategory) => ({
      name: eventCategory.name,
      type: eventCategory.eventSubjectType,
      subscriptions: countSubscriptionsWithEventCategory(
        subscriptions,
        eventCategory
      )
    }))
  }, [eventCategories, subscriptions])

  const { headers, rows, prepareRow } = useTable({
    columns,
    data
  })
  return (
    <Table
      className='table table-striped table-bordered table-fixed'
      testId='event-categories-table'
    >
      <Head>
        <Row>
          {headers.map((column: any) => {
            return (
              <HeadingCell
                key={column.id}
                testId={`${column.id}-heading`}
                className='type-default text-secondary'
              >
                {column.render('Header')}
              </HeadingCell>
            )
          })}
        </Row>
      </Head>
      <Body>
        {getTableRows(rows, prepareRow, columns, 'event-category-row')}
      </Body>
    </Table>
  )
}
