import { useDomainContext } from '@local/current-domain'
import { EventCategory } from '@local/manage-webhooks-shared'
import { Alert } from '@toasttab/buffet-pui-alerts'
import { SelectField } from '@toasttab/buffet-pui-forms'
import { InfoIcon } from '@toasttab/buffet-pui-icons'
import { Tooltip } from '@toasttab/buffet-pui-tooltip'
import { useMemo } from 'react'

export function SelectEventCategoryField({
  eventCategories,
  newSubscription
}: {
  eventCategories: EventCategory[]
  newSubscription: boolean
}) {
  const { isDepotDomain } = useDomainContext()

  const options: {
    label: string
    value: string
  }[] = useMemo(
    () =>
      eventCategories.map((ec: EventCategory) => ({
        label: ec.name,
        value: ec.guid
      })),
    [eventCategories]
  )

  const disabledAndShowTooltip = useMemo(
    () => isDepotDomain && !newSubscription,
    [isDepotDomain, newSubscription]
  )

  return (
    <>
      <SelectField
        name='eventCategoryGuid'
        label={<EventCategoryFieldLabel showTooltip={disabledAndShowTooltip} />}
        required
        options={options}
        testId='event-category'
        disabled={disabledAndShowTooltip}
      />
      {newSubscription && (
        <Alert>
          Please ensure that you select the correct category for your
          integration use case. You can find detailed descriptions of webhook
          event categories{' '}
          <a
            href='https://doc.toasttab.com/doc/devguide/apiWebhooksReference.html'
            target='_blank'
            rel='noreferrer'
            className='underline'
          >
            here
          </a>
          .
        </Alert>
      )}
    </>
  )
}

const eventCategoryLabel = 'Event category'

function EventCategoryFieldLabel({ showTooltip }: { showTooltip: boolean }) {
  if (showTooltip) {
    return (
      <>
        {eventCategoryLabel}
        <Tooltip>
          <Tooltip.Trigger>
            <InfoIcon
              className='ml-2 text-default'
              aria-label='more info on event category field disabled'
            />
          </Tooltip.Trigger>
          <Tooltip.Content>
            <p>
              Event category cannot be edited for existing webhook
              subscriptions.
            </p>
            <p>
              If you want to delete or disable this subscription{' '}
              <a
                href='https://developers.toasttab.com/hc/en-us/requests/new'
                target='_blank'
                className='underline'
                rel='noreferrer'
              >
                open a support ticket
              </a>
              .
            </p>
          </Tooltip.Content>
        </Tooltip>
      </>
    )
  }

  return <>{eventCategoryLabel}</>
}
