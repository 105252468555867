import * as React from 'react'

/**
 * We will move these props to Page and FocusView
 */
interface LayoutContextTypeDeprecated {
  disableMaxWidth?: boolean
  scrollToTop?: boolean
}

const LayoutContextDeprecated =
  React.createContext<LayoutContextTypeDeprecated>({})

export const useLayoutContextDeprecated = () => {
  const context = React.useContext(LayoutContextDeprecated)
  if (!context) {
    throw new Error(
      'useLayoutContextDeprecated must be used within a LayoutProvider component'
    )
  }
  return context
}

export interface LayoutProviderProps {
  disableMaxWidth?: boolean
  scrollToTop?: boolean
}

/**
 * @deprecated -- Remove this component.
 * Page and FocusView provide this context internally now.
 * Move disableMaxWidth and scrollToTop props to Page and/or FocusView.
 */
export const LayoutProvider = ({
  disableMaxWidth,
  scrollToTop,
  children
}: React.PropsWithChildren<LayoutProviderProps>) => {
  const contextValue = {
    disableMaxWidth,
    scrollToTop
  }

  return (
    <LayoutContextDeprecated.Provider value={contextValue}>
      {children}
    </LayoutContextDeprecated.Provider>
  )
}
