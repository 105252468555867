import * as React from 'react'
import cx from 'classnames'
import { IconWrapper, IconProps, sizes } from '../../IconWrapper'
export const PreviewIcon = React.forwardRef(function PreviewIcon(
  props: IconProps,
  ref?: React.ForwardedRef<HTMLElement>
) {
  return (
    <IconWrapper ref={ref} {...props}>
      <>
        {
          <svg
            width={24}
            height={24}
            viewBox='0 0 24 24'
            fill='none'
            className={cx(
              'inline-block align-middle',
              sizes[props.size || 'sm'].className
            )}
          >
            <path
              d='M3.05 6.55h18m-9 2.8c-2.3 0-4.3 1.2-5.5 2.9-.5.8-.5 1.8 0 2.6 1.2 1.8 3.2 2.9 5.5 2.9s4.3-1.2 5.5-2.9c.5-.8.5-1.8 0-2.6-1.3-1.7-3.3-2.9-5.5-2.9zm1.7 4.2a1.7 1.7 0 11-3.4 0 1.7 1.7 0 013.4 0zm5.3-10.5h-14c-1.1 0-2 .9-2 2v14c0 1.1.9 2 2 2h14c1.1 0 2-.9 2-2v-14c0-1.2-.9-2-2-2z'
              stroke='currentColor'
              strokeWidth={sizes[props.size || 'sm'].strokeWidth}
              strokeMiterlimit={10}
            />
          </svg>
        }
      </>
    </IconWrapper>
  )
})
