import { useDomainContext } from '@local/current-domain'
import { Button } from '@toasttab/buffet-pui-buttons'
import { Panel } from '@toasttab/buffet-pui-config-templates'
import { EmptyState } from '@toasttab/buffet-pui-empty-state'
import { AddIcon, AnalyticsCheckIcon } from '@toasttab/buffet-pui-icons'
import { Link } from 'react-router-dom'

export function NoSubscriptionPageBody() {
  const { isDepotDomain } = useDomainContext()
  return (
    <Panel>
      <EmptyState
        title='No webhook subscriptions...yet'
        testId='subscriptions-page-empty-state'
        className='my-10'
        icon={<AnalyticsCheckIcon accessibility='decorative' />}
        button={
          isDepotDomain ? undefined : (
            <Button
              as={Link}
              to='/create'
              variant='primary'
              iconLeft={<AddIcon accessibility='decorative' />}
            >
              Add webhook
            </Button>
          )
        }
      >
        <div>Once a webhook subscription is added, it will appear here.</div>
        <div>
          New to webhooks? Learn more about adding a webhook subscription in our{' '}
          <a
            href='https://doc.toasttab.com/doc/cookbook/apiWebhookUsageChecklist.html'
            target='_blank'
            className='text-link underline'
            rel='noreferrer'
          >
            documentation.
          </a>
        </div>
      </EmptyState>
    </Panel>
  )
}
