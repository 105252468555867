import { PageWrapper } from '@local/app-routes/PageWrapper/PageWrapper'
import { useBanquetPartner } from '@local/banquet-partner-props'
import { useSubscription } from '@local/manage-webhooks-shared'
import { Button } from '@toasttab/buffet-pui-buttons'
import {
  HeadingGroup,
  PageActions,
  PageBack,
  PageBody,
  PageHeader,
  Title
} from '@toasttab/buffet-pui-config-templates'
import { Link, useParams } from 'react-router-dom'

import { EventsPageBody } from '../EventsPageBody/EventsPageBody'
import { GuidSubheading } from '../GuidSubheading'

export const EventsPage = () => {
  const { guid } = useParams()
  const { partner } = useBanquetPartner()
  const { subscription, loading, error } = useSubscription(guid)

  return (
    <PageWrapper>
      <PageHeader testId='events-page-header'>
        <HeadingGroup subTitle={partner?.name}>
          <GuidSubheading guid={guid} />
          <Title>Webhook events</Title>
        </HeadingGroup>
        <PageActions>
          {subscription && (
            <Button as={Link} to={`/update/${guid}`} variant='secondary'>
              Edit webhook
            </Button>
          )}
        </PageActions>
        <PageBack
          as={Link}
          to='/subscriptions'
          testId='back-to-subscriptions'
        />
      </PageHeader>
      <PageBody className='tpc-manage-webhooks-spa'>
        <EventsPageBody
          subscription={subscription}
          loading={loading}
          error={error}
        />
      </PageBody>
    </PageWrapper>
  )
}
