import * as React from 'react'
import cx from 'classnames'
import { ChevronLeftIcon, ChevronRightIcon } from '@toasttab/buffet-pui-icons'
import { OptionalKeys, TestIdentifiable } from '@toasttab/buffet-shared-types'
import { PaginationControl } from './PaginationControl'
import { PaginationBag } from '../usePagination'
import { t, loadStrings } from '../defaultStrings'

export interface PaginationProps
  extends TestIdentifiable,
    React.HTMLAttributes<HTMLElement>,
    OptionalKeys<
      PaginationBag<unknown>,
      'totalPageCount' | 'currentPageData'
    > {}

export const Pagination = ({
  testId = 'pagination-controls',
  shouldWrap = false,
  className,
  hasPrevious,
  gotoPrevious,
  hasNext,
  gotoNext,
  gotoPage,
  pageControls,
  currentPage,
  ...navElementAttributes
}: PaginationProps) => {
  delete navElementAttributes.currentPageData
  delete navElementAttributes.totalPageCount
  loadStrings()
  return (
    <nav
      className={cx('flex flex-wrap', className)}
      data-testid={testId}
      role='navigation'
      aria-label={t('pagination-navigation')}
      {...navElementAttributes}
    >
      <PaginationControl
        testId={`${testId}-previous-button`}
        disabled={!hasPrevious && !shouldWrap}
        onClick={gotoPrevious}
        ariaLabel={t('go-to-previous-page')}
      >
        <ChevronLeftIcon accessibility='decorative' />
      </PaginationControl>
      {pageControls.map(({ isPlaceholder, page, key }) => (
        <PaginationControl
          key={key}
          testId={`${testId}-${key}-button`}
          disabled={isPlaceholder}
          isPlaceholder={isPlaceholder}
          active={currentPage === page}
          onClick={() => gotoPage(page!)}
          ariaLabel={typeof page !== 'number' ? undefined : `Page ${page + 1}`}
        >
          {typeof page !== 'number' ? '...' : page + 1}
        </PaginationControl>
      ))}
      <PaginationControl
        testId={`${testId}-next-button`}
        disabled={!hasNext && !shouldWrap}
        onClick={gotoNext}
        ariaLabel={t('go-to-next-page')}
      >
        <ChevronRightIcon accessibility='decorative' />
      </PaginationControl>
    </nav>
  )
}

Pagination.Control = PaginationControl
