import { Locale } from '@toasttab/buffet-pui-locale-utilities'
import { defaultFormats, enGB, enCA, es } from './locales'

const selectCustomFormats = (locale: string): Record<string, string> => {
  let customFormats = {}
  switch (locale) {
    case 'en-CA':
      customFormats = enCA
      break
    case 'en-GB':
    case 'en-IE':
      customFormats = enGB
      break
    case 'es-US':
    case 'es':
      customFormats = es
      break
    default:
      break
  }
  return {
    ...defaultFormats,
    ...customFormats
  }
}

export const selectCustomFormatString = (
  format: string,
  locale: Locale
): string | undefined => {
  return selectCustomFormats(locale)[format]
}
