import * as React from 'react'
import cx from 'classnames'
import { IconWrapper, IconProps, sizes } from '../../IconWrapper'
export const RadioUnselectedIcon = React.forwardRef(
  function RadioUnselectedIcon(
    props: IconProps,
    ref?: React.ForwardedRef<HTMLElement>
  ) {
    return (
      <IconWrapper ref={ref} {...props}>
        <>
          {
            <svg
              width={24}
              height={24}
              viewBox='0 0 24 24'
              fill='none'
              className={cx(
                'inline-block align-middle',
                sizes[props.size || 'sm'].className
              )}
            >
              <path
                d='M12 21a9 9 0 100-18 9 9 0 000 18z'
                stroke='currentColor'
                strokeWidth={sizes[props.size || 'sm'].strokeWidth}
                strokeMiterlimit={10}
              />
            </svg>
          }
        </>
      </IconWrapper>
    )
  }
)
