import React from 'react'
import cx from 'classnames'
import { TestIdentifiable } from '@toasttab/buffet-shared-types'
import { Variant, variants, WithVariant } from '../variant'

export interface PrefixProps extends TestIdentifiable {
  variant: Variant
  disabled?: boolean
  readOnly?: boolean
}

const getPaddingLeft = (variant: Variant) => {
  switch (variant) {
    case variants.textOnly:
    case variants.bgGray:
      return 'calc(0.75rem - 1px)'
    default:
      return '0px'
  }
}

const getMarginLeft = (variant: Variant) => {
  switch (variant) {
    case variants.icon:
      return 'calc(0.75rem - 1px)'
    case variants.iconButton:
      return 'calc(0.25rem - 1px)'
    default:
      return '0px'
  }
}

export const Prefix: WithVariant<
  React.FC<React.PropsWithChildren<PrefixProps>>
> = ({ testId, variant, disabled, readOnly, children }) => {
  return (
    <div
      /**
       *  Hack to make the box-shadow used for the border work
       *  works ok in this instance as the item is not clickable
       */
      style={{
        zIndex: variant === variants.bgGray ? -1 : 'unset',
        paddingLeft: getPaddingLeft(variant),
        marginLeft: getMarginLeft(variant),
        lineHeight: 0
      }}
      data-testid={testId}
      className={cx('type-default whitespace-nowrap', {
        'text-disabled ': disabled && variant === variants.textOnly,
        'text-secondary':
          !disabled && (variant === variants.textOnly || readOnly),
        'bg-gray-0 rounded-l-input pr-3 leading-none h-full flex items-center text-disabled':
          disabled && variant === variants.bgGray,
        'bg-gray-25 rounded-l-input pr-3 leading-none h-full flex items-center':
          !disabled && variant === variants.bgGray,
        'text-disabled leading-none absolute pointer-events-none':
          disabled && variant === variants.icon,
        'text-secondary leading-none absolute pointer-events-none':
          !disabled && variant === variants.icon,
        'leading-none absolute': variant === variants.iconButton,
        'border-r': !disabled && variant === variants.bgGray && readOnly,
        'focus-within:bg-darken-4 focus-within:rounded-full':
          variant === variants.iconButton
      })}
    >
      {children}
    </div>
  )
}

Prefix.Variant = variants
