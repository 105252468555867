import { useDomainContext } from '@local/current-domain'
import {
  Partner,
  StatusBadge,
  Subscription,
  getSubscriptionStatus
} from '@local/manage-webhooks-shared'
import { Getter, createColumnHelper } from '@tanstack/react-table'
import { Tooltip } from '@toasttab/buffet-pui-tooltip'
import { useCallback, useMemo } from 'react'
import { Link } from 'react-router-dom'

import { SubscriptionActions } from '../SubscriptionActions/SubscriptionActions'
import { SubscriptionActionsHeader } from '../SubscriptionActionsHeader/SubscriptionActionsHeader'
import { getPartnerNameFromGuid } from '../utils/getPartnerNameFromGuid'

const columnHelper = createColumnHelper<Subscription>()
const depotTruncateMaxWidth = '10rem'
const toastAdminruncateMaxWidth = '7.9rem'

export function useSubscriptionTableColumns(partners: Partner[] | undefined) {
  const { isDepotDomain } = useDomainContext()
  const truncateColWidth = useMemo(() => {
    if (isDepotDomain) return depotTruncateMaxWidth
    return toastAdminruncateMaxWidth
  }, [isDepotDomain])

  const TruncateTooltipCell = useCallback(
    ({ getValue }: { getValue: Getter<string | undefined> }) => (
      <TruncateAndTooltip value={getValue() ?? ''} width={truncateColWidth} />
    ),
    [truncateColWidth]
  )

  return useMemo(
    () => [
      columnHelper.accessor(
        (subscription) => getSubscriptionStatus(subscription),
        {
          id: 'status',
          header: 'Status',
          cell: (info) => <StatusBadge status={info.getValue()} />,
          enableSorting: false
        }
      ),
      columnHelper.accessor('name', {
        header: 'Name',
        cell: TruncateTooltipCell
      }),
      columnHelper.accessor((subscription) => subscription.guid as string, {
        id: 'guid',
        header: 'GUID',
        cell: (info) => {
          const guid = info.getValue()
          return (
            <Tooltip
              content={() => {
                return guid
              }}
              placement='bottom-start'
            >
              <Link to={`/events/${guid}`} className='text-link font-semibold'>
                <div className='truncate' style={{ width: truncateColWidth }}>
                  {guid}
                </div>
              </Link>
            </Tooltip>
          )
        }
      }),
      columnHelper.accessor('uri', {
        id: 'uri',
        header: 'Webhook URL',
        cell: TruncateTooltipCell
      }),
      columnHelper.accessor(
        (subscription) =>
          getPartnerNameFromGuid(subscription.subscriberGuid!, partners),
        {
          id: 'subscriberName',
          header: 'Subscriber Name',
          cell: TruncateTooltipCell
        }
      ),
      columnHelper.accessor(
        (subscription) => subscription.eventCategory?.name,
        {
          id: 'subscriptionCategory',
          header: 'Event Category',
          cell: TruncateTooltipCell
        }
      ),
      columnHelper.display({
        id: 'actions',
        header: SubscriptionActionsHeader,
        cell: (cell) => (
          <SubscriptionActions
            guid={cell.row.original.guid as string}
            subscriptionName={cell.row.original.name}
          />
        )
      })
    ],
    [partners, truncateColWidth, TruncateTooltipCell]
  )
}

const TruncateAndTooltip = ({
  value,
  width
}: {
  value: string
  width: number | string
}) => (
  <Tooltip
    content={() => {
      return value
    }}
    placement='bottom-start'
  >
    <div style={{ maxWidth: width }} className='truncate'>
      {value}
    </div>
  </Tooltip>
)
