import {
  HeadingGroup,
  Page,
  PageBody,
  PageHeader,
  Title
} from '@toasttab/buffet-pui-config-templates'
import {
  Tab,
  TabList,
  TabPanel,
  TabPanels,
  Tabs
} from '@toasttab/buffet-pui-tabs'
import { ReactNode, useCallback, useMemo } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'

export type TabContent = {
  id: string
  title: string
  node: ReactNode
}

export function PageWithTabs({
  tabContents,
  pageTitle,
  subTitle
}: {
  tabContents: TabContent[]
  pageTitle: string
  subTitle?: string
}) {
  const tabList = useMemo(() => tabContents.map(({ id }) => id), [tabContents])

  const tabIndex = useGetTabIndex(tabList)
  const handleTabChange = useHandleTabChange(tabList)

  return (
    <Page className='h-fit w-full bg-gray-0'>
      <Tabs index={tabIndex} onChange={handleTabChange}>
        <PageHeader
          fixedTabList={
            <TabList>
              {tabContents.map(({ id, title }) => (
                <Tab key={`tab-button-${id}`} testId={`${id}-tab-button`}>
                  {title}
                </Tab>
              ))}
            </TabList>
          }
        >
          <HeadingGroup subTitle={subTitle}>
            <Title testId='page-title'>{pageTitle}</Title>
          </HeadingGroup>
        </PageHeader>

        <PageBody className='tpc-manage-webhooks-spa'>
          <TabPanels>
            {tabContents.map(({ id, node }) => (
              <TabPanel key={`tab-node-${id}`} testId={`${id}-tab-node`}>
                {node}
              </TabPanel>
            ))}
          </TabPanels>
        </PageBody>
      </Tabs>
    </Page>
  )
}

function useGetTabIndex(tabList: string[]): number {
  const { pathname } = useLocation()

  return useMemo(() => {
    const tabIndex = tabList.findIndex((tabId) =>
      pathname.includes(`/${tabId}`)
    )
    if (tabIndex) return tabIndex
    return 0
  }, [pathname, tabList])
}

function useHandleTabChange(tabList: string[]) {
  const navigate = useNavigate()

  return useCallback(
    (newIndex: number) => {
      if (newIndex > tabList.length) {
        navigate(`/${tabList[0] ?? ''}`)
      }

      navigate(`/${tabList[newIndex]}`)
    },
    [navigate, tabList]
  )
}
