import { ReactNode } from 'react'
import { render } from '@testing-library/react'
import { SnackBarProvider } from '@toasttab/buffet-pui-snackbars'
import { BrowserRouter } from 'react-router-dom'

export const renderWrapper = (component: ReactNode) => {
  return render(
    <SnackBarProvider>
      <div id='banquetPortalsContainer'>
        <BrowserRouter>{component}</BrowserRouter>
      </div>
    </SnackBarProvider>
  )
}
