import { Subscription } from '../types'

import { getSubscriptionStatus } from './getSubscriptionStatus'

export const filterByStatus = (
  subscriptions: Subscription[],
  status: string
) => {
  return subscriptions.filter(
    (subscription) =>
      getSubscriptionStatus(subscription) === status.toUpperCase()
  )
}
