import React from 'react'
import cx from 'classnames'
import { TestIdentifiable } from '@toasttab/buffet-shared-types'

export interface LabelProps
  extends TestIdentifiable,
    React.LabelHTMLAttributes<HTMLLabelElement> {
  disabled?: boolean
  htmlFor?: string
}

export const Label: React.FC<React.PropsWithChildren<LabelProps>> = ({
  children,
  disabled,
  testId,
  htmlFor,
  className,
  ...restProps
}) => {
  return (
    <label
      className={cx(
        'inline-flex items-center',
        'type-default',
        'cursor-pointer py-2.5',
        {
          'text-disabled': disabled,
          'text-default': !disabled
        },
        className
      )}
      data-testid={testId}
      htmlFor={htmlFor}
      {...restProps}
    >
      {children}
    </label>
  )
}
