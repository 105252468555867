import * as React from 'react'
import cx from 'classnames'
import { TestIdentifiable } from '@toasttab/buffet-shared-types'

export interface SkeletonProps<E extends React.ElementType = React.ElementType>
  extends TestIdentifiable {
  /** Height in your preferred units e.g. 4rem, 16px etc. */
  height?: string
  /** Width in your preferred units e.g. 4rem, 16px etc. */
  width?: string
  /** Rendered element e.g. a 'div' or 'span' */
  as?: E
  /** Classes applied to the Skeleton component */
  className?: string
}

type SkeletonAndReactElementProps<E extends React.ElementType> =
  SkeletonProps<E> & Omit<React.ComponentProps<E>, keyof SkeletonProps>

const defaultElement = 'div'

type SkeletonComponent = <E extends React.ElementType = typeof defaultElement>(
  props: SkeletonAndReactElementProps<E>
) => React.ReactElement | null

export const Skeleton: SkeletonComponent = React.forwardRef(function Skeleton(
  { className, height, width, testId, as, ...rest }: SkeletonProps,
  ref: React.Ref<Element>
) {
  const Component = as || defaultElement
  return (
    <Component
      ref={ref}
      style={{ height, width }}
      data-testid={testId}
      className={cx(
        'skeleton-base rounded overflow-hidden relative',
        className
      )}
      {...rest}
    >
      <span
        className={cx(
          'absolute inset-0',
          'bg-gradient-to-r from-transparent via-darken-4 to-transparent',
          'animate-wave-to-r'
        )}
      />
    </Component>
  )
})
